import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SummaryService } from '../../services/summary.service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { HeaderService } from '../../../../shared/services/header.service';
import {
  ScoreMatrix,
  ValueForecastInput,
} from '../../../../horizon-project/models/ValueForecastInput';
import { ForecastService } from '../../../forecast/services/forecast.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { DynamicScoreInput } from '../../../../horizon-project/models/DynamicValueScore';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { FeatureFlagService } from '../../../../horizon-project/services/feature-flag.service';
import { NetPricePermission } from '../../../../horizon-project/models/UserProjectPermission';
import { priceTypeEnums } from '../../enums/pricetype.enum';

@Component({
  selector: 'he-summary-cot-forecast',
  templateUrl: './summary-cot-forecast.component.html',
  styleUrls: ['./summary-cot-forecast.component.scss'],
})
export class SummaryCotForecastComponent implements OnInit, OnChanges {
  @Input() public alpha: string[] = [];
  @Input() public data: any[] = [];
  public DefaultScenariodata: any[] = [];
  public countries: any[] = [];
  public analogues: any[] = [];
  public valueScoreUpdate: boolean = false;
  public priceUpdate: boolean = false;
  public vScore!: DynamicScoreInput;
  public DefaultAnalogues: any[] = [];
  public projectId = '';
  public selectedCurrency = '';
  public selectedCurrencySymbol = '';
  public selectedCountry = '';
  public loading = true;
  public selectedScenario = '';
  public scenarios: any[] = [];
  public netPricePermission!: NetPricePermission;
  private unsubscriber$ = new Subject<void>();
  constructor(
    public headerService: HeaderService,
    private forecastService: ForecastService,
    private activatedRouter: ActivatedRoute,
    private summaryService: SummaryService,
    private toastNotificationService: ToastNotificationService,
    private currencyConversionService: CurrencyConversionService,
    private countryFlagService: CountryFlagService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnChanges(): void {
    this.loading = true;
    this.scenarios = [];
    this.DefaultScenariodata = [];
    this.countries = [];
    this.analogues = [];
    if(this.data.length != 0){
      this.setScenariBasedData(this.data);
    }
  }

  ngOnInit(): void {
    this.selectedCurrency = this.currencyConversionService.GetDefaultCurrency();
    this.selectedCurrencySymbol =
      this.currencyConversionService.GetDefaultCurrencySymbol();
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        if (p.project) {
          this.projectId = p.project;
          this.loading = true;
          this.valueScoreUpdate = false;
          this.netPricePermission = this.headerService.netPricePermission
        }
      });
    this.currencyConversionService
      .GetSelectedCurrency()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (currency: string) => {
        this.selectedCurrency = currency;
        this.loading = true;
        if(this.scenarios.length != 0){
          if (this.valueScoreUpdate) {
            this.updateScores(this.vScore).then(() => {
              this.convertCurrency().then(()=>{this.loading = false})
            });
          } else {
            this.scenarios = JSON.parse(JSON.stringify(this.DefaultScenariodata));
            this.convertCurrency().then(()=>{this.loading = false})
          }
        }
      });
    this.headerService.onCountryChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((country) => {
        this.loading=true;
        this.selectedCountry = country.replace('-', ' ');
        this.scenarios = JSON.parse(JSON.stringify(this.DefaultScenariodata));
        if(this.scenarios.length != 0){
          if (!this.valueScoreUpdate) {
            this.convertCurrency().then(()=>{this.loading = false})
          }
          this.valueScoreUpdate = false;
        }

      });
    this.headerService.onScenarioChange
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.loading=true;
        this.selectedScenario = data;
        this.valueScoreUpdate = false;

        this.scenarios = JSON.parse(JSON.stringify(this.DefaultScenariodata));
        if(this.scenarios.length != 0){
          this.convertCurrency().then(()=>{this.loading = false})
        }
      });
    this.headerService.onValueScoreChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (vScore) => {
        // update asset value score of that country
        if (vScore) {
          this.valueScoreUpdate = true;
          this.loading = true;
          this.vScore = vScore;
        if(this.scenarios.length != 0){
          this.updateScores(this.vScore).then(() => {
            this.convertCurrency().then(()=>{this.loading = false})
          });
        }
        }
      });
  }

  public async updateScores(vScore: any) {
    const countryDataIndex = vScore.UpdatedCountryScores.findIndex(
      (f: any) => f.country === this.selectedCountry
    );
    if (countryDataIndex > -1) {
      const totalValueScore =
        vScore.UpdatedCountryScores[countryDataIndex].totalValueScore;
      const assetName = vScore.UpdatedCountryScores[countryDataIndex].assetName;
      this.scenarios = JSON.parse(JSON.stringify(this.DefaultScenariodata));
      let filteredData = this.data.filter(
        (x) => x.scenario === this.selectedScenario
      );

      await Promise.all(
        filteredData.map(async (element, index) => {
          if (this.selectedScenario == element.scenario) {
            const ScoreMatrixData = element.forecastSummary.scoreMatrix.filter(
              (f: any) => f.countryName === this.selectedCountry
            );
            if (ScoreMatrixData) {
              const UpdatedScoreMatrixData: any[] = JSON.parse(
                JSON.stringify(ScoreMatrixData)
              );
              UpdatedScoreMatrixData[0].assetData = [];
              UpdatedScoreMatrixData[0].assetData.push({
                Name: assetName,
                TotalValueScore: totalValueScore,
              });
              const newRequestData: ValueForecastInput = {
                ScoreMatrix: [...UpdatedScoreMatrixData],
                ProjectId: this.projectId,
              };
              await this.getForecastedData(
                newRequestData,
                element.priceType,
                filteredData,
                index
              );
            }
          }
        })
      );
    }
  }

  private async getForecastedData(
    requestData: any,
    priceTypeName: string,
    filteredData: any,
    index: any
  ) {
    if (requestData != null && requestData.ScoreMatrix.length > 0) {
      if(requestData.ScoreMatrix[0].brandData.length > 0){
        if (this.projectId) {
          requestData.ProjectId = this.projectId;
          await this.forecastService
            .getForecastData(requestData)
            .then(async (f) => {
              this.scenarios
                .filter(
                  (scenario) => scenario.scenario == this.selectedScenario
                )[0]
                .priceTypes.filter(
                  (priceType: any) => priceType.priceTypeName === priceTypeName
                )[0]
                .country.filter(
                  (country: any) => country.name == this.selectedCountry
                )[0].price = Math.round(
                f.scoreMatrix[0].assetData[0].predictedPrice
              );
              })
              .catch((err) => {
                console.log(err);
              });
            }
        }

    }
  }

  public async chaingingHelper() {}

  public setScenariBasedData(data: any): void {
    let netPriceEnabled:boolean
    this.featureFlagService.getFeatureFlagData().then((data) => {
      if (data) {
        netPriceEnabled = data.filter(
          (x) => x.name === 'net-price-assumptions' && x.page === 'project-sidebar'
        )[0]?.isActive;
      }
    });
    data.forEach((res: any) => {
      if (
        !this.scenarios.some((scenario) => scenario.scenario === res.scenario)
      ) {
        this.scenarios.push({
          scenario: res.scenario,
          scenarioIndexName: '',
          priceTypes: [],
          sequenceNo: res.scenarioSequence,
        });
      }
      this.scenarios.forEach((res1, index) => {
        res1.scenarioIndexName = ' Scenario ' + this.alpha[index] + '' + ': ';
        if (res1.scenario === res.scenario) {
          if(this.netPricePermission.isNetPriceAvailable && res.priceType === "Net Price"){
            res1.priceTypes.push({
              priceType: res.priceType.replace(/([A-Z])/g, ' $1').trim(),
              priceTypeName: res.priceType,
              country: this.setCountryData(res.forecastSummary?.scoreMatrix, res.priceType),
            });
          }else if(res.priceType !== "Net Price"){
            res1.priceTypes.push({
              priceType: res.priceType.replace(/([A-Z])/g, ' $1').trim(),
              priceTypeName: res.priceType,
              country: this.setCountryData(res.forecastSummary?.scoreMatrix, res.priceType),
            });
          }
        }
        res1.priceTypes.sort((a:any,b:any)=> priceTypeEnums[(a.priceTypeName as string).replace(' ','_') as keyof typeof priceTypeEnums] - priceTypeEnums[(b.priceTypeName as string).replace(' ','_') as keyof typeof priceTypeEnums])
      });
    });
    this.loading = false;
    this.scenarios.sort((a, b) => a.sequenceNo - b.sequenceNo);
    this.DefaultScenariodata = JSON.parse(JSON.stringify(this.scenarios));
    this.convertCurrency().then(()=>{this.loading = false})
  }

  public setCountryData(scoreMatrix: any[],priceType:string): any[] {
    let data: any[] = [];
    if (scoreMatrix != null) {
      scoreMatrix.forEach((res) => {
        if (
          !this.countries.some((country) => country.name === res.countryName)
        ) {
          this.countries.push({
            name: res.countryName,
            flagId: this.countryFlagService.getFlagId(res.countryName),
            info: res.infoIconData,
          });
        }
        if(this.headerService.ComparatorOrAnalog.value === 'Analogues'){
        data.push({
          name: res.countryName,
          info: res.infoIconData,
          price: !this.netPricePermission.isNetPriceVisible && priceType === "Net Price" ? "******" : Math.round(res.assetData[0].predictedPrice),
          currency: this.selectedCurrency,
        });
      }
      else
      {
        // Extract all actualprice values
          const prices = res.brandData?.map((item:any) => item.actualPrice);

          // Find the minimum and maximum values
            const minActualPrice = prices.length>0 ? Math.min(...prices) : 0;
            const maxActualPrice = prices.length>0 ?  Math.max(...prices) : 0;
        data.push({
          name: res.countryName,
          info: res.infoIconData,
          price: !this.netPricePermission.isNetPriceVisible && priceType === "Net Price" ? "******" : minActualPrice +' to '+ maxActualPrice,
          minprice: Math.round(minActualPrice),
          maxprice: Math.round(maxActualPrice),
        currency: this.selectedCurrency,
        });
      }

        res.brandData.forEach((analog: any) => {
          if (
            !this.analogues.some((analogue) => analogue.name === analog.name)
          ) {
            this.analogues.push({
              name: analog.name,
              color:

                '#' +
                (0x1000000 + Math.random() * 0xffffff)
                  .toString(16)
                  .substr(1, 6),
            });
          }
        });
      });
      this.countries?.sort((a,b)=>a.name?.localeCompare(b.name))
      data?.sort((a,b)=>a.name?.localeCompare(b.name))
    } else {
      this.countries.forEach((country) => {
        data.push({
          name: country.name,
          price: 0,
          currency: this.selectedCurrency,
        });
      });
      this?.countries.sort((a,b)=>a.name?.localeCompare(b.name))
      data?.sort((a,b)=>a.name?.localeCompare(b.name))
    }
    this.DefaultAnalogues = JSON.parse(JSON.stringify(this.analogues));
    return data;
  }

  public async convertCurrency() {
    await Promise.all(
      this.scenarios.map(async (scenario, index) => {
        scenario.priceTypes.forEach((priceType: any) => {
          priceType.country.forEach(async (country: any) => {
            const defaultCurrency =
              this.currencyConversionService.GetDefaultCurrency();
            if (this.selectedCurrency.toLowerCase().trim() === 'local') {
              const countryCurrency =
                await this.currencyConversionService.getCountryCurrencyData(
                  country.name
                );
              country.price = Math.round(
                await this.currencyConversionService.convertCurrency(
                  country.price,
                  defaultCurrency,
                  countryCurrency.currencyCode
                )
              );
              country.currency = countryCurrency.currencyCode;
            } else {
              country.price = Math.round(
                await this.currencyConversionService.convertCurrency(
                  country.price,
                  defaultCurrency,
                  this.selectedCurrency
                )
              );
              country.currency = this.selectedCurrency;
            }
          });
        });
      })

    )
  }

  public removeAnalogues(analogue: any): void {
    if (this.analogues.length > 2) {
      let index = this.analogues.findIndex((x) => x.name == analogue.name);
      this.analogues.splice(index, 1);
      this.headerService.setSelectedBrands(this.analogues);
    }
  }

  public downloadFile(): void {
    this.summaryService.newConvertToCSV(
      this.scenarios,
      this.countries,
      'COST OF TREATMENT FORECAST SUMMARY',
      this.selectedCurrencySymbol
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    }
}
