import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Subject, startWith, take, takeUntil } from 'rxjs';
import {
  AnalogueListInterface,
  AnalogueTableInterface,
  LegendEnum,
} from '../../models/criteria.interface';
import { ProjectService } from '../../services/project.service';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'he-analoguei-heat-map',
  templateUrl: './analoguei-heat-map.component.html',
  styleUrls: ['./analoguei-heat-map.component.scss'],
})
export class AnalogueiHeatMapComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatTable) public tableheatmap!: MatTable<any>;
  @Output() public emitAnalogueList = new EventEmitter<
    AnalogueListInterface[]
  >();
  @Output() public emitRemovedAnalogues =
    new EventEmitter<AnalogueListInterface>();
  @Output() public removeCountryFilter = new EventEmitter<string>();
  @Output() public refreshAnalogue = new EventEmitter();
  @Input() public loading = false;
  @Input() public loading2 = true;
  // @Input() public dataSource: AnalogueTableInterface[] = [];
  // @Input() public defaultdataSource: AnalogueTableInterface[] = [];
  @Input() public selectedAnalogue: AnalogueListInterface[] = [];
  @Input() public sortedAnalogues: any[] = [];
  public searchTable = new FormControl('');
  public sort: any;
  public checked = false;
  public disabled = false;
  public leastmet = false;
  public mostmet = false;
  public grid = '';
  public dataSource_all:AnalogueTableInterface[] = [];
  public dataSource:AnalogueTableInterface[] = [];
  public defaultdataSource:AnalogueTableInterface[] = [];
  public marketBrandCount: any = [];

  public legends = [
    {
      name: 'Meets criteria',
      color: LegendEnum[1],
    },
    {
      name: 'Partially meets criteria',
      color: LegendEnum[2],
    },
    {
      name: 'Does not meet criteria',
      color: LegendEnum[3],
    },
    {
      name: 'No criteria included',
      color: LegendEnum[0],
    },
  ];

  public displayedColumns: any[] = [
    'toggle',
    'Brand',
    'INN',
    'Market',
    'Orphan',
    'Oneofftherapy',
    'Population',
    'Eligiblepatientnumber',
    'Indication',
    'Routeofadministration',
    'Mechanismofaction',
    // 'TherapyArea',
    // 'IndicationCategory',
  ];
  public dynamicColums: any[] = [];
  public headerNamelist: {
    apititle: string;
    title: string;
    default: boolean;
    sticky?: boolean;
  }[] = [];

  public OrphanHeaderList: {
    apititle: string;
    title: string;
    default: boolean;
    sticky?: boolean;
  }[] = [
    {
      apititle: 'toggle',
      title: '',
      default: false,
      sticky: true,
    },
    {
      apititle: 'Brand',
      title: 'Brand Name',
      default: true,
      sticky: true,
    },
    {
      apititle: 'INN',
      title: 'INN',
      default: true,
      sticky: true,
    },
    {
      apititle: 'Country',
      title: 'Market Availability',
      default: true,
      sticky: false,
    },
    {
      apititle: 'Orphan',
      title: 'Orphan',
      default: false,
      sticky: false,
    },
    {
      apititle: 'Oneofftherapy',
      title: 'One-off therapy',
      default: false,
      sticky: false,
    },
    {
      apititle: 'Population',
      title: 'Population',
      default: false,
      sticky: false,
    },
    {
      apititle: 'Eligiblepatientnumber',
      title: 'Eligible Patient Number',
      default: false,
      sticky: false,
    },
    {
      apititle: 'Indication',
      title: 'Indication',
      default: true,
      sticky: false,
    },
    {
      apititle: 'Mechanismofaction',
      title: 'Mechanism of action category',
      default: false,
      sticky: false,
    },
    {
      apititle: 'Routeofadministration',
      title: 'Route of administration',
      default: false,
      sticky: false,
    },
  ];
  public OncologyHeaderList: {
    apititle: string;
    title: string;
    default: boolean;
    sticky?: boolean;
  }[] = [
    {
      apititle: 'toggle',
      title: '',
      default: false,
      sticky: true,
    },
    {
      apititle: 'Brand',
      title: 'Brand Name',
      default: true,
      sticky: true,
    },
    {
      apititle: 'Orphan',
      title: 'Orphan',
      default: false,
      sticky: false,
    },
    {
      apititle: 'INN',
      title: 'INN',
      default: true,
      sticky: true,
    },
    {
      apititle: 'Country',
      title: 'Market Availability',
      default: true,
      sticky: false,
    },
    {
      apititle: 'HTAIndication',
      title: 'HTA Indication',
      default: true,
      sticky: false,
    },
    {
      apititle: 'BiomarkerGenetyping',
      title: 'Biomarker/gene-typing',
      default: true,
      sticky: false,
    },
    {
      apititle: 'lineOffTherapy',
      title: 'Line of therapy',
      default: true,
      sticky: false,
    },
    {
      apititle: 'Population',
      title: 'Population',
      default: false,
      sticky: false,
    },
    {
      apititle: 'EligiblePatientNumber',
      title: 'Eligible Patient Number',
      default: false,
      sticky: false,
    },
    {
      apititle: 'RouteOfAdministration',
      title: 'Route of administration',
      default: false,
      sticky: false,
    },
    {
      apititle: 'InitialLaunchDate',
      title: 'Initial Launch Date',
      default: false,
      sticky: false,
    },
    {
      apititle: 'MoaCategory',
      title: 'Mechanism of action category',
      default: false,
      sticky: false,
    },
    {
      apititle: 'PriorReimbursedIndications',
      title: 'Prior Reimbursed Indications',
      default: false,
      sticky: false,
    },
    {
      apititle: 'TherapyRegimen',
      title: 'Therapy Regimen',
      default: false,
      sticky: false,
    },
    {
      apititle: 'Indication',
      title: 'Indication',
      default: true,
      sticky: false,
    },
    {
      apititle: 'TypeOfCombination',
      title: 'Type Of Combination',
      default: false,
      sticky: false,
    },
    {
      apititle: 'DurationOfTreatment',
      title: 'Duration Of Treatment',
      default: false,
      sticky: false,
    },
    {
      apititle: 'IndicationCategory',
      title: 'Indication Category',
      default: false,
      sticky: false,
    },
  ];
  columnsToDisplay: string[] = [];
  public ValueFrameworkName:string = "";

  private unsubscriber$ = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private criteriaSelectionService: CriteriaSelectionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMarketsBrandCount();
  }

  ngOnInit(): void {
    this.ValueFrameworkName = this.projectService.createProjectFormData.value.framework.valueFrameworkName;
    this.getComparatorColumn([],false);
    this.searchTable.valueChanges
      .pipe(startWith(null))
      .subscribe((analogue: string | null) => {
        this.removeCountryFilter.emit('');
        this.searchTableData(analogue);
      });
    this.criteriaSelectionService.brandCountData
      .pipe(take(1))
      .subscribe((res) => {
        this.marketBrandCount = res;
      });
    this.getMarkets();
  }

  public getComparatorColumn(dataset : any[],refresh:boolean): void {
    this.loading=true;
    this.defaultdataSource=dataset;
    if( refresh){
    this.dataSource=[];
  }
    this.dataSource_all=[];
    if (
      this.ValueFrameworkName === 'Orphan'
    ) {
      this.headerNamelist = this.OrphanHeaderList;
      this.grid = 'analogOrphanGrid';
    } else {
      this.headerNamelist = this.OncologyHeaderList;
      this.grid = 'analogOncologyGrid';
    }
    this.criteriaSelectionService
      .getComparatorDefault(this.grid)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.columnsToDisplay = [];
        this.displayedColumns = [];
        this.dynamicColums = [];
        this.columnsToDisplay.push('toggle');
        this.columnsToDisplay = [
          ...this.columnsToDisplay,
          ...res.selectedColumns.split(','),
        ];
        this.displayedColumns = this.columnsToDisplay.map((x) => {
          return {
            name: x,
            headerName: this.headerNamelist.find((y) => y.apititle === x)
              ?.title,
            sticky: this.headerNamelist.find((y) => y.apititle === x)?.sticky,
          };
        });
        this.dynamicColums = res.selectedColumns.split(',').map((x: string) => {
          return {
            name: x,
            title: this.headerNamelist.find((y) => y.apititle === x)?.title,
            default: this.headerNamelist.find((y) => y.apititle === x)?.default,
            visible: true,
          };
        });
        if (res.unSelectedColumns != '') {
          this.dynamicColums = [
            ...this.dynamicColums,
            ...res.unSelectedColumns.split(',').map((x: string) => {
              return {
                name: x,
                title: this.headerNamelist.find((y) => y.apititle === x)?.title,
                default: false,
                visible: false,
              };
            }),
          ];
        }
        this.loading2 = false;
      });
     // this.dataSource=this.dataSource;
     let selectColumn=   this.dynamicColums.filter((x) => x.visible === true)
     .map((y) => 
       y.name,
     )
     selectColumn.push('toggle');
     if(refresh)
      {
        this.dataSource_all=dataset;
      }
     this.dataSource= this.filterDataset(this.dataSource_all.length>0 ? this.dataSource_all : this.dataSource, selectColumn);
     this.dataSource=this.removeDuplicates(this.dataSource,selectColumn);
     this.dataSource=this.mergeRows(this.dataSource);
     this.defaultdataSource=this.dataSource;
    this.loading=false;
    //  this.tableheatmap.renderRows();
  }
// Function to filter dataset and keep only specified columns
filterDataset(dataset: any[], columns: string[]): any[] {
  // columns = columns.filter(col => col !== 'toggle');
   return dataset.map(item => {
     const filteredItem: any = {};
     columns.forEach(col => {
       filteredItem[col] = item[col];
     });
 
     return filteredItem;
   });
 }
 
 removeDuplicates(dataset: any[], uniqueColumns: string[]): any[] {
   const seen = new Set();
 
   return dataset.filter(item => {
     // Create a unique key based on the specified columns
     const key = uniqueColumns.map(col => {
       const value = item[col];
 
       if (Array.isArray(value)) {
         // If the value is an array, check if each item has a `name` property
         return value
         .map((obj: any) => obj && obj.name ? obj.name : obj)  // Use `name` property if it exists, otherwise use the value itself
           .slice() // Create a shallow copy of the array
           .sort() // Sort the array
           .join(','); // Join the sorted values into a string
       } else if (value && typeof value === 'object') {
         // If the value is an object (not an array), convert to a string representation
         return value.name || JSON.stringify(value);
       } else {
         // For non-array and non-object values, return the value directly
         return value;
       }
     }).join('-'); // Join all column values into a single key
 
     // If the key hasn't been seen yet, add it to the Set and keep the item
     if (!seen.has(key)) {
       seen.add(key);
       return true;
     }
 
     // Otherwise, it's a duplicate, so exclude it
     return false;
   });
 }
 mergeRows(dataset: any[]): any[] {
   const mergedData: any[] = [];
   const mergeColumns = ["lineOffTherapy", "BiomarkerGenetyping"];
   
   dataset.forEach((current) => {
     // Find an existing entry that matches all columns except the ones in mergeColumns
     const existing = mergedData.find((entry) => {
       return Object.keys(current).every((key) => {
         // Skip comparison for mergeColumns (lineOffTherapy, BiomarkerGenetyping)
         if (mergeColumns.includes(key)) return true;
 
         // Compare all other columns
         return JSON.stringify(entry[key]) === JSON.stringify(current[key]);
       });
     });
 
     if (existing) {
       // If a match is found, merge only the specified columns (lineOffTherapy, BiomarkerGenetyping)
       mergeColumns.forEach((col) => {
         if (existing[col]?.name && current[col]?.name) {
           // Merge only if the names are different
           if (!existing[col].name.includes(current[col].name)) {
             existing[col].name += `, ${current[col].name}`;
           }
         }
       });
     } else {
       // If no match is found, add the current item to the mergedData array
       mergedData.push({ ...current });
     }
   });
 
   // Return the final merged dataset without duplicates
   return mergedData;
 }
 
  filterAndGroupDataset(dataset: any[], columns: string[]): any[] {
    const seen = new Set();
    
    // Filter dataset based on the visible columns
    return dataset.filter(item => {
      columns = columns.filter(col => col !== 'toggle');
      const key = columns.map(col => col).join('-');  // Create a unique key based on column values
  
      if (!seen.has(key)) {
        seen.add(key);
        return true;  // If key not seen, include the record
      }
      return false;  // If key already exists, skip the record
    }).map(item => {
      // Return only the selected columns
      const filteredItem: any = {};
      columns.forEach(col => filteredItem[col] = item[col]);
      return filteredItem;
    });
  }
  public searchTableData(search: string | null): void {
    search
      ? (this.dataSource = this._filter(search))
      : (this.dataSource = this.defaultdataSource.slice());
    if (this.sort !== undefined) {
      this.sortData(this.sort);
    }
  }

  public getMarkets() {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .subscribe((data) => {
        data?.selectedMarkets?.forEach((market: any) => {
          if (!this.marketBrandCount.some((x: any) => x.country === market)) {
            this.marketBrandCount.push({
              country: market,
              brandCount: 0,
            });
          }
        });
        this.marketBrandCount.forEach((market: any, index: number) => {
          if (!data?.selectedMarkets.some((x: any) => x === market.country)) {
            this.marketBrandCount.splice(index, 1);
          }
        });
      });
    this.criteriaSelectionService.brandCountData.next(this.marketBrandCount);

    // this.updateMarketsBrandCount();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    let filteredList = [];
    filteredList = this.defaultdataSource.filter((analogue) => {
      return JSON.stringify(Object.values(analogue))
        .toLowerCase()
        .includes(filterValue);
    });
    // if (
    //   this.defaultdataSource.filter((fruit) =>
    //     fruit.Brand.toLowerCase().includes(filterValue)
    //   ).length !== 0
    // ) {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.Brand.toLowerCase().includes(filterValue)
    //   );
    // } else if (
    //   this.defaultdataSource.filter((fruit) =>
    //     fruit.INN.toLowerCase().includes(filterValue)
    //   ).length !== 0
    // ) {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.INN.toLowerCase().includes(filterValue)
    //   );
    // } else if (
    //   this.defaultdataSource.filter((fruit) =>
    //     fruit.Indication.name.toLowerCase().includes(filterValue)
    //   ).length !== 0
    // ) {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.Indication.name.toLowerCase().includes(filterValue)
    //   );
    // } else {
    //   filteredList = this.defaultdataSource.filter((analogue) =>
    //     analogue.Brand.toLowerCase().includes(filterValue)
    //   );
    // }
    return filteredList;
  }

  public sortData(sort: Sort): void {
    this.sort = sort;
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction !== 'asc';
      switch (sort.active) {
        case 'Brand':
          return compare(a.Brand, b.Brand, isAsc);
        case 'INN':
          case 'Brand Name':
            return compare(a.Brand, b.Brand, isAsc);
          case 'INN':
          return compare(a.INN, b.INN, isAsc);
        case 'Orphan':
          return compare(a.Orphan.name, b.Orphan.name, isAsc);
        case 'Oneofftherapy':
          return compare(a.Oneofftherapy.name, b.Oneofftherapy.name, isAsc);
        case 'Population':
          return compare(a.Population.name, b.Population.name, isAsc);
        case 'Eligiblepatientnumber':
          return compare(
            a.Eligiblepatientnumber.name,
            b.Eligiblepatientnumber.name,
            isAsc
          );
        case 'Indication':
          return compare(a.Indication.name, b.Indication.name, isAsc);
        case 'Routeofadministration':
          return compare(
            a.Routeofadministration.name,
            b.Routeofadministration.name,
            isAsc
          );
        case 'Mechanismofaction':
          return compare(
            a.Mechanismofaction.name,
            b.Mechanismofaction.name,
            isAsc
          );
        case 'HTA Indication':
          return compare(a.HTAIndication.name, b.HTAIndication.name, isAsc);
        case 'Biomarker/gene-typing':
          return compare(
            a.BiomarkerGenetyping.name,
            b.BiomarkerGenetyping.name,
            isAsc
          );
        case 'Line of therapy':
          return compare(a.lineOffTherapy.name, b.lineOffTherapy.name, isAsc);
        case 'Initial Launch Date':
          return compare(
            a.InitialLaunchDate.name,
            b.InitialLaunchDate.name,
            isAsc
          );
        case 'Mechanism of action category':
          return compare(a.MoaCategory.name, b.MoaCategory.name, isAsc);
        case 'Prior Reimbursed Indications':
          return compare(
            a.PriorReimbursedIndications.name,
            b.PriorReimbursedIndications.name,
            isAsc
          );
        case 'Therapy Regimen':
          return compare(a.TherapyRegimen.name, b.TherapyRegimen.name, isAsc);
        case 'Tli':
          return compare(a.Indication.name, b.Indication.name, isAsc);
        case 'Type Of Combination':
          return compare(
            a.TypeOfCombination.name,
            b.TypeOfCombination.name,
            isAsc
          );
        case 'Duration Of Treatment':
          return compare(
            a.DurationOfTreatment.name,
            b.DurationOfTreatment.name,
            isAsc
          );
        case 'Indication Category':
          return compare(
            a.IndicationCategory.name,
            b.IndicationCategory.name,
              isAsc
          );
        default:
          return 0;
      }
    });
  }

  public selectBrand(event: boolean, analogue: any): void {
    if (event) {
      if (
        this.selectedAnalogue.filter(
          (x) =>
            this.checkSelectedAnaloues(x, analogue)
        ).length == 0
      )
        this.selectedAnalogue.push({
          name: analogue.Brand,
          indication:analogue.Indication? analogue.Indication.name :null,
          HTAIndication:analogue.HTAIndication? analogue.HTAIndication.name :null,
          mechanismOfAction: analogue.Mechanismofaction ? analogue.Mechanismofaction.name : null,
          inn: analogue.INN,
          sequenceNo: this.selectedAnalogue.length + 1,
          available: true,
          Country: this.setmarketForselectedAnalogues(analogue),
        });
      this.dataSource
        .filter((res) => this.checkAnaloues(res, analogue))
        .forEach((res) => {
          res.toggle.toggle = event;
        });
      this.defaultdataSource
        .filter((res) => this.checkAnaloues(res, analogue))
        .forEach((res) => {
          res.toggle.toggle = event;
        });
      if (
        this.ValueFrameworkName!==
        'Orphan'
      ) {
        this.dataSource
          .filter(
            (res) =>
              res.Brand.toLowerCase().trim() ===
                analogue.Brand.toLowerCase().trim() &&
              res.HTAIndication.name.toLowerCase().trim() !==
                analogue.HTAIndication.name.toLowerCase().trim()
          )
          .forEach((res) => {
            if(this.checkAnaloguesCountry(res, analogue)){
              res.toggle.disable = event;
            }
          });
        this.defaultdataSource
          .filter(
            (res) =>
              res.Brand.toLowerCase().trim() ===
                analogue.Brand.toLowerCase().trim() &&
              res.HTAIndication.name.toLowerCase().trim() !==
                analogue.HTAIndication.name.toLowerCase().trim()
          )
          .forEach((res) => {
            if(this.checkAnaloguesCountry(res, analogue)){
              res.toggle.disable = event;
            }
          });
      }
      this.updateMarketsBrandCount();
      this.emitAnalogueList.emit(this.selectedAnalogue);
    } else {
      // let removeAnalogue = this.selectedAnalogue.filter((data:any)=>{
      //   return (data.name === analogue.Brand && data.indication === analogue.Indication.name)
      // })[0]
      this.dataSource
        .filter((res) => this.checkAnaloues(res, analogue))
        .forEach((res, index) => {
          res.toggle.toggle = event;
        });
      this.defaultdataSource
        .filter((res) => this.checkAnaloues(res, analogue))
        .forEach((res) => {
          res.toggle.toggle = event;
        });
      if (
        this.ValueFrameworkName!==
        'Orphan'
      ) {
        this.dataSource
          .filter(
            (res) =>
              res.Brand.toLowerCase().trim() ===
                analogue.Brand.toLowerCase().trim() &&
              res.Indication.name.toLowerCase().trim() ===
                analogue.Indication.name.toLowerCase().trim() &&
              res.INN.toLowerCase().trim() ===
                analogue.INN.toLowerCase().trim() &&
              res.HTAIndication.name.toLowerCase().trim() !==
                analogue.HTAIndication.name.toLowerCase().trim()
          )
          .forEach((res) => {
            if(this.checkAnaloguesCountry(res, analogue)){
              res.toggle.disable = event;
            }
          });
        this.defaultdataSource
          .filter(
            (res) =>
              res.Brand.toLowerCase().trim() ===
                analogue.Brand.toLowerCase().trim() &&
              res.Indication.name.toLowerCase().trim() ===
                analogue.Indication.name.toLowerCase().trim() &&
              res.INN.toLowerCase().trim() ===
                analogue.INN.toLowerCase().trim() &&
              res.HTAIndication.name.toLowerCase().trim() !==
                analogue.HTAIndication.name.toLowerCase().trim()
          )
          .forEach((res) => {
            if(this.checkAnaloguesCountry(res, analogue)){
              res.toggle.disable = event;
            }
          });
      }
      this.selectedAnalogue.forEach((data: any, index: number) => {
        if (
          this.checkSelectedAnaloues(data, analogue)
        ) {
          this.emitRemovedAnalogues.emit(data);
          // this.selectedAnalogue.splice(index, 1);
        }
      });
      this.sortedAnalogues.forEach((data: any, index: number) => {
        if (
          data.name.toLowerCase().trim() ===
            analogue.Brand.toLowerCase().trim() &&
          data.indication.trim().toLowerCase() ===
            analogue.Indication.name.trim().toLowerCase() &&
          data.inn.trim().toLowerCase() === analogue.INN.trim().toLowerCase()
        ) {
          this.sortedAnalogues.splice(index, 1);
        }
      });
      // this.emitRemovedAnalogues.emit(removeAnalogue)
      this.updateMarketsBrandCount();
    }
    // this.emitAnalogueList.emit(this.selectedAnalogue);
  }

  public checkAnaloguesCountry( analogue: AnalogueTableInterface,
    selectedAnalogue: AnalogueTableInterface):boolean{
      let state: boolean = false;
      selectedAnalogue.Country.forEach(res=>{
        state = analogue.Country.some(x=> x.name === res.name)
      })
      return state;
    
  }

  public checkAnaloues(
    analogue: AnalogueTableInterface,
    selectedAnalogue: AnalogueTableInterface
  ): boolean {
    let state: boolean = false;
    if (
      this.ValueFrameworkName=== 'Orphan'
    ) {
      state =
        selectedAnalogue.Brand.toLowerCase().trim() ===
          analogue.Brand.toLowerCase().trim() &&
        selectedAnalogue.Indication.name.toLowerCase().trim() ===
          analogue.Indication.name.toLowerCase().trim() &&
        selectedAnalogue.INN.toLowerCase().trim() ===
          analogue.INN.toLowerCase().trim();
    } else {
      state =
        selectedAnalogue.Brand.toLowerCase().trim() ===
          analogue.Brand.toLowerCase().trim() &&
        // selectedAnalogue.Indication.name.toLowerCase().trim() ===
        //   analogue.Indication.name.toLowerCase().trim() &&
        selectedAnalogue.INN.toLowerCase().trim() ===
          analogue.INN.toLowerCase().trim() &&
        selectedAnalogue.HTAIndication.name.toLowerCase().trim() ===
          analogue.HTAIndication.name.toLowerCase().trim() && this.checkAnaloguesCountry(analogue,selectedAnalogue);
    }
    return state;
  }

  public checkSelectedAnaloues(
    analogue: any,
    selectedAnalogue: AnalogueTableInterface
  ): boolean {
    let state: boolean = false;
    if (
      this.ValueFrameworkName=== 'Orphan'
    ) {
      state =
        selectedAnalogue.Brand.toLowerCase().trim() ===
          analogue.name.toLowerCase().trim() &&
        selectedAnalogue.Indication.name.toLowerCase().trim() ===
          analogue.indication.toLowerCase().trim() &&
        selectedAnalogue.INN.toLowerCase().trim() ===
          analogue.inn.toLowerCase().trim();
    } else {
      state =
        selectedAnalogue.Brand.toLowerCase().trim() ===
          analogue.name.toLowerCase().trim() &&
        // selectedAnalogue.Indication.name.toLowerCase().trim() ===
        //   analogue.Indication.name.toLowerCase().trim() &&
        selectedAnalogue.INN.toLowerCase().trim() ===
          analogue.inn.toLowerCase().trim() &&
        selectedAnalogue.HTAIndication.name.toLowerCase().trim() ===
          analogue.HTAIndication.toLowerCase().trim();
    }
    return state;
  }


  public setmarketForselectedAnalogues(analogue: any): any[] {
    let Markets: any[] = [];
    this.defaultdataSource
      .filter(
        (res) =>
          this.checkAnaloues(res, analogue)
      )
      .forEach((x) => {
        Markets = [...Markets, ...x.Country];
      });
    return Markets;
  }

  updateMarketsBrandCount() {
    const enabledBrands = JSON.parse(
      JSON.stringify(
        this.defaultdataSource.filter((res: any) => {
          return res.toggle.toggle === true;
        })
      )
    );
    let testBrands: any[] = [];
    enabledBrands.forEach((x: any) => {
      let index = testBrands.findIndex(
        (y) =>
          y.Brand.toLowerCase().trim() === x.Brand.toLowerCase().trim() &&
          y.Indication.name.toLowerCase().trim() ===
            x.Indication.name.toLowerCase().trim() &&
          y.INN.toLowerCase().trim() === x.INN.toLowerCase().trim()
      );
      if (index === -1) {
        testBrands.push(x);
      } else {
        x.Country.forEach((z: any) => {
          let countryIndex = testBrands[index].Country.findIndex(
            (a: any) => a.name === z.name
          );
          if (countryIndex === -1) {
            testBrands[index].Country.push(z);
          }
        });
      }
    });
    this.marketBrandCount.forEach((countryData: any) => {
      countryData.brandCount = testBrands.filter(
        (brandData) =>
          brandData.Country.filter(
            (m: any) =>
              m.name.toLowerCase().trim() ===
              countryData.country.toLowerCase().trim()
          ).length > 0
      ).length;
    });
    this.criteriaSelectionService.brandCountData.next(this.marketBrandCount);
  }

  saveNewList() {
    this.loading2 = true;
    let body = this.dynamicColums
      .filter((x) => x.visible === true)
      .map((y) => {
        return {
          Type: this.grid,
          Name: y.name,
        };
      });
    this.criteriaSelectionService
      .setColumns(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.getComparatorColumn([],false);
      });
  }

  filterbyHeat(filter: string): void {
    if (filter === 'Most met') {
      this.mostmet = !this.mostmet;
      this.leastmet = false;
      if (this.mostmet) {
        this.oreder1();
      } else {
        this.dataSource = this.defaultdataSource.sort(
          (a: AnalogueTableInterface, b: AnalogueTableInterface) =>
            a?.Brand.localeCompare(b.Brand)
        );
        this.tableheatmap.renderRows();
      }
    } else {
      this.leastmet = !this.leastmet;
      this.mostmet = false;
      if (this.leastmet) {
        this.oreder2();
      } else {
        this.dataSource = this.defaultdataSource.sort(
          (a: AnalogueTableInterface, b: AnalogueTableInterface) =>
            a?.Brand.localeCompare(b.Brand)
        );
        this.tableheatmap.renderRows();
      }
    }
  }

  public oreder1(): void {
    if( this.ValueFrameworkName===
      'Orphan'){
    this.dataSource = this.dataSource.sort(
      (a: AnalogueTableInterface, b: AnalogueTableInterface) => {
        if (a?.Orphan?.order !== b?.Orphan?.order) {
          return a?.Orphan?.order - b?.Orphan?.order;
        } else if (a?.Oneofftherapy?.order !== b?.Oneofftherapy?.order) {
          return a?.Oneofftherapy?.order - b?.Oneofftherapy?.order;
        } else if (a?.Population?.order !== b?.Population?.order) {
          return a?.Population?.order - b?.Population?.order;
        } else if (
          a?.Eligiblepatientnumber?.order !== b?.Eligiblepatientnumber?.order
        ) {
          return a?.Eligiblepatientnumber?.order - b?.Eligiblepatientnumber?.order;
        } else if (
          a?.Routeofadministration?.order !== b?.Routeofadministration?.order
        ) {
          return a?.Routeofadministration?.order - b?.Routeofadministration?.order;
        } else {
          return a?.Mechanismofaction?.order - b?.Mechanismofaction?.order;
        }
      }
    );
    }else{
      this.dataSource = this.dataSource.sort(
        (a: AnalogueTableInterface, b: AnalogueTableInterface) => {
          if (a?.HTAIndication?.order !== b?.HTAIndication?.order) {
            return a?.HTAIndication?.order - b?.HTAIndication?.order;
          } else if (a?.lineOffTherapy?.order !== b?.lineOffTherapy?.order) {
            return a?.lineOffTherapy?.order - b?.lineOffTherapy?.order;
          } else if (a?.Population?.order !== b?.Population?.order) {
            return a?.Population?.order - b?.Population?.order;
          }else if (a?.InitialLaunchDate?.order !== b?.InitialLaunchDate?.order) {
            return a?.InitialLaunchDate?.order - b?.InitialLaunchDate?.order;
          }else if (a?.Orphan?.order !== b?.Orphan?.order) {
            return a?.Orphan?.order - b?.Orphan?.order;
          }else if (a?.DurationOfTreatment?.order !== b?.DurationOfTreatment?.order) {
            return a?.DurationOfTreatment?.order - b?.DurationOfTreatment?.order;
          }else if (a?.EligiblePatientNumber?.order !== b?.EligiblePatientNumber?.order) {
            return a?.EligiblePatientNumber?.order - b?.EligiblePatientNumber?.order;
          }else if (a?.RouteOfAdministration?.order !== b?.RouteOfAdministration?.order) {
            return a?.RouteOfAdministration?.order - b?.RouteOfAdministration?.order;
          }else if (a?.MoaCategory?.order !== b?.MoaCategory?.order) {
            return a?.MoaCategory?.order - b?.MoaCategory?.order;
          }else if (a?.TherapyRegimen?.order !== b?.TherapyRegimen?.order) {
            return a?.TherapyRegimen?.order - b?.TherapyRegimen?.order;
          }else if (a?.TypeOfCombination?.order !== b?.TypeOfCombination?.order) {
            return a?.TypeOfCombination?.order - b?.TypeOfCombination?.order;
          }else {
            return a?.Tli?.order - b?.Tli?.order;
          }
        }
      );
    }
    this.tableheatmap.renderRows();
  }

  public oreder2(): void {
    if( this.ValueFrameworkName===
      'Orphan'){
    this.dataSource = this.dataSource.sort(
      (a: AnalogueTableInterface, b: AnalogueTableInterface) => {
        if (b?.Orphan?.order !== a?.Orphan?.order) {
          return b?.Orphan?.order - a?.Orphan?.order;
        } else if (b?.Oneofftherapy?.order !== a?.Oneofftherapy?.order) {
          return b?.Oneofftherapy?.order - a?.Oneofftherapy?.order;
        } else if (b?.Population?.order !== a?.Population?.order) {
          return b?.Population?.order - a?.Population?.order;
        } else if (
          b?.Eligiblepatientnumber?.order !== a?.Eligiblepatientnumber?.order
        ) {
          return b?.Eligiblepatientnumber?.order - a?.Eligiblepatientnumber?.order;
        } else if (
          b?.Routeofadministration?.order !== a?.Routeofadministration?.order
        ) {
          return b?.Routeofadministration?.order - a?.Routeofadministration?.order;
        } else {
          return b?.Mechanismofaction?.order - a?.Mechanismofaction?.order;
        }
      }
    );
  }else{
    this.dataSource = this.dataSource.sort(
      (a: AnalogueTableInterface, b: AnalogueTableInterface) => {
        if (b?.HTAIndication?.order !== a?.HTAIndication?.order) {
          return b?.HTAIndication?.order - a?.HTAIndication?.order;
        } else if (b?.lineOffTherapy?.order !== a?.lineOffTherapy?.order) {
          return b?.lineOffTherapy?.order - a?.lineOffTherapy?.order;
        } else if (b?.Population?.order !== a?.Population?.order) {
          return b?.Population?.order - a?.Population?.order;
        }else if (b?.InitialLaunchDate?.order !== a?.InitialLaunchDate?.order) {
          return b?.InitialLaunchDate?.order - a?.InitialLaunchDate?.order;
        }else if (b?.Orphan?.order !== a?.Orphan?.order) {
          return b?.Orphan?.order - a?.Orphan?.order;
        }else if (b?.DurationOfTreatment?.order !== a?.DurationOfTreatment?.order) {
          return b?.DurationOfTreatment?.order - a?.DurationOfTreatment?.order;
        }else if (b?.EligiblePatientNumber?.order !== a?.EligiblePatientNumber?.order) {
          return b?.EligiblePatientNumber?.order - a?.EligiblePatientNumber?.order;
        }else if (b?.RouteOfAdministration?.order !== a?.RouteOfAdministration?.order) {
          return b?.RouteOfAdministration?.order - a?.RouteOfAdministration?.order;
        }else if (b?.MoaCategory?.order !== a?.MoaCategory?.order) {
          return b?.MoaCategory?.order - a?.MoaCategory?.order;
        }else if (b?.TherapyRegimen?.order !== a?.TherapyRegimen?.order) {
          return b?.TherapyRegimen?.order - a?.TherapyRegimen?.order;
        }else if (b?.TypeOfCombination?.order !== a?.TypeOfCombination?.order) {
          return b?.TypeOfCombination?.order - a?.TypeOfCombination?.order;
        }else {
          return b?.Tli?.order - a?.Tli?.order;
        }
      }
    );
  }
    this.tableheatmap.renderRows();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
