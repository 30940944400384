import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  FilterCotLevelsEnum,
  FilterIncludeExcludeEnum,
  FilterItemInterface,
  FilterItemValueInterface,
  FilterMeasuresCOTTypeEnum,
  FilterMeasuresPeriodEnum,
  FilterMeasuresPriceCategoryEnum,
  FilterMeasuresPriceTypeEnum,
  FilterNameEnum,
  FilterProductParamsBrandedStatusEnum,
  FilterProductParamsOrphanStatusEnum,
  FilterProductParamsReimbursementEnum,
  FiltersObject,
} from './interfaces';
import { SetOfFiltersInterface } from '../../core/datastore-common';
import { ScopesDetailsService } from './scopes-details.service';
import { SessionStoreBase } from '../common/session-store-service';
import { ResponseStatus, SessionEnums } from '../common';
import { SharedService } from '../../uikit/service/shared.service';
import { BrandsAvailiabilityInterface } from './brands-availiability/interfaces';
import { BrandsCountryAvailiabilityInterface } from './brands-availiability/interfaces/brands-country-availiability.interface';
import { DatePipe } from '@angular/common';
import { cotApiInterface, cotCalculatorGridInterface, cotIdListInterface, packListInterface, regulatoryIndicationListInterface } from './cot-calculator/models/cot-calculator.interface';
import { FilterDefaultsCOTAdult } from './constants';
import { Message } from './indication-matrix/indication-matrix.provider';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FiltersValueService {
  readonly projectAPIUri: string = environment.gatewayApiEndpoint;

  filterValues$: BehaviorSubject<FiltersObject> =
    new BehaviorSubject<FiltersObject>([
      {
        filterName: FilterNameEnum.indicationsAllowed,
        filterValue: [{ name: 'Melanoma' }],
      },
      {
        filterName: FilterNameEnum.indications,
        filterValue: [{ name: 'Melanoma' }],
      },
      {
        filterName: FilterNameEnum.countries,
        filterValue: [{ name: 'USA' }, { name: 'UK' }, { name: 'GERMANY' }],
      },
      {
        filterName: FilterNameEnum.allCountriesInScope,
        filterValue: [{ name: false }],
      },
      { filterName: FilterNameEnum.brands, filterValue: [] },
      {
        filterName: FilterNameEnum.comparatorDrug,
        filterValue: [{ name: true }],
      },
      {
        filterName: FilterNameEnum.calcPer,
        filterValue: [{ name: 'Month', type: FilterMeasuresPeriodEnum.month }],
      },
      {
        filterName: FilterNameEnum.cotType,
        filterValue: [{ name: 'Adult', type: FilterMeasuresCOTTypeEnum.adult }],
      },
      {
        filterName: FilterNameEnum.includeWastage,
        filterValue: [{ name: 'Include wastage', type: true }],
      },
      {
        filterName: FilterNameEnum.averageBodyWeight,
        filterValue: [{ name: FilterDefaultsCOTAdult.averageBodyWeight }],
      },
      {
        filterName: FilterNameEnum.bodySurfaceArea,
        filterValue: [{ name: FilterDefaultsCOTAdult.bodySurfaceArea }],
      },
      {
        filterName: FilterNameEnum.priceCategory,
        filterValue: [
          { name: 'List', type: FilterMeasuresPriceCategoryEnum.List },
        ],
      },
      {
        filterName: FilterNameEnum.priceType,
        filterValue: [
          {
            name: 'Price Hierarchy',
            type: FilterMeasuresPriceTypeEnum.hierarchy,
          },
        ],
      },
      {
        filterName: FilterNameEnum.currency,
        filterValue: [{ name: 'Euro (€)', type: 'EUR' }],
      },
      {
        filterName: FilterNameEnum.localCurrency,
        filterValue: [{ name: false }],
      },
      {
        filterName: FilterNameEnum.orphanStatus,
        filterValue: [
          {
            name: 'Include all',
            type: FilterProductParamsOrphanStatusEnum.all,
          },
        ],
      },
      {
        filterName: FilterNameEnum.brandedStatus,
        filterValue: [
          {
            name: 'Include all',
            type: FilterProductParamsBrandedStatusEnum.all,
          },
        ],
      },
      {
        filterName: FilterNameEnum.discontinued,
        filterValue: [
          {
            name: 'Exclude discontinued',
            type: FilterIncludeExcludeEnum.exclude,
          },
        ],
      },
      {
        filterName: FilterNameEnum.reimbursementStatus,
        filterValue: [
          {
            name: 'Include All',
            type: FilterProductParamsReimbursementEnum.all,
          },
        ],
      },
      {
        filterName: FilterNameEnum.topLevelIndications,
        filterValue: [{ name: '' }],
      },
      { filterName: FilterNameEnum.onLoad, filterValue: [{ name: false }] },
      //{ filterName: FilterNameEnum.routeOfAdministartion, filterValue: [{ name: 'Route Of Administration', type: FilterRouteOfAdministrationEnum.Capsules }] }
      {
        filterName: FilterNameEnum.cotLevel,
        filterValue: [{ name: 'Lowest', type: FilterCotLevelsEnum.lowest }],
      },
      {
        filterName: FilterNameEnum.cotCalculator,
        filterValue: [{ name: 'cotCalculator', type: [] }],
      },
      { filterName: FilterNameEnum.corridorCOTIds, filterValue: [] },
    ]);

  maxBrands = 15;
  maxCountries = 20;
  maxCotBrands = 500;
  maxBrandsLimit = 100;
  minBrandsLimit = 2;
  maxFiveHundredBrandLimit = 500;
  indicationFiltersUpdated: boolean = false;
  public editedBrandList: cotApiInterface[] = [];
  public cotIdList = new BehaviorSubject<cotIdListInterface[]>([]);
  public castCotIdList = this.cotIdList.asObservable();


  brandValues$ = this.filterValues$.pipe(
    map(
      (common) =>
        this.findItemInFilters(common, FilterNameEnum.brands)?.filterValue
    )
  );

  countryValues$ = this.filterValues$.pipe(
    map(
      (common) =>
        this.findItemInFilters(common, FilterNameEnum.countries)?.filterValue
    )
  );

  countryNames$ = this.countryValues$.pipe(
    map((country) => country?.map((x) => x.name))
  );

  indicationValues$ = this.filterValues$.pipe(
    map((common) => {
      return this.findItemInFilters(common, FilterNameEnum.indications)
        ?.filterValue;
    })
  );

  indicationAllowedValues$ = this.filterValues$.pipe(
    map((common) => {
      return this.findItemInFilters(common, FilterNameEnum.indicationsAllowed)
        ?.filterValue;
    })
  );

  measures$ = this.filterValues$.pipe(
    map((common) => {
      return {
        calcPer: this.findItemInFilters(common, FilterNameEnum.calcPer)
          ?.filterValue[0].type,
        currency: this.findItemInFilters(common, FilterNameEnum.currency)
          ?.filterValue[0].type,
        includeWastage: this.findItemInFilters(
          common,
          FilterNameEnum.includeWastage
        )?.filterValue[0].type,
      };
    })
  );

  countryScopeSelected$ = this.filterValues$.pipe(
    map((common) => {
      return !!this.findItemInFilters(
        common,
        FilterNameEnum.allCountriesInScope
      )?.filterValue[0]?.name;
    })
  );

  localCurrencySelected$ = this.filterValues$.pipe(
    map((common) => {
      return !!this.findItemInFilters(common, FilterNameEnum.localCurrency)
        ?.filterValue[0]?.name;
    })
  );
  disabledForMaxBrandLimit$ = this.brandValues$.pipe(
    map((value) => {
      return value.length > this.maxBrandsLimit;
    })
  );

  disabledCotCompare$ = this.brandValues$.pipe(
    map((value) => {
      return value.length >= 2;
    })
  );
  disabledForMaxFiveHundredBrandLimit$ = this.brandValues$.pipe(
    map((value) => {
      return value.length > this.maxFiveHundredBrandLimit;
    })
  );
  maxBrandsExceeded$ = this.brandValues$.pipe(
    map((value) => {
      return value.length > this.maxBrands;
    })
  );

  maxCountriesExceeded$ = this.countryValues$.pipe(
    map((value) => {
      return value.length > this.maxCountries;
    })
  );

  constructor(
    private scopesDetailsService: ScopesDetailsService,
    private session: SessionStoreBase,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private httpClient: HttpClient
  ) {}

  findItemInFilters(
    filtersObject: FiltersObject,
    filterName: FilterNameEnum
  ): FilterItemInterface {
    return (
      (filtersObject &&
        filtersObject.find((item) => item.filterName === filterName)) || {
        filterName,
        filterValue: [],
      }
    );
  }

  updateItemInFilters(
    filterNameToUpdate: FilterNameEnum,
    newFilterValue: FilterItemValueInterface[],
    filtersObject: FiltersObject = []
  ): FiltersObject {
    if (filtersObject.length === 0)
      filtersObject = this.filterValues$.getValue();

    this.indicationFiltersUpdated = false;
    const updatedFiltersObject: FiltersObject = [
      ...filtersObject.filter(
        (filtersItem) => filtersItem.filterName !== filterNameToUpdate
      ),
    ];
    updatedFiltersObject.push({
      filterName: filterNameToUpdate,
      filterValue: newFilterValue,
    });
    if (filterNameToUpdate === FilterNameEnum.brands) {
      this.setBrandsProperty(newFilterValue);
    }
    else if (filterNameToUpdate === FilterNameEnum.indications) {
      this.setIndidactionsProperty(newFilterValue);
    }
    else if (filterNameToUpdate === FilterNameEnum.indicationsAllowed) {
      this.setIndidactionsAllowedProperty(newFilterValue);
    }
    return updatedFiltersObject;
  }

  refreshItemsInFilters(
    filterNameToUpdate: FilterNameEnum,
    newFilterValue: FilterItemValueInterface[]
  ): FiltersObject {
    let findObj: FilterItemInterface = this.findItemInFilters(
      this.filterValues$.getValue(),
      filterNameToUpdate
    );
    let indexNo = this.filterValues$.value.indexOf(findObj);
    if (indexNo >= 0) this.filterValues$.value.splice(indexNo, 1);
    this.filterValues$.value.push({
      filterName: filterNameToUpdate,
      filterValue: newFilterValue,
    });
    return this.updateItemInFilters(filterNameToUpdate, newFilterValue);
  }

  updateIndicationsAllowedItemInFilters(
    newFilterValue: FilterItemValueInterface[], clearValueEmitVal?: boolean
  ): FiltersObject {
    let filterNameToUpdate: FilterNameEnum = FilterNameEnum.indicationsAllowed;
    let findObj: FilterItemInterface = this.findItemInFilters(
      this.filterValues$.getValue(),
      filterNameToUpdate
    );
    let indexNo = this.filterValues$.value.indexOf(findObj);
    if (indexNo >= 0) this.filterValues$.value.splice(indexNo, 1);
    newFilterValue.forEach((element: any) => {
      element.type = 'indication';
      element.abbreviation = null;
    });
    this.filterValues$.value.push({
      filterName: FilterNameEnum.indicationsAllowed,
      filterValue: newFilterValue,
    });

    let oldTLi =   this.findItemInFilters(this.filterValues$.getValue(), FilterNameEnum.indications).filterValue || [];
    let newTLi = this.findItemInFilters(this.filterValues$.value, FilterNameEnum.indicationsAllowed).filterValue || [];
 
    // if(!newFilterValue.some(x=> oldTLi[0].name === x.name)){
    //   if(newTLi[0].name !== oldTLi[0].name){
    //     const nextValue: FiltersObject =  this.updateItemInFilters(FilterNameEnum.indications, [newTLi[0]]);
    //     this.filterValues$.next(nextValue);
    //   }
    // }
       
    if(oldTLi.length>0)
    {
        if(!newFilterValue.some(x=> oldTLi[0].name === x.name)){
          if(newTLi.length>0)
          {
                if(newTLi[0].name !== oldTLi[0].name){
                const nextValue: FiltersObject =  this.updateItemInFilters(FilterNameEnum.indications, [newTLi[0]]);
                this.filterValues$.next(nextValue);
              }
           } 
        }
    }
    else if(newTLi.length>0 && !clearValueEmitVal)   
    {
          const nextValue: FiltersObject =  this.updateItemInFilters(FilterNameEnum.indications, [newTLi[0]]);
          this.filterValues$.next(nextValue);
     } 

  
    
    return this.updateItemInFilters(filterNameToUpdate, newFilterValue);
    
  }

  deletedItemInFilters(
    item: FilterItemValueInterface,
    filterType: FilterNameEnum,
    filtersValue: FiltersObject
  ): FiltersObject {
    const filterItemValue: FilterItemValueInterface[] =
      this.findItemInFilters(filtersValue, filterType)?.filterValue || [];
    return this.updateItemInFilters(
      filterType,
      filterItemValue.filter((item1) => item1.id !== item.id)
    );
  }

  toTitleCase(str:string) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  getSetOfFilters(
    filtersObject: FiltersObject,
    isFromIndicationMatrix = false
  ): SetOfFiltersInterface {
    const indications: string[] = this.findItemInFilters(
      filtersObject,
      FilterNameEnum.indications
    )?.filterValue.length
      ? isFromIndicationMatrix
        ? []
        : [
            this.findItemInFilters(
              filtersObject,
              FilterNameEnum.indications
            )?.filterValue.map((item) => item.name)[0],
          ]
      : [];
    const allBrands =
      this.findItemInFilters(
        filtersObject,
        FilterNameEnum.brands
      )?.filterValue.map((item) => item.name) || [];

    const allCountriesInScopeSelected = !!this.findItemInFilters(
      filtersObject,
      FilterNameEnum.allCountriesInScope
    )?.filterValue[0]?.name;
    let CotIds: any[] = this.cotIdList.value.map((x: any) => x.cotId);

    const countries = allCountriesInScopeSelected
    ? this.scopesDetailsService
        .getUserScopesDetails()
        .countries.map((c) => this.toTitleCase(c.name))
    : this.findItemInFilters(
        filtersObject,
        FilterNameEnum.countries
      )?.filterValue.map((item) => this.toTitleCase(item.name)) || [];
        
    return {
      indications,
      countries,
      brands: allBrands,
      comparatorDrug: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.comparatorDrug
      )?.filterValue[0].name,
      calcPer: this.findItemInFilters(filtersObject, FilterNameEnum.calcPer)
        ?.filterValue[0].type,
      cotType: this.findItemInFilters(filtersObject, FilterNameEnum.cotType)
        ?.filterValue[0].type,
      includeWastage: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.includeWastage
      )?.filterValue[0].type,
      reimbursementStatus: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.reimbursementStatus
      )?.filterValue[0].type,
      averageBodyWeight: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.averageBodyWeight
      )?.filterValue[0].name,
      bodySurfaceArea: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.bodySurfaceArea
      )?.filterValue[0].name,
      priceCategory: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.priceCategory
      )?.filterValue[0].type,
      priceType: this.findItemInFilters(filtersObject, FilterNameEnum.priceType)
        ?.filterValue[0].type,
      currency: this.findItemInFilters(filtersObject, FilterNameEnum.currency)
        ?.filterValue[0].type,
      searchType: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.searchType
      )?.filterValue[0].type,
      localCurrency: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.localCurrency
      )?.filterValue[0].name,
      orphanStatus: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.orphanStatus
      )?.filterValue[0].type,
      brandedStatus: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.brandedStatus
      )?.filterValue[0].type,
      discontinued: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.discontinued
      )?.filterValue[0].type,
      onLoad: this.findItemInFilters(filtersObject, FilterNameEnum.onLoad)
        ?.filterValue[0].name,
      isRegulatoryIndication:
        this.sharedService.datastoreRegulatoryIndicationFlag$.value,
      sortColumn:
        this.sharedService.gridmodesortType$.value === 0
          ? ''
          : this.sharedService.gridmodesortColumn$.value,
      sortDirection:
        this.sharedService.gridmodesortType$.value === 0
          ? ''
          : this.sharedService.gridmodesortType$.value == 1
          ? 'asc'
          : 'desc',
      pageNumber: this.sharedService.gridmodePageNumber$.value,
      pageSize: this.sharedService.gridmodeRecordPerPage$.value,
      selectedColumns: '',
      cotLevel: this.findItemInFilters(filtersObject, FilterNameEnum.cotLevel)
        ?.filterValue[0].type,
      cotCalculator: this.findItemInFilters(
        filtersObject,
        FilterNameEnum.cotCalculator
      )?.filterValue[0].type,
      corridorCOTIds: CotIds,
    };
  }

  hasRequiredValue(payload: SetOfFiltersInterface): boolean {
    return !!(
      payload.brands.length &&
      payload.countries.length &&
      payload.indications.length
    );
  }

  hasRequiredValuesPackDetails(payload: SetOfFiltersInterface): boolean {
    return !!(payload.brands.length && payload.countries.length);
  }

  setBrandsProperty(brands: FilterItemValueInterface[]) {
    this.session.setSession(SessionEnums.brands, brands);
  }

  setIndidactionsProperty(indications: FilterItemValueInterface[]) {
    this.session.setSession(SessionEnums.indications, indications);
  }

  setIndidactionsAllowedProperty(indications: FilterItemValueInterface[]) {
    this.session.setSession(SessionEnums.indicationsAllowed, indications);
  }

  getProperty(keyName: SessionEnums): FilterItemValueInterface[] {
    let storedProp = this.session.getSession(keyName);
    let storedParsed = storedProp
      ? JSON.parse(storedProp)
      : null;
    if (storedParsed && !Array.isArray(storedParsed)) {
      storedParsed = [storedParsed];
    }
    return storedParsed;
  }

  gridMapData(
    countries: string[],
    dataset: BrandsAvailiabilityInterface[],
    featureFlag: boolean,
    featureFlagLaunchDate: boolean
  ): any[] {
    return countries.map((country: string) => {
      let result: any = {
        colName: country,
      };
      if (featureFlag) {
        result = {
          colName: country,
          availDetials: {
            availableStatus: 'Availability Status',
            approvalDate: 'Initial Indication Approval Date',
            ...(featureFlagLaunchDate ? { launchDate: 'Launch Date' } : {}),
            reimbursementStatus: 'Reimbursement Status',
          },
          displayMore: false,
        };
      } else {
        result = {
          colName: country,
        };
      }

      dataset.forEach((brand: BrandsAvailiabilityInterface) => {
        let available = this.isAvailable(brand, country);
        let BrnadData: BrandsCountryAvailiabilityInterface = {
          country: '',
        };
        if (available) {
          BrnadData = this.isAvailableDateCheck(brand, country);
        }
        if (featureFlag) {
          result = {
            ...result,
            [brand.brandedName.toLowerCase().replace('.', '')]: {
              availableStatus: available
                ? BrnadData.availabilityStatus != null
                  ? BrnadData.availabilityStatus
                  : '-'
                : '-',
              approvalDate: available
                ? BrnadData.indicationApprovalDate != null
                  ? this.datePipe.transform(
                      BrnadData.indicationApprovalDate,
                      'dd/MM/y'
                    )
                  : '-'
                : '-',
                ...(featureFlagLaunchDate
                  ? {
                      launchDate: available
                        ? BrnadData.launchDate != null
                          ? this.datePipe.transform(BrnadData.launchDate, 'dd/MM/y')
                          : '-'
                        : '-',
                    }
                  : {}),
              reimbursementStatus: available
                ? BrnadData.reimbursementStatus != null
                  ? BrnadData.reimbursementStatus
                  : '-'
                : '-',
            },
          };
        } else {
          result = {
            ...result,
            [brand.brandedName.toLowerCase()]: available ? 'Available' : '-',
          };
        }
      });
      return result;
    });
  }

  private isAvailable(
    brand: BrandsAvailiabilityInterface,
    country: string
  ): boolean {
    return !!(
      brand.availableIn &&
      brand.availableIn.length &&
      brand.availableIn.some(
        (countryInArray: BrandsCountryAvailiabilityInterface) =>
          countryInArray.country.toLowerCase() === country.toLowerCase()
      )
    );
  }

  private isAvailableDateCheck(
    brand: BrandsAvailiabilityInterface,
    country: string
  ): BrandsCountryAvailiabilityInterface {
    let available: any = {};
    available = brand.availableIn.filter(
      (avail) => avail.country.toLowerCase() === country.toLowerCase()
    )[0];
    return available;
  }

  public mapCotData(data: cotCalculatorGridInterface[]): void {
    this.editedBrandList = data
      .filter((x) => x.edited)
      .map((x) => {
        return {
          brandID: x.brandID,
          brandName: x.brandName,
          costOfTreatmentId: x.costOfTreatmentId,
          countryID: x.countryID,
          countryName: x.countryName,
          packList: this.mapeditedPack(x),
          topLevelIndication: x.topLevelIndication,
          topLevelIndicationId: x.topLevelIndicationId,
        };
      });
    this.updateFilter();
  }

  public mapeditedPack(
    packList: cotCalculatorGridInterface
  ): packListInterface[] {
    return packList.packDescriptionList
      .filter((x) => x.packID === packList.packDescription.currentValue.id)
      .map((editedpack) => {
        return {
          currency: editedpack.currency,
          ddd: editedpack.ddd,
          packDescription: editedpack.packDescription,
          packID: editedpack.packID,
          perMg: packList.pricePerMg.currentValue,
          perPack: editedpack.perPack,
          perUnit: packList.pricePerUnit.currentValue,
          regulatoryIndicationList: this.mapEditedIndication(packList),
          strength: editedpack.strength,
          strengthUnit: editedpack.strengthUnit,
        };
      });
  }

  public mapEditedIndication(
    indicationList: cotCalculatorGridInterface
  ): regulatoryIndicationListInterface[] {
    return indicationList.regulatoryIndicationList
      .filter(
        (x) =>
          x.regulatoryIndicationID ===
          indicationList.regulatoryIndication.currentValue.id
      )
      .map((editedindication) => {
        return {
          averageWeight: indicationList.averageBodyWeight.currentValue,
          bodySurfaceArea: indicationList.averageBodySurfaceArea.currentValue,
          cycle: editedindication.cycle,
          dosingAssumptionId: editedindication.dosingAssumptionId,
          durationLoading: indicationList.loadingDoseDuration.currentValue,
          durationMaintenance:
            indicationList.maintainanceDoseDuration.currentValue,
          frequencyLoading: indicationList.loadingDoseFrequency.currentValue,
          frequencyMaintenance:
            indicationList.maintainanceDoseFreaquency.currentValue,
          productDosingLoading: indicationList.loadingDose.currentValue,
          productDosingMaintenance:
            indicationList.maintainanceDose.currentValue,
          regulatoryIndication: editedindication.regulatoryIndication,
          regulatoryIndicationID: editedindication.regulatoryIndicationID,
          unit: indicationList.duration.currentValue.id.toUpperCase(),
        };
      });
  }

  updateFilter(): void {
    const item: FilterItemValueInterface = {
      name: FilterNameEnum.corridorCOTIds,
      type: this.editedBrandList,
    };
    const filtersValue: FiltersObject = this.updateItemInFilters(
      FilterNameEnum.cotCalculator,
      [item]
    );
    this.filterValues$.next(filtersValue);
  }

  public updateCOtlist(idLIst:cotIdListInterface[]){
    if(this.cotIdList.value.length === 0){
      this.cotIdList.next(idLIst)
    }else{
      let oldCotIdList:cotIdListInterface[] = JSON.parse(JSON.stringify(this.cotIdList.value))
      idLIst.forEach(cot=>{
        if(cot.isCOTCalculated){
          let filteredCOt = oldCotIdList.filter(x=> x.brandName === cot.brandName && x.country === cot.country && x.indication === cot.indication)[0]
          //applying old cotid because when the cot is being calculated in the server the id is assigned 0;
          if(filteredCOt !== undefined){
            cot.cotId = filteredCOt.cotId;
          }
        }
      })
      this.cotIdList.next(idLIst)
    }
  }

  getGeneralInfo(): Observable<any> {
    const payload: SetOfFiltersInterface = this.getSetOfFilters(this.filterValues$.value);
    const urlString = new URL(
      `${this.projectAPIUri}/brand/generalInfo`
    );
    return this.httpClient.post<any>(urlString.href, payload);

  }
}
