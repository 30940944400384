<div *ngIf="!showSection">
  <he-loading-spinner
    [diameter]="70"
    [height]="'350px'"
  ></he-loading-spinner>
</div>
<div *ngIf="showSection" class="card-he">
  <mat-card>
    <div class="heading">
      Analogues
    </div>
    <mat-card-content>
      <div class="analog-table table-responsive">
        <mat-table [dataSource]="dataSource.data">

          <ng-container matColumnDef="sequenceNo" class="hideCls">
            <mat-header-cell *matHeaderCellDef class="sequenceNo hideCls">
              Id
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="sequenceNoRow hideCls">
              {{ element.sequenceNo }}
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="brandName">
            <mat-header-cell *matHeaderCellDef class="mat-column-brandName">
              Brand Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column-brandName">
              {{ element.brandName }}
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="indication">
            <mat-header-cell *matHeaderCellDef class="mat-column-indication">
              Indication
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column-indication">
              {{ element.indication }}
            </mat-cell>
          </ng-container>
      
          <ng-container *ngIf="this.headerService.IsOncologyFrameworkSelected" matColumnDef="indicationHTA">
            <mat-header-cell *matHeaderCellDef class="mat-column-indicationHTA-header">
              HTA Indication
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column-indicationHTA">
              {{ element.indicationHTA || 'NA'}}
            </mat-cell>
          </ng-container>  
      
          <ng-container matColumnDef="mechanism">
            <mat-header-cell *matHeaderCellDef class="mat-column-mechanism">
              Mechanism of Action
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column-mechanism">
              {{ element.mechanism }}
            </mat-cell>
          </ng-container>       
      
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            [ngClass]="{ highlight: row?.highlight === true }"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
      </mat-table>
      
      </div>
    </mat-card-content>
  </mat-card>
</div>
