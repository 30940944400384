import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { startWith, Subject, take, takeUntil } from 'rxjs';
import {
  ComporatorListInterface,
  ComporatorTableInterface,
  LegendEnum,
} from '../../models/criteria.interface';
import { FormControl } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'he-comporator-heat-map',
  templateUrl: './comporator-heat-map.component.html',
  styleUrls: ['./comporator-heat-map.component.scss'],
})
export class ComporatorHeatMapComponent
  implements OnInit, OnDestroy, OnChanges
{
  displayedColumns: any[] = [];
  columnsToDisplay: string[] = [];
  data: any[] = ELEMENT_DATA;

  @ViewChild(MatTable) public tableheatmap!: MatTable<any>;

  @Output() public emitAnalogueList = new EventEmitter<
    ComporatorListInterface[]
  >();
  @Output() public emitRemovedAnalogues =
    new EventEmitter<ComporatorListInterface>();
  @Output() public removeCountryFilter = new EventEmitter<string>();
  @Input() public loading = false;
  @Input() public loading2 = false;
 // @Input() public dataSource: ComporatorTableInterface[] = [];
  //@Input() public defaultdataSource: ComporatorTableInterface[] = [];
  @Input() public selectedAnalogue: ComporatorListInterface[] = [];
  @Input() public sortedAnalogues: any[] = [];
  public searchTable = new FormControl('');
  public sort: any;
  public checked = false;
  public disabled = false;
  public leastmet = false;
  public mostmet = false;
  public marketBrandCount: any = [];
  public attributes: any = [];
  public dataSource_all:ComporatorTableInterface[] = [];
 public dataSource:ComporatorTableInterface[] = [];
  public defaultdataSource:ComporatorTableInterface[] = [];
  public searchTooltip: any = 'Search by Brand Name, INN or MOA for comparators';

  public legends = [
    {
      name: 'Meets criteria',
      color: LegendEnum[1],
    },
    {
      name: 'Partially meets criteria',
      color: LegendEnum[2],
    },
    {
      name: 'Does not meet criteria',
      color: LegendEnum[3],
    },
    {
      name: 'No criteria included',
      color: LegendEnum[0],
    },
  ];

  public headerNamelist: {
    apititle: string;
    title: string;
    default: boolean;
    sticky?: boolean;
  }[] = [
    {
      apititle: 'toggle',
      title: '',
      default: false,
      sticky:true
    },
    {
      apititle: 'Brand',
      title: 'Brand Name',
      default: true,
      sticky:true

    },
    {
      apititle: 'Orphan',
      title: 'Orphan',
      default: false,
      sticky:false
    },
    {
      apititle: 'INN',
      title: 'INN',
      default: true,
      sticky:true
    },
    {
      apititle: 'Country',
      title: 'Market Availability',
      default: true,
      sticky:false
    },
    {
      apititle: 'HTAIndication',
      title: 'HTA Indication',
      default: true,
      sticky:false
      
    },
    {
      apititle: 'BiomarkerGenetyping',
      title: 'Biomarker/gene-typing',
      default: true,
      sticky:false

    },
    {
      apititle: 'lineOffTherapy',
      title: 'Line of therapy',
      default: true,
      sticky:false

    },
    {
      apititle: 'Population',
      title: 'Population',
      default: true,
      sticky:false

    },
    {
      apititle: 'EligiblePatientNumber',
      title: 'Eligible Patient Number',
      default: false,
      sticky:false

    },
    {
      apititle: 'RouteOfAdministration',
      title: 'Route of administration',
      default: false,
      sticky:false

    },
    {
      apititle: 'Mechanismofaction',
      title: 'Mechanism of action',
      default: false,
      sticky:false

    },
    {
      apititle: 'InitialLaunchDate',
      title: 'Initial Launch Date',
      default: false,
      sticky:false

    },
    {
      apititle: 'MoaCategory',
      title: 'Mechanism of action category',
      default: false,
      sticky:false

    },
    {
      apititle: 'PriorReimbursedIndications',
      title: 'Prior Reimbursed Indications',
      default: false,
      sticky:false

    },
    {
      apititle: 'TherapyRegimen',
      title: 'Therapy Regimen',
      default: false,
      sticky:false

    },
    {
      apititle: 'Tli',
      title: 'Top Level Indication',
      default: false,
      sticky:false

    },
    {
      apititle: 'TypeOfCombination',
      title: 'Type Of Combination',
      default: false,
      sticky:false

    },
    {
      apititle: 'DurationOfTreatment',
      title: 'Duration Of Treatment',
      default: false,
      sticky:false

    },
  ];

  public dynamicColums: any[] = [

  ];

  private unsubscriber$ = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private criteriaSelectionService: CriteriaSelectionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMarketsBrandCount();
    this.mostmet=false;
    this.leastmet=false;
  }

  ngOnInit(): void {
    this.getComparatorColumn([]);
    this.searchTable.valueChanges
      .pipe(startWith(null))
      .subscribe((analogue: string | null) => {
        this.removeCountryFilter.emit('');
        this.searchTableData(analogue);
      });
    this.criteriaSelectionService.brandCountData
      .pipe(take(1))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.marketBrandCount = res;
      });
    this.getMarkets();
  }

  saveNewList() {
    this.loading2 = true;
    let body = this.dynamicColums
      .filter((x) => x.visible === true)
      .map((y) => {
        return {
          Type: 'comparatorGrid',
          Name: y.name,
        };
      });
    this.criteriaSelectionService
      .setColumns(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.getComparatorColumn([]);
      });
  }

  public getComparatorColumn(dataset : any[]): void {
    this.defaultdataSource=dataset;
    this.criteriaSelectionService
      .getComparatorDefault('comparatorGrid')
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.columnsToDisplay = [];
        this.displayedColumns = [];
        this.dynamicColums = [];
        this.columnsToDisplay.push('toggle');
        this.columnsToDisplay = [
          ...this.columnsToDisplay,
          ...res.selectedColumns.split(','),
        ];
        this.displayedColumns = this.columnsToDisplay.map((x) => {
          return {
            name: x,
            headerName: this.headerNamelist.find((y) => y.apititle === x)
              ?.title,
             sticky: this.headerNamelist.find((y) => y.apititle === x)
             ?.sticky,

          };
        });
        this.dynamicColums = res.selectedColumns.split(',').map((x: string) => {
          return {
            name: x,
            title: this.headerNamelist.find((y) => y.apititle === x)?.title,
            default: this.headerNamelist.find((y) => y.apititle === x)?.default,
            visible: true,
          };
        });
        if(res.unSelectedColumns != ''){
          this.dynamicColums = [
            ...this.dynamicColums,
            ...res.unSelectedColumns.split(',').map((x: string) => {
              return {
                name: x,
                title: this.headerNamelist.find((y) => y.apititle === x)?.title,
                default: false,
                visible: false,
              };
            }),
          ];
        }
        this.loading2 = false;
      });
   let selectColumn=   this.dynamicColums.filter((x) => x.visible === true)
      .map((y) => 
        y.name,
      );

      selectColumn.push('toggle');
      selectColumn.push('Tli');
      if( dataset.length!=0)
        {
          this.dataSource_all=dataset;
        }
       this.dataSource= this.filterDataset(this.dataSource_all.length>0 ? this.dataSource_all : this.dataSource, selectColumn);
       this.dataSource=this.removeDuplicates(this.dataSource,selectColumn);
       this.dataSource=this.mergeRows(this.dataSource);
       this.defaultdataSource=this.dataSource;
   

  }


// Function to filter dataset and keep only specified columns
filterDataset(dataset: any[], columns: string[]): any[] {
 // columns = columns.filter(col => col !== 'toggle');
  return dataset.map(item => {
    const filteredItem: any = {};
    columns.forEach(col => {
      filteredItem[col] = item[col];
    });

    return filteredItem;
  });
}

removeDuplicates(dataset: any[], uniqueColumns: string[]): any[] {
  const seen = new Set();
  return dataset.filter(item => {
    // Create a unique key based on the specified columns
    const key = uniqueColumns.map(col => {
      const value = item[col];

      if (Array.isArray(value)) {
        // If the value is an array, check if each item has a `name` property
        return value
        .map((obj: any) => obj && obj.name ? obj.name : obj)  // Use `name` property if it exists, otherwise use the value itself
          .slice() // Create a shallow copy of the array
          .sort() // Sort the array
          .join(','); // Join the sorted values into a string
      } else if (value && typeof value === 'object') {
        // If the value is an object (not an array), convert to a string representation
        return value.name || JSON.stringify(value);
      } else {
        // For non-array and non-object values, return the value directly
        return value;
      }
    }).join('-'); // Join all column values into a single key

    // If the key hasn't been seen yet, add it to the Set and keep the item
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }

    // Otherwise, it's a duplicate, so exclude it
    return false;
  });
}
mergeRows(dataset: any[]): any[] {
  const mergedData: any[] = [];
  const mergeColumns = ["lineOffTherapy", "BiomarkerGenetyping"];
  
  dataset.forEach((current) => {
    // Find an existing entry that matches all columns except the ones in mergeColumns
    const existing = mergedData.find((entry) => {
      return Object.keys(current).every((key) => {
        // Skip comparison for mergeColumns (lineOffTherapy, BiomarkerGenetyping)
        if (mergeColumns.includes(key)) return true;

        // Compare all other columns
        return JSON.stringify(entry[key]) === JSON.stringify(current[key]);
      });
    });

    if (existing) {
      // If a match is found, merge only the specified columns (lineOffTherapy, BiomarkerGenetyping)
      mergeColumns.forEach((col) => {
        if (existing[col]?.name && current[col]?.name) {
          // Merge only if the names are different
          if (!existing[col].name.includes(current[col].name)) {
            existing[col].name += `, ${current[col].name}`;
          }
        }
      });
    } else {
      // If no match is found, add the current item to the mergedData array
      mergedData.push({ ...current });
    }
  });

  // Return the final merged dataset without duplicates
  return mergedData;
}
  public searchTableData(search: string | null): void {
    search
      ? (this.dataSource = this._filter(search))
      : (this.dataSource = this.defaultdataSource.slice());
    if (this.sort !== undefined) {
      this.sortData(this.sort);
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    let filteredList = [];
    filteredList = this.defaultdataSource.filter((analogue) => {
      return JSON.stringify(Object.values(analogue))
        .toLowerCase()
        .includes(filterValue);
    });
    return filteredList;
  }

  public sortData(sort: Sort): void {
    this.sort = sort;
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction !== 'asc';
      switch (sort.active) {
        case 'Brand Name':
          return compare(a.Brand, b.Brand, isAsc);
        case 'INN':
          return compare(a.INN, b.INN, isAsc);
        // case 'Marketavailability':
        //   return compare(a.Marketavailability.name, b.Marketavailability.name, isAsc);
        case 'HTA Indication':
          return compare(a.HTAIndication.name, b.HTAIndication.name, isAsc);
        case 'Duration Of Treatment':
          return compare(
            a.DurationOfTreatment.name,
            b.DurationOfTreatment.name,
            isAsc
          );
        case 'Biomarker/gene-typing':
          return compare(
            a.BiomarkerGenetyping.name,
            b.BiomarkerGenetyping.name,
            isAsc
          );
        case 'Population':
          return compare(a.Population.name, b.Population.name, isAsc);
        case 'Eligible Patient Number':
          return compare(
            a.EligiblePatientNumber.name,
            b.EligiblePatientNumber.name,
            isAsc
          );
        // case 'Indication':
        //   return compare(a.Indication.name, b.Indication.name, isAsc);
        case 'Route of administration':
          return compare(
            a.RouteOfAdministration.name,
            b.RouteOfAdministration.name,
            isAsc
          );
        case 'Mechanism of action category':
          return compare(
            a.Mechanismofaction.name,
            b.Mechanismofaction.name,
            isAsc
          );
        case 'Line of therapy':
          return compare(a.lineOffTherapy.name, b.lineOffTherapy.name, isAsc);
        case 'Therapy Regimen':
          return compare(a.TherapyRegimen.name, b.TherapyRegimen.name, isAsc);
        case 'Type Of Combination':
          return compare(
            a.TypeOfCombination.name,
            b.TypeOfCombination.name,
            isAsc
          );
        case 'Prior Reimbursed Indications':
          return compare(
            a.PriorReimbursedIndications.name,
            b.PriorReimbursedIndications.name,
            isAsc
          );
        case 'Orphan':
          return compare(a.Orphan.name, b.Orphan.name, isAsc);
          case 'Initial Launch Date':
            return compare(a.InitialLaunchDate.name, b.InitialLaunchDate.name, isAsc);
        default:
          return compare(a.Brand, b.Brand, isAsc);
      }
    });
  }

  public getMarkets() {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        data?.selectedMarkets?.forEach((market: any) => {
          if (!this.marketBrandCount.some((x: any) => x.country === market)) {
            this.marketBrandCount.push({
              country: market,
              brandCount: 0,
            });
          }
        });
        this.marketBrandCount.forEach((market: any, index: number) => {
          if (!data?.selectedMarkets.some((x: any) => x === market.country)) {
            this.marketBrandCount.splice(index, 1);
          }
        });
      });
    this.criteriaSelectionService.brandCountData.next(this.marketBrandCount);

    // this.updateMarketsBrandCount();
  }
  public selectBrand(event: boolean, analogue: any): void {
    if (event) {
      if (
        this.selectedAnalogue.filter(
          (x) =>
            x.name.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            x.HTAIndication.toLowerCase().trim() ===
              analogue.HTAIndication.name.toLowerCase().trim()
          // && x.biomarkerGenetyping.toLowerCase().trim() === analogue.BiomarkerGenetyping.name.toLowerCase().trim()
        ).length == 0
      )
        this.selectedAnalogue.push({
          name: analogue.Brand ?? null,
          HTAIndication: analogue.HTAIndication ? analogue.HTAIndication.name: null,
          indication:analogue.Tli?  analogue.Tli.name : null,
          biomarkerGenetyping: analogue.BiomarkerGenetyping?  analogue.BiomarkerGenetyping.name : null,
          lineOfTherapy: analogue.lineOffTherapy?  analogue.lineOffTherapy.name : null,
          inn: analogue.INN?? null,
          sequenceNo: this.selectedAnalogue.length + 1,
          available: true,
          market: this.setmarketForselectedAnalogues(analogue),
        });
      this.dataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            // && res.BiomarkerGenetyping.name.toLowerCase().trim() === analogue.BiomarkerGenetyping.name.toLowerCase().trim()
            res.HTAIndication.name.toLowerCase().trim() ===
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.toggle = event;
        });

      this.defaultdataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            // res.BiomarkerGenetyping.name.toLowerCase().trim() === analogue.BiomarkerGenetyping.name.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() ===
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.toggle = event;
        });
      this.dataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() !==
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.disable = true;
        });
      this.defaultdataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() !==
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.disable = true;
        });
      this.updateMarketsBrandCount();
      this.emitAnalogueList.emit(this.selectedAnalogue);
    } else {
      // let removeAnalogue = this.selectedAnalogue.filter((data:any)=>{
      //   return (data.name === analogue.Brand && data.indication === analogue.Indication.name)
      // })[0]
      this.dataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            // res.BiomarkerGenetyping.name.toLowerCase().trim() === analogue.BiomarkerGenetyping.name.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() ===
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res, index) => {
          res.toggle.toggle = event;
        });
      this.defaultdataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            // res.BiomarkerGenetyping.name.toLowerCase().trim() === analogue.BiomarkerGenetyping.name.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() ===
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.toggle = event;
        });
      this.dataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() !==
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.disable = false;
        });
      this.defaultdataSource
        .filter(
          (res) =>
            res.Brand.toLowerCase().trim() ===
              analogue.Brand.toLowerCase().trim() &&
            res.HTAIndication.name.toLowerCase().trim() !==
              analogue.HTAIndication.name.toLowerCase().trim()
        )
        .forEach((res) => {
          res.toggle.disable = false;
        });
      this.selectedAnalogue.forEach((data: any, index: number) => {
        if (
          data.name.toLowerCase().trim() ===
            analogue.Brand.toLowerCase().trim() &&
          data.HTAIndication.toLowerCase().trim() ===
            analogue.HTAIndication.name.toLowerCase().trim()
          // &&
          // data.biomarkerGenetyping.toLowerCase().trim() === analogue.BiomarkerGenetyping.name.toLowerCase().trim()
        ) {
          this.emitRemovedAnalogues.emit(data);
          // this.selectedAnalogue.splice(index, 1);
        }
      });
      this.sortedAnalogues.forEach((data: any, index: number) => {
        if (
          data.name.toLowerCase().trim() ===
            analogue.Brand.toLowerCase().trim() &&
          data.HTAIndication.trim().toLowerCase() ===
            analogue.HTAIndication.name.trim().toLowerCase()
          // &&
          // data.biomarkerGenetyping.trim().toLowerCase() === analogue.BiomarkerGenetyping.name.trim().toLowerCase()
        ) {
          this.sortedAnalogues.splice(index, 1);
        }
      });
      // this.emitRemovedAnalogues.emit(removeAnalogue)
      this.updateMarketsBrandCount();
    }
    // this.emitAnalogueList.emit(this.selectedAnalogue);
  }

  updateMarketsBrandCount() {
    const enabledBrands = JSON.parse(
      JSON.stringify(
        this.defaultdataSource.filter((res: any) => {
          return res.toggle.toggle === true;
        })
      )
    );
    let testBrands: any[] = [];
    enabledBrands.forEach((x: any) => {
      let index = testBrands.findIndex(
        (y) =>
          y.Brand.toLowerCase().trim() === x.Brand.toLowerCase().trim() &&
          y.HTAIndication.name.toLowerCase().trim() ===
            x.HTAIndication.name.toLowerCase().trim()
        // && y.BiomarkerGenetyping.name.toLowerCase().trim() === x.BiomarkerGenetyping.name.toLowerCase().trim()
      );
      if (index === -1) {
        testBrands.push(x);
      } else {
        x.Country.forEach((z: any) => {
          let countryIndex = testBrands[index].Country.findIndex(
            (a: any) => a.name === z.name
          );
          if (countryIndex === -1) {
            testBrands[index].Country.push(z);
          }
        });
      }
    });
    this.marketBrandCount.forEach((countryData: any) => {
      countryData.brandCount = testBrands.filter(
        (brandData) =>
          brandData.Country.filter(
            (m: any) =>
              m.name.toLowerCase().trim() ===
              countryData.country.toLowerCase().trim()
          ).length > 0
      ).length;
    });
    this.criteriaSelectionService.brandCountData.next(this.marketBrandCount);
  }

  public setmarketForselectedAnalogues(analogue: any): any[] {
    let Markets: any[] = [];
    this.defaultdataSource
      .filter(
        (res) =>
          res.Brand.toLowerCase().trim() ===
            analogue.Brand.toLowerCase().trim() &&
          // res.Indication.name.toLowerCase().trim() ===
          //   analogue.Indication.name.toLowerCase().trim() &&
          res.HTAIndication.name.toLowerCase().trim() ===
            analogue.HTAIndication.name.toLowerCase().trim()
      )
      .forEach((x) => {
        Markets = [...Markets, ...x.Country];
      });
    return Markets;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public oreder1():void{
    this.dataSource = this.dataSource.sort((a:any,b:any)=>{
      if(a.lineOffTherapy.order !== b.lineOffTherapy.order){
        return  a.lineOffTherapy.order - b.lineOffTherapy.order
      }else if(a.Population.order !== b.Population.order){
        return a.Population.order - b.Population.order
      }else{
        return a.BiomarkerGenetyping.order - b.BiomarkerGenetyping.order
      }
    })
    this.tableheatmap.renderRows();

  }

  public oreder2():void{
    this.dataSource = this.dataSource.sort((a:any,b:any)=>{
      if(b.lineOffTherapy.order !== a.lineOffTherapy.order){
        return  b.lineOffTherapy.order - a.lineOffTherapy.order
      }else if(b.Population.order !== a.Population.order){
        return b.Population.order - a.Population.order
      }else{
        return b.BiomarkerGenetyping.order - a.BiomarkerGenetyping.order
      }
    })
    this.tableheatmap.renderRows();
  }

  filterbyHeat(filter:string):void{
    if(filter === "Most met"){
      this.mostmet = !this.mostmet;
      this.leastmet = false;
      if(this.mostmet){
        this.oreder1()
      }else{
        this.dataSource = this.defaultdataSource.sort((a:ComporatorTableInterface,b:ComporatorTableInterface)=> a?.Brand.localeCompare(b.Brand));
    this.tableheatmap.renderRows();
      }

    }else{
      this.leastmet = !this.leastmet;
      this.mostmet = false;
      if(this.leastmet){
        this.oreder2()
      }else{
        this.dataSource =  this.defaultdataSource.sort((a:ComporatorTableInterface,b:ComporatorTableInterface)=> a?.Brand.localeCompare(b.Brand));
    this.tableheatmap.renderRows();
      }
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
