import { UserPreferences } from '../../../../core/user-preferences/user-preferences';
import { AddUserPreference } from '../../../../core/add-user-preference/add-user-preference';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { setDateFormat } from '../../../../uikit/grid-common/grid-common.formatter';
import { ColDef, GridApi, ColumnApi, GridReadyEvent, ColumnMovedEvent, DragStoppedEvent, ValueFormatterParams, ColumnState, SortChangedEvent } from 'ag-grid-community';
import { FiltersValueService } from '../../filters-value.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { colorRender } from '../../../../uikit/grid-common/color-code/color-code';
import { LegendService } from 'projects/helios-gui/src/uikit/grid-common/legend/legend.service';
import { SharedService } from '../../../../uikit/service/shared.service';
import { GridViewTypeEnum, RequestTypeEnum } from '../../../../uikit/filters';
import { titleCaseFormatter } from '../../../common/string-utils';
import { dateComparator } from '../../../common';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { CountryAmnogService } from 'projects/helios-gui/src/uikit/country-amnog/country-amnog-service';
import { emptyFormatter } from 'projects/helios-gui/src/uikit/grid-common/empty.formatter';
import { PageEvent } from '@angular/material/paginator';
import { CustomTooltipRendererComponent } from './custom-tooltip-renderer/custom-tooltip-renderer.component';

@Component({
  selector: 'he-pack-details-grid',
  templateUrl: './pack-details-grid.component.html',
  styleUrls: ['./pack-details-grid.component.scss']
})
export class PackDetailsGridComponent implements OnChanges, OnInit, OnDestroy {
  @Input() dataset: any;
  @Input() showHistorical = false;
  @Output() gridReady = new EventEmitter();
  @Output() paginatorPageEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() sortChanged = new EventEmitter<SortChangedEvent>();
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  private readonly destroying$: Subject<void> = new Subject<void>();

  sizemode: 'autofit' | 'autosize' | 'none' = 'autosize';
  pageSize = 50;
  rowData: any[] = [];
  columnDefs: ColDef[] | any[] = [];
  columnDefstemp: ColDef[] | any[] = [];
  gridOptions: any = {
    headerHeight: 89,
    rowClassRules: {
      'he-row-samepack': 'data.samePack === true',
      'he-row-lastsamepack': 'data.lastSamePack === true',
      'he-row-pack': '!data.samePack',
    }
  };

  localCurrency: boolean | undefined;
  gridColumnApi: any;
  preferencesRetrieved = false;
  featureFlagGridModeExpansion: boolean = false;
  callTransformData: boolean = false;
  frameworkComponents={
    CustomTooltipRendererComponent:CustomTooltipRendererComponent
  }

  constructor(
    public filtersValueService: FiltersValueService,
    private legendService: LegendService,
    public sharedService: SharedService,
    private addUserPreference: AddUserPreference,
    private userPreferences: UserPreferences,
    private featureFlagService: FeatureFlagService,
    private countryAmnogService: CountryAmnogService, private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.applyInitStyles();
    this.subscribeUserPreferences();
    this.subscribeLocalCurrencySelected();
    if (this.sharedService.datastoreTabSelectedIndex$.value === Number(RequestTypeEnum.PnR)) {
      this.subscribeCalculatedPriceToggled();
      this.subscribeEstimatedPriceToggled();
    }

    window.addEventListener('beforeunload', this.onBeforeUnload);
    this.checkGridModeExpansionFlag();
  }
  onBeforeUnload = () => {
    localStorage.clear();
  }
  //clear local storage while user logs off or the browser is closed
  ngOnChanges(): void {
    this.transformData();
  }

  ngOnDestroy(): void {
    this.toggleWestPanelStyles(false);
    this.destroying$.next(undefined);
    this.destroying$.complete();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  onGridReadyEvent(param: GridReadyEvent): void {
    this.gridApi = param.api;
    this.columnApi = param.columnApi;
    this.checkColumnState();
  }

  onPaginatorPageEvent(params: any) {
    this.paginatorPageEvent.emit(params);
  }

  onSortChanged(params: any) {
    this.sortChanged.emit(params);
  }

  customTextComparator = (valueA: string, valueB: string) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };
  checkColumnState() {
    const key = this.sharedService.getSelectedGridModeTabKey();
    const columnOrderKey = `${key}_columnorder`;

    let columnState: string | null = "";
    let columnOrder: string | null = "";
    //check column definition
    columnState = localStorage.getItem(key);
    columnOrder = localStorage.getItem(columnOrderKey);
    //set column position
    if (columnState || columnState != undefined) {
      var columnStateList = JSON.parse(columnState);

      // Set Price Start and End date as per historical value
      if (key === "packprice") {
        var colIndex = 0;
        colIndex = columnStateList.findIndex((c: any) => c.colId?.toString() === "priceStartDate");
        if (colIndex >= 0) {
          columnStateList[colIndex].hide = !this.showHistorical;
        }

        colIndex = columnStateList.findIndex((c: any) => c.colId?.toString() === "priceEndDate");
        if (colIndex >= 0) {
          columnStateList[colIndex].hide = !this.showHistorical;
        }
      }

      this.columnApi.setColumnState(columnStateList);
    }
    else if (columnOrder || columnOrder != undefined) {
      this.columnApi.moveColumns(JSON.parse(columnOrder).map((x: any) => x.name.split('|')[0]), 0);
            //might needed it
      // let test = (JSON.parse(columnOrder))
      // test.forEach((element:any) => {
      //    this.columnApi.moveColumn(element.name.split('|')[0],element.name.split('|')[1])
      // });
    }
  }

  public transformData() {
    this.callGridViewColumnDetails(this.sharedService.getSelectedGridModeTabKey());
  }

  //store column reposition
  onColumnMoved(params: ColumnMovedEvent) {
    this.onGridColMove(params);
  }

  onDragStopped(event: DragStoppedEvent) {
    if (!this.featureFlagGridModeExpansion) {
      return;
    }

    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    const columnstates = this.columnApi.getColumnState();
    const key = this.sharedService.getSelectedGridModeTabKey();
    const columnOrderKey = `${key}_columnorder`;
    const columnOrder = columnstates.map((c: any, idx: any) => { return { type: columnOrderKey, name: `${c.colId}|${idx}` } });

    const columnsToHide: ColumnState[] = columnstates.filter((c: any) => c.hide)
    if (columnsToHide.length > 0) {
      columnsToHide.forEach(col => {
        this.sharedService.datastoreHideColumn$.next(col)
      })
      this.sharedService.datastoreHideColumn$.next(undefined)
    }

    columnstates.forEach((c: any) => c.hide = false);

    localStorage.setItem(key, JSON.stringify(columnstates));
    localStorage.setItem(columnOrderKey, JSON.stringify(columnOrder));
    this.addUserPreference.run({
      payload: columnOrder
    }).pipe(takeUntil(this.destroying$)).subscribe()
  }

  onGridColMove(params: ColumnMovedEvent) {
  }

  datastoreSelectedColumn: any[] = [];
  commonOption(): void {
    const commonOptions = {
      headerClass: 'tableHeader',
      suppressSizeToFit: false,
      wrapText: true,
      autoHeight: false,
      // sortable: true,
    };

    this.columnDefs = this.columnDefs.map((x) => Object.assign(x, commonOptions));

    //set only customized columns
    this.columnDefstemp = [];
    if (!this.sharedService.datastoreExportAll$.value) {
      if (this.sharedService.datastoreTabSelectedIndex$.value == 0) {
        this.datastoreSelectedColumn = this.sharedService.datastoreSelectedColumnPnR$.value;
      }
      else if (this.sharedService.datastoreTabSelectedIndex$.value == 1) {
        this.datastoreSelectedColumn = this.sharedService.datastoreSelectedColumnCOT$.value;
      }
      else if (this.sharedService.datastoreTabSelectedIndex$.value == 2) {
        this.datastoreSelectedColumn = this.sharedService.datastoreSelectedColumnHTA$.value
      }
    }
    else {
      if (this.sharedService.datastoreExcelSheet$.value == 0) {
        this.datastoreSelectedColumn = this.sharedService.datastoreSelectedColumnPnR$.value;
      }
      else if (this.sharedService.datastoreExcelSheet$.value == 1) {
        this.datastoreSelectedColumn = this.sharedService.datastoreSelectedColumnCOT$.value;
      }
      else if (this.sharedService.datastoreExcelSheet$.value == 2) {
        this.datastoreSelectedColumn = this.sharedService.datastoreSelectedColumnHTA$.value
      }
    }
    this.columnDefs.forEach(columns => {
      // Assign the colId == field as when we store the column state, it doen't have the field property so switching between default and historical was impacting
      columns.colId = columns.field;
      const checkcolIndex = this.datastoreSelectedColumn.findIndex(
        c => c.toString().toLowerCase() === columns.field?.toString().toLowerCase()
      );
      if (checkcolIndex != -1) {
        const column = this.columnDefs.findIndex(c => c.field?.toString() === columns.field?.toString());
        this.columnDefstemp.push(this.columnDefs[column]);
      }
    });

    this.columnDefs = this.columnDefstemp
    this.rowData = [];
  }

  callGridViewColumnDetails(tabname: string) {
    this.rowData = [];

    if (tabname == GridViewTypeEnum.packprice)
      this.setColumnDefs();
    else if (tabname == GridViewTypeEnum.hta)
      this.setColumnDefsHTA();
    else if (tabname == GridViewTypeEnum.indicationcot)
      this.setColumnDefsCot();
  }


  applyInitStyles(): void {
    const matCard = document.querySelector('mat-card') as HTMLElement;
    matCard.style.paddingTop = '0';
    const viewPort = document.querySelector('.middle-slot') as HTMLElement;
    viewPort.classList.remove('p-2');
    viewPort.classList.add('pl-2');
    viewPort.classList.add('pr-2');
    viewPort.classList.add('pb-2');

    this.toggleWestPanelStyles(true);
  }

  toggleWestPanelStyles(toggle: boolean) {
    const ruleName = 'd-none'
    const westPanel = document.querySelector('.he-west-panel') as HTMLElement;
    if (toggle) {
      westPanel.classList.add(ruleName);
    }
    else {
      westPanel.classList.remove(ruleName);
    }
  }

  subscribeLocalCurrencySelected(): void {
    this.filtersValueService.localCurrencySelected$.pipe(
      takeUntil(this.destroying$)
    ).subscribe((value) => {
      this.localCurrency = value;
    });
  }

  subscribeCalculatedPriceToggled(): void {
    this.legendService.calculatedPriceToggleValue$.pipe(
      takeUntil(this.destroying$),
      takeUntil(this.destroying$),
      skip(1),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.transformData();
    });
  }

  subscribeEstimatedPriceToggled(): void {
    this.legendService.estimatedPriceToggleValue$.pipe(
      takeUntil(this.destroying$),
      skip(1),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.transformData();
    });
  }

  subscribeUserPreferences(): void {
    let selectedTab = this.sharedService.getSelectedGridModeTabKey()
    const columnOrderKey = selectedTab + '_columnorder';
    this.userPreferences.run({
      payload: [
        columnOrderKey
      ]
    }).pipe(
      takeUntil(this.destroying$)
    ).subscribe((response) => {
      if (response.type != "pending") {
        if (!response.error && response.payload) {
          const columnOrder = (response.payload as any[]).map((c: any, idx: any) => {
            if (c.userPreference.type != columnOrderKey) {
              return false;
            }
            return {
              type: columnOrderKey, name: `${c.userPreference.preference}|${idx}`
            }
          });
          localStorage.setItem(columnOrderKey, JSON.stringify(columnOrder));
        }
        this.preferencesRetrieved = true
      }
    });
  }


  saveGridApi(params: GridApi): void {
    this.gridReady.emit(params);

  }

  generateHeaderTextTemplate(headerText: string): string {
    return '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '      <span ref="" class="ag-header-cell-text" role="columnheader">' + headerText + '</span>' +
      '      <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      '      <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
      '      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
      '      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
      '      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
      '  </div>' +
      '</div>';
  }

  generateEmptyTextTemplate(): string {
    return '<div class="d-none"></div>';
  }

  setColumnDefs(): void {
    this.columnDefs = [
      {
        headerName: 'Country',
        field: 'country',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        comparator: this.customTextComparator,
        cellRenderer: this.countryAmnogService.cellRenderer,
        lockVisible: true, sortable: true
      },
      {
        headerName: 'Brand name',
        field: 'brand',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        cellRenderer: 'CustomTooltipRendererComponent',

        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Availability Status',
        field: 'availabilityStatus',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },

      },
      {
        headerName: 'INN',
        field: 'brandInns',
        pinned: 'left',
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Pack description',
        field: 'packDescription',
        pinned: 'left',
        width: 300,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Price Start Date', field: 'priceStartDate', valueFormatter: setDateFormat, comparator: dateComparator,
        hide: !this.showHistorical,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Price End Date', field: 'priceEndDate', valueFormatter: setDateFormat, comparator: dateComparator,
        hide: !this.showHistorical,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Company', field: 'company',
        sortable: true
      },
      {
        headerName: 'Marketing Authority', field: 'marketingAuthority',
        sortable: true
      },
      {
        headerName: 'Launch Date', field: 'launchDate', valueFormatter: setDateFormat, comparator: dateComparator,
        sortable: true
      },
      {
        headerName: 'Brand Category', field: 'brandCategory',
        sortable: true
      },
      {
        headerName: 'Hospital Pack', field: 'isHospitalPack',
        sortable: true
      },
      {
        headerName: 'Combination Molecule', field: 'combinationMolecule',
        sortable: true
      },
      {
        headerName: 'Product lifecycle', field: 'isDiscontinued',
        sortable: true
      },
      {
        headerName: 'Formulation', field: 'formulation',
        sortable: true
      },
      {
        headerName: 'Strength', field: 'strength',
        sortable: true
      },
      {
        headerName: 'Strength Unit', field: 'strengthUnit',
        sortable: true
      },
      {
        headerName: 'Fill Size', field: 'fillSize',
        sortable: true
      },
      {
        headerName: 'Fill Unit', field: 'fillUnit', 
        sortable: true
      },
      {
        headerName: 'Pack Size', field: 'packSize',
        sortable: true
      },
      {
        headerName: 'Pack Unit', field: 'packUnit',
        sortable: true
      },
      {
        headerName: 'Currency', field: 'currency',
        sortable: true
      },
      { headerName: 'Ex Factory', field: 'exFactoryPrice', valueFormatter: this.decimalFormatter, cellStyle: (params: any) => colorRender(params), sortable: true },
      {
        headerName: 'Ex Factory Source Type', field: 'exfactoryColorCode', cellClass: 'd-none', maxWidth: 0, resizable: false,
        headerComponentParams: {
          template: this.generateEmptyTextTemplate()
        }
      },
      { headerName: 'Hospital', field: 'hospitalPrice', valueFormatter: this.decimalFormatter, cellStyle: (params: any) => colorRender(params), sortable: true },
      {
        headerName: 'Hospital Source Type', field: 'hospitalColorCode', cellClass: 'd-none', maxWidth: 0, resizable: false,
        headerComponentParams: {
          template: this.generateEmptyTextTemplate()
        }
      },
      {
        headerName: 'PPP', field: 'ppp', valueFormatter: this.decimalFormatter, cellStyle: (params: any) => colorRender(params),
        headerComponentParams: {
          template: this.generateHeaderTextTemplate('<abbr title="Pharmacy Purchase Price">PPP</abbr>')
        }, sortable: true
      },
      {
        headerName: 'PPP Source Type', field: 'pppColorCode', cellClass: 'd-none', maxWidth: 0, resizable: false,
        headerComponentParams: {
          template: this.generateEmptyTextTemplate()
        },
        sortable: true
      },
      {
        headerName: 'PP Ex Vat', field: 'ppExVat', valueFormatter: this.decimalFormatter, cellStyle: (params: any) => colorRender(params),
        headerComponentParams: {
          template: this.generateHeaderTextTemplate('<abbr title="Public Price">PP</abbr> Ex <abbr title="Value Added Tax">Vat</abbr>')
        }, sortable: true
      },
      {
        headerName: 'PP Ex Vat Source Type', field: 'ppExVatColorCode', cellClass: 'd-none', maxWidth: 0, resizable: false,
        headerComponentParams: {
          template: this.generateEmptyTextTemplate()
        },
        sortable: true
      },
      {
        headerName: 'PP Inc Vat', field: 'ppIncVat', valueFormatter: this.decimalFormatter, cellStyle: (params: any) => colorRender(params),
        headerComponentParams: {
          template: this.generateHeaderTextTemplate('<abbr title="Public Price">PP</abbr> Inc <abbr title="Value Added Tax">Vat</abbr>')
        }, sortable: true
      },
      {
        headerName: 'PP Inc Vat Source Type', field: 'ppIncVatColorCode', cellClass: 'd-none', maxWidth: 0, resizable: false,
        headerComponentParams: {
          template: this.generateEmptyTextTemplate()
        },
        sortable: true
      },
      { headerName: 'Reimbursement Price', field: 'reimbursementPrice', valueFormatter: this.decimalFormatter, sortable: true },
      {
        headerName: 'Reimbursement Classification (Formulary Status)', field: 'reimbursedClassification', cellStyle: (params: any) => colorRender(params),
        sortable: true
      },
      { headerName: 'Reimbursement Reference Price', field: 'reimbursementReferencePrice', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Source', field: 'packSource', sortable: true },
      { headerName: 'Source Update Date', field: 'sourceUpdateDate', valueFormatter: setDateFormat, comparator: dateComparator, sortable: true },

      { headerName: 'Country ISO Code', field: 'countryISOCode', sortable: true },
      { headerName: 'Pack Note', field: 'packNote', sortable: true },
      { headerName: 'Region', field: 'region', sortable: true },
      { headerName: 'MOA', field: 'moa', sortable: true },
      { headerName: 'Reimbursement Approval Date', field: 'reimbursementApprovalDate', valueFormatter: setDateFormat, comparator: dateComparator, sortable: true },
      { headerName: 'Level Of Reimbursement', field: 'levelOfReimbursement', sortable: true },
      { headerName: 'Copayment Ratio', field: 'copaymentRatio', sortable: true },
      { headerName: 'Reimbursable Price', field: 'reimbursablePrice', valueFormatter: this.decimalFormatter,  sortable: true },
      { headerName: 'Copayment Price', field: 'copaymentPrice',  valueFormatter: this.decimalFormatter, sortable: true },
      {
        headerName: 'Reimbursed Annotations', field: 'reimbursedAnnotations', width: 200,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      { headerName: 'Launch Price', field: 'launchPrice', valueFormatter: this.decimalFormatter, sortable: true },
      {
        headerName: 'Therapy Class', field: 'therapyClass',
        valueFormatter: titleCaseFormatter, sortable: true
      },
      { headerName: 'WHOATC', field: 'whoatc', sortable: true },
      { headerName: 'Orphan Status', field: 'orphanStatus', sortable: true },
      { headerName: 'Reimbursement Reference Price including VAT', field: 'reimbursementReferencePriceIncludingVat', valueFormatter: this.decimalFormatter,  sortable: true },
      { headerName: 'Reimbursement Reference Group', field: 'reimbursementReferenceGroup', sortable: true },
      { headerName: 'Pack ID', field: 'packId', sortable: true },
      { headerName: 'Visible Net Ex Factory', field: 'exfactoryLessRebate', valueFormatter: this.decimalFormatter,  sortable: true },
      { headerName: 'Visible Net Hospital Price', field: 'hospitalPriceLessRebate',  valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Visible Net PPP', field: 'ppLessRebate', valueFormatter: this.decimalFormatter,  sortable: true },
      { headerName: 'Visible Net PP Ex Vat', field: 'ppExVatLessRebate',  valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Visible Net PP Inc Vat', field: 'ppIncVatLessRebate',  valueFormatter: this.decimalFormatter, sortable: true }
    ];
    this.commonOption();
    this.rowData = this.sharedService.pnrDataload(this.dataset, false);
  }
  setColumnDefsCot(): void {
    this.columnDefs = [
      {
        headerName: 'Country',
        field: 'country',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        comparator: this.customTextComparator,
        lockVisible: true, sortable: true
      },
      {
        headerName: 'Brand name',
        field: 'brand',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        cellRenderer: 'CustomTooltipRendererComponent',
        lockVisible: true, sortable: true
      },
      {
        headerName: 'Availability Status',
        field: 'availabilityStatus',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },

      },
      {
        headerName: 'INN',
        field: 'brandInns',
        pinned: 'left',
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        }, sortable: true
      },
      {
        headerName: 'Pack description',
        field: 'packDescription',
        pinned: 'left',
        width: 500,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9', },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        cellRenderer: this.packDescriptionCellRenderer,
        lockVisible: true, sortable: true
      },
      { headerName: 'Company', field: 'company', sortable: true },
      { headerName: 'Top Level Indication', field: 'topLevelIndication', lockVisible: true, sortable: true },
      {
        headerName: 'Regulatory Indication',
        field: 'regulatoryIndication',
        width: 300,
        suppressSizeToFit: true,
        lockVisible: true,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      { headerName: 'Indication Approval Date', field: 'indicationApprovalDate', suppressSizeToFit: true, lockVisible: true, valueFormatter: setDateFormat, comparator: dateComparator, sortable: true },
      { headerName: 'Currency', field: 'currency', sortable: true },
      { headerName: 'Cost per Pack', field: 'perPack', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost per Unit', field: 'perUnit', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost per MG', field: 'perMg', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost per ML', field: 'perMl', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of treatment per Day', field: 'perDay', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of treatment per Month', field: 'perMonth', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of treatment per Annum', field: 'perAnnum', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of Treatment Per Duration of Treatment', field: 'perDurationOfTreatment', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of Treatment Per Day Including Wastage', field: 'perDayIncludingWastage', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of Treatment Per Month Including Wastage', field: 'perMonthIncludingWastage', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of Treatment Per Annum Including Wastage', field: 'perAnnumIncludingWastage', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of Treatment Per Duration of Treatment Incl wastage', field: 'perDurationOfTreatmentIncludingWastage', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Cost of Treatment Per Cycle', field: 'perCycle', valueFormatter: this.decimalFormatter, sortable: true },

      { headerName: 'Loading Dosing', field: 'productDosingLoading', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Maintenance Dosing', field: 'productDosingMaintenance', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Loading Dosing Frequency', field: 'frequencyLoading', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Loading Dosing Frequency Unit', field: 'frequencyLoadingUnit', sortable: true },
      { headerName: 'Maintenance Dosing Frequency', field: 'frequencyMaintenance', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Maintenance Dosing Frequency Unit', field: 'frequencyMaintenanceUnit', sortable: true },
      { headerName: 'Loading Dosing Duration', field: 'durationLoading', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Loading Dosing Duration Unit', field: 'durationLoadingUnit', sortable: true },
      { headerName: 'Maintenance Dosing Duration', field: 'durationMaintenance', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Maintenance Dosing Duration Unit', field: 'durationMaintenanceUnit', sortable: true },
      { headerName: 'Body Surface Area', field: 'bodySurfaceArea', valueFormatter: this.decimalFormatter, sortable: true },
      { headerName: 'Average Weight', field: 'averageWeight', valueFormatter: this.decimalFormatter, sortable: true }
    ];
    this.commonOption();
    this.rowData = this.sharedService.cotdataload(this.dataset, false)
  }

  private decimalFormatter(params: ValueFormatterParams) {
    try {
      if (
        params.value === undefined ||
        params.value === null ||
        params.value === '' ||
        params.value === 0 ||
        parseFloat(params.value) === 0.00
      ) {
        return '-';
      } else if (params.value === ' ') {
        return emptyFormatter(params);
      } else {
        return new Number(params.value).toFixed(2).replace('.00', '');
      }
    } catch (error) {
      params.value 
    }
  }
  


  setColumnDefsHTA(): void {
    this.columnDefs = [
      {
        headerName: 'Country',
        field: 'country',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        comparator: this.customTextComparator,
        lockVisible: true, sortable: true

      },
      {
        headerName: 'Brand name',
        field: 'brand',
        pinned: 'left',
        width: 120,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        },
        lockVisible: true, sortable: true
      },
      {
        headerName: 'INN',
        field: 'brandInns',
        pinned: 'left',
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        }, sortable: true
      },
      {
        headerName: 'Pack description',
        field: 'packDescription',
        pinned: 'left',
        width: 300,
        suppressSizeToFit: true,
        cellStyle: { color: '#92A1B9' },
        cellClass: (params: any) => {
          return params.data.samePack === true ? 'd-none' : '';
        }, sortable: true
      },
      { headerName: 'Company', field: 'company', sortable: true },
      {
        headerName: 'Top Level Indication', field: 'topLevelIndication', width: 200, lockVisible: true, sortable: true
      },
      { headerName: 'Indication Approval Date', field: 'indicationApprovalDate', valueFormatter: setDateFormat, comparator: dateComparator, sortable: true },
      {
        headerName: 'Line of Therapy', field: 'lineOfTherapy', width: 200,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      {
        headerName: 'Indication HTA', field: 'indicationHta', width: 200,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      { headerName: 'National HTA Body', field: 'nationalHtaBody', sortable: true },
      {
        headerName: 'Decision', field: 'decision',
        valueFormatter: titleCaseFormatter, sortable: true
      },
      { headerName: 'Assessment Publication Date', field: 'assessmentPublicationDate', valueFormatter: setDateFormat, comparator: dateComparator, sortable: true },
      {
        headerName: 'Clinical Reason', field: 'clinicalReason', cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      {
        headerName: 'Economical Reason', field: 'economicalReason', cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      {
        headerName: 'Standardised Decision', field: 'standardisedDecision', cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      {
        headerName: 'Utilisation Restrictions', field: 'utilisationRestrictions', width: 200,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      {
        headerName: 'Comparator Drug HTA', field: 'comparatorDrugHta', cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },

      {
        headerName: 'Reimbursement Classification (Formulary Status)', field: 'reimbursedClassification', sortable: true
      },
      {
        headerName: 'Regulatory Indication',
        field: 'regulatoryIndication',
        width: 300,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      },
      { headerName: 'Estimated Patient Number', field: 'estimatedPatientNumber', sortable: true },
      { headerName: 'Link To The Original Document', field: 'linkToTheOriginalDocument', cellStyle: { alignItems: 'start' }, sortable: true },
      {
        headerName: 'Commercial Agreement', field: 'commercialAgreement', width: 200,
        cellStyle: { alignItems: 'start' },
        valueFormatter: titleCaseFormatter, sortable: true
      }
    ];

    this.commonOption();
    this.rowData = this.sharedService.Htadataload(this.dataset, false);
    //  this.rowData = this.distinctByColumns(this.rowData);
  }

  // distinctByColumns<T>(data: any[]): any {

  //   const datawithselectedColumns: any[] = data.map((person) => {
  //     const selectedProps: any = {};
  //     this.sharedService.datastoreSelectedColumn$.value.forEach((propName) => {
  //       selectedProps[propName.charAt(0).toLowerCase() + propName.slice(1)] = person[propName.charAt(0).toLowerCase() + propName.slice(1)];
  //     });
  //     return selectedProps;
  //   });

  //   //
  //   const col = this.sharedService.datastoreSelectedColumn$.value;
  //   const map = new Map<string, T>();
  //   datawithselectedColumns.forEach(d => {
  //     const key = col.map(c => ((d as any)[c.charAt(0).toLowerCase() + c.slice(1)] ?? '').trim()).join('-');
  //     if (!map.has(key)) {
  //       map.set(key, d);
  //     }
  //   });
  //   return Array.from(map.values());
  // }

  checkGridModeExpansionFlag() {
    this.featureFlagService.getGridModeExpansionFlag(this.destroying$).then((flagValue: boolean) => {
      this.featureFlagGridModeExpansion = flagValue;
    });
  }

  packDescriptionCellRenderer(params: any) {
    if (params.data.isCotPreview)
    {
      return '<abbr class="cot-prv-pack" title="Created with cost of treatment calculator">' + params.value + '</abbr>';
    }
    else if (params.data.isCotCorridor)
    {
      return '<abbr class="cot-prv-pack" title="Pack used for Cost of Treatment visualization">' + params.value + '</abbr>';
    }
    return params.value;
  }
}
