<div [ngSwitch]="inputdata.inputType" id="{{inputdata.title}}">
  <div *ngSwitchCase="'0'" class="custom-input custom-autocomplete">
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-icon matPrefix>search</mat-icon>
      <input type="text"
      placeholder="Search relevant key words"
      matInput
      [value]="inputdata.selectedValue"
      [formControl]="fruitCtrl"
      [matAutocomplete]="auto0"
      #fruitInput
      />
      <mat-autocomplete #auto0="matAutocomplete">
        <mat-option *ngFor="let option of filteredList | async" [value]="option" (click)="selectToggle(option)">
          {{option}}
        </mat-option>
      </mat-autocomplete>
        <!-- <mat-select placeholder="Please select" (selectionChange)="selectToggle($event.value)" [value]="inputdata.selectedValue">
          <mat-option *ngFor="let topping of inputdata.valueList" id="{{topping}}" [value]="topping">{{topping}}</mat-option>
        </mat-select> -->
      </mat-form-field>
</div>
    <div *ngSwitchCase="'1'"  class="custom-toogle custom-toogl-width">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="inputdata.selectedValue">
            <mat-button-toggle *ngFor="let value of inputdata.valueList" id="{{value}}" [value]="value" (click)="selectToggle(value)">{{value}}</mat-button-toggle>
          </mat-button-toggle-group>
    </div>
    <div *ngSwitchCase="'2'" class="custom-input">
        <mat-form-field appearance="outline" style="width:100%;">
            <mat-select placeholder="Please select" (selectionChange)="selectToggle($event.value)" [value]="inputdata.selectedValue">
              <mat-option *ngFor="let topping of inputdata.valueList" id="{{topping}}" [value]="topping">{{topping}}</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div *ngSwitchCase="'3'" class="custom-input custom-autocomplete">
      <mat-form-field appearance="outline" style="width:100%;">
        <mat-chip-grid #chipGrid class="">
          <mat-chip-row *ngFor="let fruit of inputdata.selectedValueList" (removed)="remove(fruit)">
            {{fruit}}
            <button matChipRemove [attr.aria-label]="'remove ' + fruit">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="Search relevant key words (Like 'ase' or 'enzyme')" #fruitInput [formControl]="fruitCtrl"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-grid>
        <mat-icon matPrefix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let fruit of filteredList  | async" [value]="fruit">
            {{fruit}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'4'" class="custom-input">
      <div class="multi-inputs" >
        <ng-container *ngFor="let multiInput of inputdata.multiInputList;index as i">
          <mat-form-field appearance="outline" style="width:42%;" >
            <mat-select placeholder="Please select Therapy Area" (selectionChange)="selectMultiValue(i,$event.value)" [value]="multiInput.selectedTherapyValue">
              <mat-option *ngFor="let topping of multiInput.therapyValueList" [value]="topping.name">{{topping.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width:42%;" >
            <mat-select placeholder="Please select Indications" (selectionChange)="selectIndicationMultiValue(i,$event.value)" [value]="multiInput.selectedIndicationValue" multiple>
              <mat-option class="hide-all" #allSelected (click)="toggleAllSelection(i)" [value]="0" *ngIf="multiInput.selectedTherapyValue != ''">Select all</mat-option>
              <mat-option *ngFor="let topping of multiInput.indicationValueList" [value]="topping.name">{{topping.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button [disabled]="inputdata.multiInputList?.length === 1" (click)="removeInput(i)" class="add-action-btn">
            <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
          </button>
        </ng-container>

        <button mat-stroked-button *ngIf="inputdata.multiInputList?.length !== 8" (click)="addNew()" class="add-action-btn">
          <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
        </button>
      </div>

  </div>
  <div *ngSwitchCase="'5'" class="custom-input">
    <div class="d-flex d-align-c d-j-c launch-date margin-padding custom-toogl-cellAdjust">
      
      
      <div
        class="d-flex d-align-c d-j-start input-range custom-toogl-cellAdjust-new"
        [formGroup]="valueRangSelectedForm"
      >
        <p style="margin: 0;">From</p>
        <mat-form-field
          appearance="fill"
          class=" range-input"
          id="minSelectedRange-field"
        >
          <input
            matInput
            type="number"
            [max]="maxSelectedRange !== null ? maxSelectedRange : maxRange"
            [min]="minRange"
            (input)="valueChanged()"
            [(ngModel)]="minSelectedRange"
            required
            formControlName="minSelectedRange" 
          />
          <mat-error
            style="height: 0; margin-top: -22px"
            *ngIf="minSelectedRange === null"
            >Required</mat-error
          >
        </mat-form-field>
        <p  style="margin: 0;">To</p>
        <mat-form-field
          appearance="fill"
          class="range-input"
          id="maxSelectedRange-field"
        >
          <input
            matInput
            type="number"
            [max]="maxRange"
            [min]="minSelectedRange !== null ? minSelectedRange : minRange"
            (input)="valueChanged()"
            [(ngModel)]="maxSelectedRange"
            required
            formControlName="maxSelectedRange"
          />
          <mat-error
            style="height: 0; margin-top: -22px"
            *ngIf="maxSelectedRange === null"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'6'"  class="custom-toogle custom-toogl-width">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple="boolean" [value]="inputdata.selectedValueList">
      <mat-button-toggle *ngFor="let value of inputdata.valueList" id="{{value}}" [value]="value" (change)="selectMultipleToggle($event)">{{value}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngSwitchCase="'7'" class="custom-input custom-toogl-width-changes">
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-select placeholder="Please select" (selectionChange)="selectionOfMultiValue($event.value)" [value]="inputdata.selectedValueList" multiple>
        <mat-option class="hide-all" #allSelected (click)="toggleValueAllSelection()" [value]="'0'">Select all</mat-option>
        <mat-option *ngFor="let topping of inputdata.valueList" id="{{topping}}" [value]="topping">{{topping}}</mat-option>
      </mat-select>
    </mat-form-field>
    
  </div>
  <div *ngSwitchCase="'8'" class="custom-input custom-autocomplete custom-toogl-width-changes">
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-chip-grid #chipGrid class="">
        <mat-chip-row *ngFor="let fruit of inputdata.selectedValueList | slice:0:6" (removed)="removeMulti(fruit)">
          {{fruit}}
          <button matChipRemove [attr.aria-label]="'remove ' + fruit">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <mat-chip *ngIf="inputdata.selectedValueList.length > 6">
          +{{inputdata.selectedValueList.length - 6}} more
        </mat-chip>
        <input placeholder="Search relevant key words" #fruitInput [formControl]="multifruitCtrl"
        [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        />
      </mat-chip-grid>
      <mat-icon matPrefix>search</mat-icon>
      <mat-autocomplete #auto="matAutocomplete" [hideSingleSelectionIndicator]="true">
        <mat-option class="hide-all" #allSelected (click)="toggleValuesWithSearchAllSelectionMulti()">Select all</mat-option>
        <mat-option *ngFor="let fruit of multifilteredList  | async" (click)="$event.stopPropagation()">
          <mat-checkbox class="example-margin" [color]="'primary'" [checked]="fruit.selected" (change)="multiselected($event,fruit.name)" (click)="$event.stopPropagation()">{{fruit.name}}</mat-checkbox>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>