<form [formGroup]="scenarioForm" *ngIf="!loading">
  <div class="card-he">
    <mat-card class="example-card">
      <mat-card-header>
        <div class="heading">
          <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px;"/> -->
          <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px;"/> -->
          <span class="heading-title">Scenario Details</span>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div>
          <div
            class="input-div error-padding"
            style="width: 20%; margin-bottom: 10px"
            id="-1"
          >
            <p>Scenario name <span class="mandatory-field">*</span></p>
            <mat-form-field
              appearance="fill"
              class="form-details"
              id="scenario-name-field"
            >
              <input
                matInput
                formControlName="scenarioName"
                heAutoTrimInput
                (keyup)="sendTheNewValue($event)"
              />
              <mat-error>{{ scenarioNameError }}</mat-error>
              <!-- <mat-error *ngIf="scenarioDuplicate">Scenario name already exists</mat-error> -->
            </mat-form-field>
          </div>
          <div class="input-div error-padding">
            <p>Scenario details</p>
            <mat-form-field
              appearance="fill"
              style="width: 100%"
              class="form-details text-area-field"
              id="scenario-details-field"
            >
              <textarea
                matInput
                rows="7"
                heAutoTrimInput
                formControlName="scenarioDetails"
                (input)="sendTheNewValue($event)"
              ></textarea>
              <mat-error>{{ scenarioDetailsError }}</mat-error>
            </mat-form-field>
          </div>
          <!-- <button (click)="scroll()"></button> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
<ng-container *ngIf="!loading">
  <div *ngFor="let valueDomains of ValueDomains" class="error-hidden">
    <div class="card-he">
      <mat-card class="example-card">
        <mat-card-header>
          <div class="heading">
            <span class="heading-title" >{{ valueDomains.name }}</span>
            <span
              class="heading-status"
              [ngClass]="{ complete: valueDomains.completed }"
              >COMPLETE</span
            >
          </div>
        </mat-card-header>
        <mat-card-content>
          <!-- common valuedomains -->
          <div *ngFor="let country of valueDomains.countries">
            <ng-container *ngIf="country.name === 'All'" >
              <div class="horizon-field-02">
                <ng-container
                  *ngFor="let attributes of country.valueAttributes"
                >
                  <div class="domains-Attributes" id="{{attributes.scrollId}}">
                    <div class="domain-name">
                      <p class="attributes-name" style="margin: 0" >
                        {{ attributes.name }} <span class="mandatory-field">*</span>
                      </p>
                      <div>
                        <mat-icon
                        *heFeatureFlag="{
                          Name: 'asset-tpp-info',
                          Page: 'Asset-Tpp'
                        }"
                        #tooltip="matTooltip"
                        matTooltip="{{ attributes.infoData }}"
                        matTooltipClass="horizon-tooltip"
                        >info</mat-icon
                      >
                      </div>

                    </div>
                    <div class="domain-value">
                      <div>
                        <mat-form-field
                          class="domain-scores"
                          floatLabel="always"
                          id="scenario-score"
                          [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                          [ngClass]="{ 'error-line': attributes.selectedScore ==='' && attributes.touched}"
                        >
                          <mat-select
                            #selectedScore
                            class="select-score"
                            required
                            (openedChange)="
                              checkFormDirty(
                                $event,
                                country.name,
                                attributes.name,
                                valueDomains.isAllMarketVisible
                              )
                            "
                            (selectionChange)="
                              updateValueAtrributeScore(
                                selectedScore.value.scoreId,
                                selectedScore.value.score,
                                'NA',
                                country.name,
                                attributes.name,
                                valueDomains.isAllMarketVisible,
                                attributes.formType

                              )
                            "
                            [value]="attributes.selectedScore"
                            [disabled]="!isEditAssetTppScores"
                            [hideSingleSelectionIndicator]="true"
                          >
                            <mat-option
                              [value]="score"
                              *ngFor="let score of attributes.score"
                              >{{ score.score }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field
                          class="domain-scores-discrpt"
                          floatLabel="always"
                          id="scenario-details"
                          [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                          [ngClass]="{ 'error-line': attributes.selectedScore ==='' && attributes.touched}"
                        >
                          <mat-select
                            #selectedScoreDesc
                            (openedChange)="
                              checkFormDirty(
                                $event,
                                country.name,
                                attributes.name,
                                valueDomains.isAllMarketVisible
                              )
                            "
                            (selectionChange)="
                              updateValueAtrributeScore(
                                selectedScoreDesc.value.scoreId,
                                selectedScoreDesc.value.score,
                                'NA',
                                country.name,
                                attributes.name,
                                valueDomains.isAllMarketVisible,
                                attributes.formType

                              )
                            "
                            [value]="attributes.selectedScore"
                            placeholder="Please select"
                            [disabled]="!isEditAssetTppScores"
                          >
                            <mat-option
                              [value]="score"
                              *ngFor="let score of attributes.score"
                              >{{ score.scoreDescription }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <mat-error
                        class="domainerror"
                        *ngIf="
                          attributes.selectedScore ==='' && attributes.touched
                        "
                        >{{ attributes.name }} is required</mat-error
                      >
                    </div>
                  </div>
                  <!-- <div>{{attributes.touched}}{{attributes.selectedScore}}</div> -->
                </ng-container>
              </div>
            </ng-container>
          </div>

          <!-- toggle for all markets and specific markets -->
           <ng-container *ngIf="Countries.length > 1">
            <ng-container *ngIf="valueDomains.name != 'Other'">

              <div
                class="custom-toogle-scenario"
                style="display: flex; justify-content: center; align-items: center"
                *heFeatureFlag="{
                  Name: 'scenario-details-input-market-toggle',
                  Page: 'Scenario-details'
                }"
              >
                <mat-button-toggle-group
                name="domainInputOptions"
                aria-label="domainInputOptions"
                value="{{valueDomains.isAllMarketVisible}}"
                (change)="
                  valueDomains.isAllMarketVisible =
                    !valueDomains.isAllMarketVisible
                "
              >
                <mat-button-toggle value="true"
                  >Input {{ valueDomains.name | lowercase }} by
                  market</mat-button-toggle
                >
                <mat-button-toggle value="false"
                  >Input {{ valueDomains.name | lowercase }} for all
                  markets</mat-button-toggle
                >
              </mat-button-toggle-group>
                <mat-icon class="set-mat-icon" *ngIf="valueDomains.name === 'Burden' && !projectService.isDropDown " [matMenuTriggerFor]="menu" style="right: 49px;">settings</mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [ngClass]="valueDomains.inputType === 'value' ? 'selected' : ''" (click)="valueDomains.inputType = 'value';updateScenarioAttribtues();">
                  <span>Input patient number as value </span>
                </button>
                <button mat-menu-item [ngClass]="valueDomains.inputType === 'range' ? 'selected' : ''" (click)="valueDomains.inputType = 'range';updateScenarioAttribtues();">
                  <span>Input patient number as range </span>
                </button>
              </mat-menu>
              <mat-icon
              class="info-mat-icon"
                #tooltip="matTooltip"
                matTooltip='Select "Input {{
                  valueDomains.name | lowercase
                }} by market" to apply different scores to each attribute in different markets. 
                Select "Input {{
                  valueDomains.name | lowercase
                }} for all markets" to add one score for each attribute across markets.'
                matTooltipClass="horizon-tooltip"
                >info</mat-icon
              >
              </div>
            </ng-container>
    
           </ng-container>
  
          <!-- for country specific attributes -->
          <div *ngIf="valueDomains.isAllMarketVisible">
            <div *ngFor="let country of valueDomains.countries">
              <ng-container *ngIf="country.name !== 'All'">
                <div style="display: flex">
                  <div  class="country-flag">
                    <img
                      class="flag"
                      src="{{
                        'assets/flags/small/' + getFlagId(country?.name) + '.svg'
                      }}"
                      width="20px"
                    />
                    {{ country.name }}
                  </div>
                </div>
                <div class="horizon-field-02">
                  <ng-container
                    *ngFor="let attributes of country.valueAttributes"
                  >
                    <div class="domains-Attributes" id="{{attributes.scrollId}}">
                      <div class="domain-name">
                        <p class="attributes-name" style="margin: 0" >
                          {{ attributes.displayname }} <span class="mandatory-field">*</span>
                        </p>
                        <div>
                          <mat-icon
                          *heFeatureFlag="{
                            Name: 'asset-tpp-info',
                            Page: 'Asset-Tpp'
                          }"
                          #tooltip="matTooltip"
                          matTooltip="{{ attributes.infoData }}"
                          matTooltipClass="horizon-tooltip"
                          >info</mat-icon
                        >
                        </div>

                      </div>
                      <div class="domain-value">
                        <div *ngIf="attributes.formType === 'dropdown'">
                          <div>
                            <mat-form-field
                            class="domain-scores"
                            floatLabel="always"
                            id="scenario-score"
                            [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                            [ngClass]="{ 'error-line': attributes.selectedScore ==='' && attributes.touched}"
                          >
                            <mat-select
                              #selectedScore
                              class="select-score"
                              required
                              (openedChange)="
                                checkFormDirty(
                                  $event,
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible
                                )
                              "
                              (selectionChange)="
                                updateValueAtrributeScore(
                                  selectedScore.value.scoreId,
                                  selectedScore.value.score,
                                  'NA',
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible,
                                  attributes.formType

                                )
                              "
                              [value]="attributes.selectedScore"
                              [disabled]="!isEditAssetTppScores"
                              [hideSingleSelectionIndicator]="true"
                            >
                              <mat-option
                                [value]="score"
                                *ngFor="let score of attributes.score"
                                >{{ score.score }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field
                            class="domain-scores-discrpt"
                            floatLabel="always"
                            id="scenario-details"
                            [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                            [ngClass]="{ 'error-line': attributes.selectedScore ==='' && attributes.touched}"
                          >
                            <mat-select
                              #selectedScoreDesc
                              (openedChange)="
                                checkFormDirty(
                                  $event,
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible
                                )
                              "
                              (selectionChange)="
                                updateValueAtrributeScore(
                                  selectedScoreDesc.value.scoreId,
                                  selectedScoreDesc.value.score,
                                  'NA',
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible,
                                  attributes.formType

                                )
                              "
                              [value]="attributes.selectedScore"
                              placeholder="Please select"
                              [disabled]="!isEditAssetTppScores"
                            >
                              <mat-option
                                [value]="score"
                                *ngFor="let score of attributes.score"
                                >{{ score.scoreDescription }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                          </div>
  
                          <mat-error
                          class="domainerror"
                          *ngIf="
                            attributes.selectedScore ===''  && attributes.touched
                          "
                          >{{ attributes.name }} is required</mat-error
                        >
                        </div>
                        <div *ngIf="attributes.formType === 'value'">

                          <div *ngIf="valueDomains.inputType  === 'value'">
                            <div>
                              <mat-form-field
                              class="domain-scores-range1"
                              floatLabel="always"
                              id="scenario-details"
                              [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                              [ngClass]="{ 'error-line': !attributes.isValid && attributes.touched}"
                            >
                            <input matInput placeholder="{{attributes.placeholder}}" 
                             type="number" 
                             heOnlyNumber
                             heBlockDecimal
                             step="1"
                                        (input)="checkFormDirty(
                                 true,
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible
                                )"
                             (keyup)="updateValueAtrributeScore(
                              '',
                              '',
                              $event.target,
                              country.name,
                              attributes.name,
                              valueDomains.isAllMarketVisible,
                              valueDomains.inputType
                              
                            )"
                              [value]="attributes.value"
                            >
                            </mat-form-field>
                            </div>
                          <mat-error
                          class="domainerror"
                          *ngIf="
                            !attributes.isValid && attributes.touched
                          "
                          >{{ attributes.name }} {{attributes.error}}</mat-error
                        >
                          </div>     
                          <div *ngIf="valueDomains.inputType  === 'range'">
                            <div class="range-input">
                              <div>
                                <div style="display: flex;align-items: center;">
                                  <p>From</p>
                                  <mat-form-field
                                  class="domain-scores-range2"
                                  floatLabel="always"
                                  id="scenario-details"
                                  [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                                  [ngClass]="{ 'error-line': !attributes.isStartRangeValid && attributes.rangeStartTouched}"
                                >
                                <input matInput 
                                placeholder=""
                                 type="number"
                                 step="1"
                                 heOnlyNumber
                                 heBlockDecimal
                                 [value]="attributes.rangeStart"
                                 (keyup)="updateValueAtrributeScore(
                                  '',
                                  '',
                                  $event.target,
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible,
                                  valueDomains.inputType,
                                  'start'
                                )"
                                 >
                                </mat-form-field>
                                </div>
                                <mat-error
                                style="width: 309px;height: 33px;"
                                class="domainerror"
                                >
                                <ng-container *ngIf=" !attributes.isStartRangeValid && attributes.rangeStartTouched">
                                  {{ attributes.name }} {{attributes.starRangeError}}
                                </ng-container>
                                </mat-error>
                              </div>
                              <div>
                                <div style="display: flex;align-items: center;">
                                  <p>To</p>
                                  <mat-form-field
                                  class="domain-scores-range2"
                                  floatLabel="always"
                                  id="scenario-details"
                                  [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                                  [ngClass]="{ 'error-line': !attributes.isEndRangeValid && attributes.rangeEndTouched}"
                                >
                                <input matInput 
                                placeholder="" 
                                type="number"
                                step="1"
                                heOnlyNumber
                                heBlockDecimal
                                [value]="attributes.rangeEnd"
                                (keyup)="updateValueAtrributeScore(
                                  '',
                                  '',
                                  $event.target,
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible,
                                  valueDomains.inputType,
                                  'end'
                                )"
                                >
                                </mat-form-field>
                                </div>
                                <mat-error
                                style="margin-left: 55px;height: 33px;"
                                class="domainerror"
                                
                                >
                                <ng-container *ngIf=" !attributes.isEndRangeValid && attributes.rangeEndTouched
                                ">
                                {{ attributes.name }} {{attributes.endRangeError}}

                                </ng-container>
                              </mat-error>
                              </div>
  
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                    <!-- <div>{{attributes.touched}}{{attributes.selectedScore}}</div> -->
                  </ng-container>
                </div>
              </ng-container>

            </div>
          </div>

          <!-- for all country attributes -->
          <div *ngIf="!valueDomains.isAllMarketVisible">
            <div *ngFor="let country of valueDomains.allCountries">
              <div class="horizon-field-02">
                <ng-container
                  *ngFor="let attributes of country.valueAttributes"
                >
                  <div class="domains-Attributes" id="{{attributes.scrollId}}">
                    <div class="domain-name">
                      <p class="attributes-name" style="margin: 0" >
                        {{ attributes.combineName }} <span class="mandatory-field">*</span>
                      </p>
                      <div>
                        <mat-icon
                        *heFeatureFlag="{
                          Name: 'asset-tpp-info',
                          Page: 'Asset-Tpp'
                        }"
                        #tooltip="matTooltip"
                        matTooltip="{{ attributes.infoData }}"
                        matTooltipClass="horizon-tooltip"
                        >info</mat-icon
                      >
                      </div>
       
                    </div>
                    <div class="domain-value">
                      <div *ngIf="attributes.formType === 'dropdown'">
                        <div>
                          <mat-form-field
                          class="domain-scores"
                          floatLabel="always"
                          id="scenario-score-combined"
                          [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                          [ngClass]="{ 'error-line': attributes.selectedScore ==='' && attributes.touched}"
                        >
                          <mat-select
                            #selectedScoreCombined
                            class="select-score"
                            required
                            (openedChange)="
                              checkFormDirty(
                                $event,
                                country.name,
                                attributes.name,
                                valueDomains.isAllMarketVisible
                              )
                            "
                            (selectionChange)="
                              updateValueAtrributeScore(
                                selectedScoreCombined.value.scoreId,
                                selectedScoreCombined.value.score,
                                'NA',
                                country.name,
                                attributes.combineName,
                                valueDomains.isAllMarketVisible,
                                attributes.formType,
                                $event
                              )
                            "
                            [value]="attributes.selectedScore"
                            [disabled]="!isEditAssetTppScores"
                            [hideSingleSelectionIndicator]="true"
                          >
                            <mat-option
                              [value]="score"
                              *ngFor="let score of attributes.score"
                              >{{ score.score }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field
                          class="domain-scores-discrpt"
                          floatLabel="always"
                          id="scenario-details-combined"
                          [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                          [ngClass]="{ 'error-line': attributes.selectedScore ==='' && attributes.touched}"
                        >
                          <mat-select
                            #selectedScoreDescCombined
                            (openedChange)="
                              checkFormDirty(
                                $event,
                                country.name,
                                attributes.name,
                                valueDomains.isAllMarketVisible
                              )
                            "
                            (selectionChange)="
                              updateValueAtrributeScore(
                                selectedScoreDescCombined.value.scoreId,
                                selectedScoreDescCombined.value.score,
                                'NA',
                                country.name,
                                attributes.combineName,
                                valueDomains.isAllMarketVisible,
                                attributes.formType
                              )
                            "
                            [value]="attributes.selectedScore"
                            placeholder="Please select"
                            [disabled]="!isEditAssetTppScores"
                          >
                            <mat-option
                              [value]="score"
                              *ngFor="let score of attributes.score"
                              >{{ score.scoreDescription }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        </div>
          
                        <mat-error
                        class="domainerror"
                        *ngIf="attributes.selectedScore ==='' && attributes.touched
                        "
                        >{{ attributes.name }} is required</mat-error
                      >
                      </div>
                      <div *ngIf="attributes.formType === 'value'">
                        <div *ngIf="valueDomains.inputType  === 'value'">
                          <div>
                            <mat-form-field
                            class="domain-scores-range1"
                            floatLabel="always"
                            id="scenario-details"
                            [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                            [ngClass]="{ 'error-line': !attributes.isValid && attributes.touched}"
                          >
                          <input matInput placeholder="{{attributes.placeholder}}" type="number" step="1" heOnlyNumber heBlockDecimal
                          (input)="checkFormDirty(
                                 true,
                                  country.name,
                                  attributes.name,
                                  valueDomains.isAllMarketVisible
                                )"
                          (keyup)="updateValueAtrributeScore(
                            '',
                            '',
                            $event.target,
                            country.name,
                            attributes.combineName,
                            valueDomains.isAllMarketVisible,
                            valueDomains.inputType
                          )"
                          [value]="attributes.value"
                          >
                          </mat-form-field>
                          </div>
                          <mat-error
                          class="domainerror"
                          *ngIf=" !attributes.isValid && attributes.touched
                          "
                          >{{ attributes.combineName }} {{attributes.error}}</mat-error>
                        </div>                
                        <div *ngIf="valueDomains.inputType  === 'range'">
                          <div class="range-input">
                            <div>
                              <div style="display: flex;align-items: center;">
                                <p>From</p>
                                <mat-form-field
                                class="domain-scores-range2"
                                floatLabel="always"
                                id="scenario-details"
                                [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                                [ngClass]="{ 'error-line': !attributes.isStartRangeValid && attributes.rangeStartTouched}"
                              >
                              <input matInput 
                              placeholder=""
                               type="number"
                               step="1"
                               heOnlyNumber
                               heBlockDecimal
                               [value]="attributes.rangeStart"
                               (keyup)="updateValueAtrributeScore(
                                '',
                                '',
                                $event.target,
                                country.name,
                                attributes.combineName,
                                valueDomains.isAllMarketVisible,
                                valueDomains.inputType,
                                'start'
                              )"
                               >
                              </mat-form-field>
                              </div>
                                <mat-error
                                style="width: 309px;height: 33px;"
                                class="domainerror"
                                >
                                <ng-container *ngIf=" !attributes.isStartRangeValid && attributes.rangeStartTouched">{{ attributes.combineName }} {{attributes.starRangeError}} </ng-container>
                              </mat-error>
                            </div>
                            <div>
                              <div style="display: flex;align-items: center;">
                                <p>To</p>
                                <mat-form-field
                                class="domain-scores-range2"
                                floatLabel="always"
                                id="scenario-details"
                                [ngClass]="{ 'disable-Input': !isEditAssetTppScores }"
                                [ngClass]="{ 'error-line': !attributes.isEndRangeValid && attributes.rangeEndTouched}"
                              >
                              <input matInput 
                              placeholder="" 
                              type="number"
                              step="1"
                              heOnlyNumber
                              heBlockDecimal
                              [value]="attributes.rangeEnd"
                              (keyup)="updateValueAtrributeScore(
                                '',
                                '',
                                $event.target,
                                country.name,
                                attributes.combineName,
                                valueDomains.isAllMarketVisible,
                                valueDomains.inputType,
                                'end'
                              )"
                              >
                              </mat-form-field>
                              </div>
                   
                              <mat-error
                              style="margin-left: 55px;height: 33px;"
                              class="domainerror"
                              >
                              <ng-container *ngIf=" !attributes.isEndRangeValid && attributes.rangeEndTouched ">
                              {{ attributes.combineName }} {{attributes.endRangeError}}
                              </ng-container>
                            </mat-error>
                            </div>

                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
<he-loading-spinner
  *ngIf="loading"
  [diameter]="70"
  [height]="'350px'"
></he-loading-spinner>
