<form [formGroup]="projectForm" class="project-details" *ngIf="!loading">
  <div
    class="new-project-card"
    *heFeatureFlag="{
      Name: 'project-details-form',
      Page: 'create-new-project'
    }"
  >
    <mat-card class="example-card">
      <mat-card-header>
        <div class="heading">
          <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px;"/> -->
          <!-- <img src="assets/lock.svg" class="project-icon" style="margin-top: -3px;"/> -->
          <span class="heading-title">Project Details</span>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div style="display: flex">
          <div class="input-container-left">
            <div class="input-div">
              <p>Project Name <span class="mandatory-field">*</span></p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field"
                id="projectName-field"
              >
                <input
                  matInput
                  formControlName="projectName"
                  required
                  [readonly]="projectId !== ''"
                  heAutoTrimInput
                  (blur)="projectNameChanged($event)"
                />
                <!-- <mat-error>{{getErrorMessage()}}</mat-error> -->
              </mat-form-field>
              <span
                class="errorMsg"
                *ngIf="
                  projectName?.hasError('required') &&
                  (projectName?.dirty || projectName?.touched)
                "
              >
                Project Name is required
              </span>
              <span class="errorMsg" *ngIf="projectName?.hasError('isMinLengthError')">
                Project Name must be at least 5 characters long.
              </span>
              <span class="errorMsg" *ngIf="projectName?.hasError('isMaxLengthError')">
                Project Name cannot exceed 200 characters.
              </span>
              <span
                class="errorMsg"
                *ngIf="projectName?.hasError('noSpecialCharacters')"
              >
                The input cannot contain any of the following characters: ? ! #
                % *< > ~
              </span>

              
              <span
                class="errorMsg"
                *ngIf="projectName?.hasError('projectNameExists')"
              >
                Project with the same name already exists. Please enter a
                different project name.
              </span>
            </div>
            <div class="input-div">
              <p>Asset Name <span class="mandatory-field">*</span></p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field"
                id="assetName-field"
              >
                <input
                  matInput
                  formControlName="assetName"
                  required
                  [readonly]="projectId !== ''"
                  heAutoTrimInput
                />
              </mat-form-field>
              <span
                class="errorMsg"
                *ngIf="
                  assetName?.hasError('required') &&
                  (assetName?.dirty || assetName?.touched)
                "
              >
                Asset Name is required
              </span>
              <span class="errorMsg" *ngIf="assetName?.hasError('isMinLengthError')">
                Asset Name must be at least 5 characters long.
              </span>
              <span class="errorMsg" *ngIf="assetName?.hasError('isMaxLengthError')">
                Asset Name cannot exceed 200 characters.
              </span>
              <span
                class="errorMsg"
                *ngIf="assetName?.hasError('noSpecialCharacters')"
              >
                The input cannot contain any of the following characters: ? ! #
                % *< > ~
              </span>
            </div>
            <div class="input-div">
              <p>Top Level Indication <span class="mandatory-field">*</span></p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field framework-field"
                id="topLevelIndication-field"
              >
                <!-- <mat-select required formControlName="topLevelIndication" [value]="selectedTLIvalue">
                  <mat-option
                    *ngFor="let tli of TLIData"
                    [value]="tli"
                    >{{ tli.name }}</mat-option
                  >
                </mat-select> -->
                <input
                  type="text"
                  matInput
                  formControlName="topLevelIndication"
                  [readonly]="projectId !== ''"
                  heAutoTrimInput
                />
                <!-- <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayTliFn"
                >
                  <mat-option
                    *ngFor="let option of filteredTLIs | async"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete> -->
              </mat-form-field>
              <span
                class="errorMsg"
                *ngIf="
                  topLevelIndication?.hasError('required') &&
                  (topLevelIndication?.dirty || topLevelIndication?.touched)
                "
                >Top Level Indication is required</span
              >
              <span
                class="errorMsg"
                *ngIf="topLevelIndication?.hasError('isMinLengthError')"
              >
                Top Level Indication must be at least 5 characters long.
              </span>
              <span
                class="errorMsg"
                *ngIf="topLevelIndication?.hasError('noSpecialCharacters')"
              >
                The input cannot contain any of the following characters: ? ! #
                % *< > ~
              </span>
            </div>
            <div class="input-div" style="margin-top: 20px !important">
              <p>Mechanism of Action</p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field"
                id="moa-field"
              >
                <input
                  matInput
                  formControlName="moa"
                  [readonly]="projectId !== ''"
                  heAutoTrimInput
                />
              </mat-form-field>
              <span
                class="errorMsg"
                *ngIf="
                  moa?.hasError('required') && (moa?.dirty || moa?.touched)
                "
              >
                Mechanism of Action is required
              </span>

              <span class="errorMsg" *ngIf="moa?.hasError('isMinLengthError')">
                Mechanism of Action must be at least 5 characters long.
              </span>
              <span
                class="errorMsg"
                *ngIf="moa?.hasError('noSpecialCharacters')"
              >
                The input cannot contain any of the following characters: ? ! #
                % *< > ~
              </span>
            </div>
            <div class="input-div">
              <p>GPI Horizon Framework</p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field framework-field"
                id="framework-field"
              >
                <mat-label class="label-color">Please select</mat-label>
                <mat-select
                  required
                  [value]="selectedValueFrameworks"
                  [disabled]="projectId !== ''"
                  (selectionChange)="checkFrameWork($event.value);selectFramework($event.value);"
                >
                  <mat-option
                    class="framework-field-option"
                    *ngFor="let valueframework of ValueFrameworksView"
                    [value]="valueframework"
                    >{{ valueframework.valueFrameworkName }}</mat-option
                  >
                </mat-select>
                <mat-error>Framework is required</mat-error>
              </mat-form-field>
              <!-- <mat-form-field appearance="fill" class="mat-form-field framework-field">
                <mat-label class="label-color">Please select</mat-label>
                <mat-select required formControlName="framework" [value]="selectedValueFrameworks" [disabled]="true">
                  <mat-option class="framework-field-option"
                    *ngFor="let valueframework of ValueFrameworks"
                    [value]="valueframework" (click)="selectFramework()"
                    >{{ valueframework.valueFrameworkName }}</mat-option
                  >
                </mat-select>
                <mat-error>Framework is required</mat-error>
              </mat-form-field> -->
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div class="input-container-right">
            <div class="complete-div" *ngIf="projectForm.status === 'VALID'">
              <span class="complete">Complete</span>
            </div>
            <div class="input-div" style="width: 95%">
              <p>Project Details</p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field text-area-field"
                id="projectDetails-field"
              >
                <textarea
                  matInput
                  rows="7"
                  formControlName="projectDetails"
                  [readonly]="projectId !== ''"
                ></textarea>
              </mat-form-field>
              <!-- <span
                class="errorMsg"
                *ngIf="
                  projectDetails?.hasError('required') &&
                  (projectDetails?.dirty || projectDetails?.touched)
                "
              >
                Project Details is required
              </span>
               -->
              <span
                class="errorMsg"
                *ngIf="
                  (projectDetails?.dirty || projectDetails?.touched) &&
                  projectDetails?.hasError('isMaxLengthError')
                "
              >
                Project details cannot exceed 200 characters
              </span>
              <span
                class="errorMsg"
                *ngIf="projectDetails?.hasError('noSpecialCharacters')"
              >
                The input cannot contain any of the following characters: ? ! #
                % *< > ~
              </span>
            </div>
            <div class="input-div" style="width: 80%">
              <p>Markets <span class="mandatory-field">*</span></p>
              <mat-form-field
                [ngClass]="{ 'disable-Input': projectId !== '' }"
                appearance="fill"
                class="mat-form-field"
                id="markets-field"
              >
                <mat-icon matPrefix class="markets-search-icons"
                  >search</mat-icon
                >
                <input
                  matInput
                  [matAutocomplete]="autoMarkets"
                  formControlName="markets"
                  [readonly]="projectId !== ''"
                />
                <mat-autocomplete #autoMarkets="matAutocomplete" (optionSelected)="onMarketSelect()">
                  <mat-option
                    class="market-field-option"
                    *ngFor="let option of filteredMarketsTemp"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error
                *ngIf="
                  projectForm.controls.markets.touched &&
                  selectedMarket.length === 0
                "
                class="errorMsg"
              >
                Markets is required</mat-error
              >
              <p
                style="color: #77839f; margin-top: 5px"
                *ngIf="selectedMarket.length < 1"
              >
                None Selected...
              </p>
              <mat-chip-listbox class="market-chip-list">
                <mat-chip
                  *ngFor="let market of selectedMarket"
                  [removable]="true"
                  (removed)="onMarketsRemoved(market)"
                >
                  <he-country-cell country="{{ market }}"></he-country-cell>
                  <mat-icon matChipRemove *ngIf="projectId === ''"
                    >cancel</mat-icon
                  >
                </mat-chip>
              </mat-chip-listbox>
            </div>
            <ng-container *ngIf="projectService.comparatorAvailable">
              <div class="input-div " style="width: 100%"  *heFeatureFlag="{
                Name: 'comparator-selection',
                Page: 'project-sidebar'
              }">
                <p>Comparators <span class="mandatory-field">*</span></p>
                <div class="custom-toogle">
                  <p>Are there any known comparators for your asset currently available in the specific indication?</p>
                  <mat-button-toggle-group formControlName="comparator" name="fontStyle" aria-label="Font Style" style="width: 50%;" (change)="resetAnalogues();">
                    <mat-button-toggle       matTooltip="Comparator Selection"
                    matTooltipClass="horizon-tooltip" style="width: 50%;" value="true">Yes</mat-button-toggle>
                    <mat-button-toggle matTooltip="Analogue Selection"
                    matTooltipClass="horizon-tooltip" style="width: 50%;" value="false">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
  
              </div>
            </ng-container>

          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <he-collaborator></he-collaborator>

  <div class="row">
    <div class="col-12">
      <div
        class="new-project-card"
        *heFeatureFlag="{
          Name: 'upload-file-form',
          Page: 'create-new-project'
        }"
      >
        <mat-card class="example-card">
          <mat-card-header>
            <div class="heading">
              <span class="heading-title">Project Files</span>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div style="display: flex">
              <div class="input-container-left">
                <div
                  class="upload-div"
                  style="width: 91%"
                  heDnd
                  (fileDropped)="onFileDropped($event)"
                  id="uploadFile-div"
                >
                  <div>
                    <img
                      src="../../../../../../assets/uploadImg.png"
                      class="upload-img-logo"
                      alt="Document logo"
                    />
                  </div>
                  <input
                    type="file"
                    #fileDropRef
                    accept=".ppt,.doc,.docx,.pdf,.pptx"
                    id="fileDropRef"
                    multiple
                    (change)="fileBrowseHandler($event)"
                    style="display: none"
                  />
                  <span>Drag your files here</span
                  ><span style="margin-top: 10px"
                    >or
                    <label
                      for="fileDropRef"
                      class="label-color"
                      style="font-weight: 600; cursor: pointer"
                      >Browse</label
                    >
                  </span>
                </div>
                <div class="upload-section-desc">
                  <span>Supported formats : DOC, DOCX, PDF, PPT, PPTX</span>
                  <span>Maximum size : 5MB</span>
                </div>
              </div>
              <div class="pull-right">
                <span
                  class="uploadStatusClass"
                  mat-stroked-button
                  *ngIf="fileListData.length !== 0"
                  >COMPLETE</span
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-5 ml-3"
                *ngFor="let file of NewFileList; let i = index"
              >
                <div class="files-list">
                  <div class="single-file">
                    <div class="file-icon" style="width: 55px">
                      <img src="/assets/document.png" alt="Document logo" />
                    </div>
                    <div class="info">
                      <h4 class="name">
                        {{ file?.name }}
                      </h4>
                      <p class="size">
                        {{ file?.name.split(".")[1].toUpperCase() }} document
                        {{ formatBytes(file?.length) }}
                      </p>
                    </div>
                    <a class="border-style download-icon"
                      ><img
                        src="/assets/download.png"
                        alt="Download File logo"
                        href="#"
                        (click)="downloadFileFunction($event, file?.name)"
                    /></a>
                    <a
                      target="_blank"
                      class="open-spacing border-style"
                      type="button"
                      (click)="openFileFunction($event, file?.name)"
                      >Open</a
                    >
                    <a
                      target="_blank"
                      class="open-spacing border-style disable-file"
                      type="button"
                      (click)="deleteFile(i)"
                      >Remove</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-5 ml-3"
                *ngFor="let file of fileListData; let i = index"
              >
                <div class="files-list">
                  <div class="single-file">
                    <div class="file-icon" style="width: 55px">
                      <img src="/assets/document.png" alt="Document logo" />
                    </div>
                    <div class="info">
                      <h4 class="name">
                        {{ file?.name }}
                      </h4>
                      <p class="size">
                        {{ file?.name.split(".")[1].toUpperCase() }} document
                        {{ formatBytes(file?.size) }}
                      </p>
                      <!-- <span *ngIf="file?.progress >= 0">
                        <he-progress-bar
                          [progress]="file?.progress"
                        ></he-progress-bar>
                      </span> -->
                    </div>
                    <a class="border-style download-icon"
                      ><img
                        src="/assets/download.png"
                        alt="Download File logo"
                        href="#"
                        (click)="downloadFile($event, file)"
                    /></a>
                    <a
                      target="_blank"
                      class="open-spacing border-style"
                      type="button"
                      (click)="openFile($event, file)"
                      >Open</a
                    >
                    <a
                      target="_blank"
                      class="open-spacing border-style"
                      type="button"
                      (click)="deleteFile(i)"
                      >Remove</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</form>
<he-loading-spinner
  *ngIf="loading"
  [diameter]="70"
  [height]="'350px'"
></he-loading-spinner>
<br /><br />
