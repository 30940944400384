import { filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Sort } from '@angular/material/sort';
import {
  CriteriaApiInterFace,
  CriteriaInterface,
  AnalogueTableInterface,
  LegendEnum,
  criteriaPayloadInterface,
  AnalogueListInterface,
  patientNumberCriteriaApiInterFace,
  multiValueList,
} from '../../models/criteria.interface';
import { CriteriaSelectionService } from '../../services/criteria-selection.service';
import { Subject, take, takeUntil } from 'rxjs';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { ProjectService } from '../../services/project.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { AnalogueMasterData } from '../../../../horizon-project/models/AnalogueMaseterData';
import { AnalogueiHeatMapComponent } from '../analoguei-heat-map/analoguei-heat-map.component';
import { ProjectDraftService } from '../../services/project-draft.service';
import { NetPriceService } from '../../services/net-price.service';
import { FeatureFlagService } from '../../../../horizon-project/services/feature-flag.service';
import { OncologyCriteriaConstant, ValueFrameworkNameConstant } from '../../enums/criteria.enum';
import { HeaderService } from '../../../../shared/services/header.service';
@Component({
  selector: 'he-analogue-selection-criteria',
  templateUrl: './analogue-selection-criteria.component.html',
  styleUrls: ['./analogue-selection-criteria.component.scss'],
})
export class AnalogueSelectionCriteriaComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable)public table!: MatTable<any>;
  @ViewChild(AnalogueiHeatMapComponent)public analogueHeatMapComponent!: AnalogueiHeatMapComponent;

  public firstPanelOpen = true;
  public secondPanelOpen = false;
  public thirdPanelOpen = false;
  public showMustHaveError = false;
  public minlaunchDate = 2019;
  public mindate = 2019;
  public countryFilter:string = "";
  public maxlaunchDate = new Date().getFullYear();
  public maxdate = new Date().getFullYear();
  public sort: any;
  public launchDateForm = new FormGroup({
    minlaunchDate: new FormControl(''),
    maxlaunchDate: new FormControl(''),
  });
  analogueColumns: string[] = [
    'brandName',
    'indication',
    'mechanismOfAction',
    'therapyArea',
    'remove',
  ];

  oncoAnalogueColumns: string[] = [
    'brandName',
    'inn',
    'HTAIndication',
    'mechanismOfAction',
    'remove',
  ];
  public mustHave: CriteriaInterface[] = [];
  public niceToHave: CriteriaInterface[] = [];
  public Unselected: CriteriaInterface[] = [];
  public defaultCriteria: CriteriaInterface[] = [];
  public isDraftProject: boolean = false;
  public loading = false;
  public criteriaList: {
    apititle: string;
    inputType: string;
    title: string;
  }[] = [
    {
      apititle: 'orphan',
      inputType: '1',
      title: 'Orphan',
    },
    {
      apititle: 'oneOffTherapy',
      inputType: '1',
      title: 'Duration of treatment',
    },
    {
      apititle: 'population',
      inputType: '1',
      title: 'Population',
    },
    {
      apititle: 'eligiblePatientNumber',
      inputType: '2',
      title: 'Patient number (per European market)',
    },
    {
      apititle: 'eligiblePatientNumberUSA',
      inputType: '2',
      title: 'Patient number (USA)',
    },
    {
      apititle: 'routeOfAdministration',
      inputType: '2',
      title: 'Route of administration',
    },
    {
      apititle: 'moaCategory',
      inputType: '3',
      title: 'Mechanism of action category',
    },
    {
      apititle: 'therapyAreas',
      inputType: '4',
      title: 'Therapy areas/Indications',
    },
    {
      apititle: 'lineoftherapy',
      inputType: '1',
      title: 'Line of therapy',
    },
    {
      apititle: 'indications',
      inputType: '4',
      title: 'Indications',
    },
    {
      apititle: 'formulation',
      inputType: '2',
      title: 'Formulation',
    },
    {
      apititle: 'biomarker',
      inputType: '2',
      title: 'Biomarker',
    },
    {
      apititle: 'durationoftreatment',
      inputType: '1',
      title: 'Duration of treatment',
    },
    {
      apititle: 'tli',
      inputType: '1',
      title: 'Top Level Indication',
    },    
    {
      apititle: 'therapyregimen',
      inputType: '3',
      title: 'Therapy regimen',
    },    
    {
      apititle: 'typeofcombination',
      inputType: '3',
      title: 'Type of combination',
    },    
    {
      apititle: 'biomarker_genetyping',
      inputType: '3',
      title: 'Biomarker/gene-typing',
    },    
    {
      apititle: 'priorreimbursedindication',
      inputType: '3',
      title: 'Prior reimbursed indications',
    },
  ];

  public legends = [
    {
      name: 'Meets criteria',
      color: LegendEnum[1],
    },
    {
      name: 'Partially meets criteria',
      color: LegendEnum[2],
    },
    {
      name: 'Does not meet criteria',
      color: LegendEnum[3],
    },
    {
      name: 'No criteria included',
      color: LegendEnum[0],
    },
  ];
  public selectedMarket: any = [];
  public newSelectedMarket: any = [];
  public analogues: AnalogueListInterface[] = [];
  public sortedAnalogues: any[] = [];
  public MasterAnalogueData: AnalogueMasterData[] = [];

  public dataSource: AnalogueTableInterface[] = [];
  private unsubscriber$ = new Subject<void>();

  public brandsCountData: any = [];
  private readonly PATIENT_NUMBER_MARKET_AREA = {
    Europe: 'EUROPE',
    UnitedStates: 'UNITED STATES',
  };
  public minMarket:number = 2;
  public newHeatmap:boolean = true;
  public dateInfoData:string='';
  public ValueFrameworkName:string = "";
  constructor(
    public criteriaSelectionService: CriteriaSelectionService,
    private toastNotificationService: ToastNotificationService,
    public projectService: ProjectService,
    private projectDraftService: ProjectDraftService,
    private netPriceService: NetPriceService,
    private featureFlagService: FeatureFlagService,
   private headerService:HeaderService
  ) {}

  ngOnInit(): void {
    this.ValueFrameworkName = this.projectService.createProjectFormData.value.framework.valueFrameworkName;
    this.featureFlagService.getFeatureFlagData().then((data) => {
      this.newHeatmap = data.find(
       (x) => x.name === 'new-analogue-heatMap' && x.page === 'analogue-selection'
     )?.isActive as boolean;
     this.minMarket = this.newHeatmap?5:2;
   });
    this.checkDraftProject();
    this.getMasterAnalogues();
    this.getAnalogueSelectionCriteria();
    this.criteriaSelectionService.castSavedSelectedAnalogues
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        const filteredData = res.filter(
          (element: any, index: any, array: any) => {
            return (
              array.findIndex(
                (prevElement: any) =>
                  prevElement.name === element.name &&
                  prevElement.indication === element.indication &&
                  prevElement.inn === element.inn
              ) === index
            );
          }
        );
        this.analogues = res;
      });
    this.getCreateProjectDetails();
    this.sortedAnalogues = this.projectService.selectedAnalogues;
    this.getBrandCountData();
  }

  getInputType(title:string, results: CriteriaApiInterFace){
    //var ValueFrameworkName = this.projectService.createProjectFormData.value.framework.valueFrameworkName;
    var inputType = "";
    let values: any[] = [];
    let list = Object.entries(results);
    list.forEach((element) => {
      if (element[0] === title) {
        values = element[1];
      }
    });
    if(this.ValueFrameworkName.toLowerCase() == ValueFrameworkNameConstant.orphan){

      switch (title) {
        case OncologyCriteriaConstant.orphan:
          inputType='1'
          
          break;
        case OncologyCriteriaConstant.oneOffTherapy:
          inputType='6'
          
          break;
        case OncologyCriteriaConstant.population:
          inputType='6'
          
          break;
        case OncologyCriteriaConstant.eligiblePatientNumber:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.eligiblePatientNumberUSA:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.routeOfAdministration:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.moaCategory:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.therapyAreas:
          inputType='4'
          
          break;
        case OncologyCriteriaConstant.lineoftherapy:
          inputType='1'
          
          break;
        case OncologyCriteriaConstant.indications:
          inputType='4'
          
          break;
        case OncologyCriteriaConstant.formulation:
          inputType='2'
          
          break;
        case OncologyCriteriaConstant.biomarker:
          inputType='2'
          
          break;
        case OncologyCriteriaConstant.durationoftreatment:
          inputType='1'
          
          break;
        case OncologyCriteriaConstant.tli:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.therapyregimen:
          inputType='3'
          
          break;
        case OncologyCriteriaConstant.typeofcombination:
          inputType='3'
          
          break;
        case OncologyCriteriaConstant.biomarker_genetyping:
          inputType='3'
          
          break;
        case OncologyCriteriaConstant.priorreimbursedindication:
          inputType='3'
          
          break;
    
        default:
          break;
      }

    }
    else if(this.ValueFrameworkName.toLowerCase() == ValueFrameworkNameConstant.oncology){
      switch (title) {
        case OncologyCriteriaConstant.orphan:
          inputType='1'
          
          break;
        case OncologyCriteriaConstant.oneOffTherapy:
          inputType='6'
          
          break;
        case OncologyCriteriaConstant.population:
          inputType='6'
          
          break;
        case OncologyCriteriaConstant.eligiblePatientNumber:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.eligiblePatientNumberUSA:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.routeOfAdministration:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.moaCategory:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.therapyAreas:
          inputType='4'
          
          break;
        case OncologyCriteriaConstant.lineoftherapy:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.indications:
          inputType='4'
          
          break;
        case OncologyCriteriaConstant.formulation:
          inputType='5'
          
          break;
        case OncologyCriteriaConstant.biomarker:
          inputType='5'
          
          break;
        case OncologyCriteriaConstant.durationoftreatment:
          inputType='6'
          
          break;
        case OncologyCriteriaConstant.tli:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.therapyregimen:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.typeofcombination:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.biomarker_genetyping:
          inputType=values.length>10?'8':'7'
          
          break;
        case OncologyCriteriaConstant.priorreimbursedindication:
          inputType='5'
          
          break;
    
        default:
          break;
      }
    }
    return inputType;
    
  }

  checkDraftProject() {
    this.projectDraftService.checkDraftProjectExist().then((res: any) => {
      if (res.data) {
        this.isDraftProject = res.data;
      }
    });
  }

  public refreshDataOnMarketChange() {
    this.Findanalogues();
  }
  public getMasterAnalogues(): void {
    const TLi = '';
    const MOA = '';
    this.criteriaSelectionService
      .getAnalogueMaster(TLi, MOA)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.MasterAnalogueData = res;
      });
  }

  public getBrandCountData(): void {
    this.criteriaSelectionService.brandCountData.subscribe((res) => {
      this.brandsCountData = res;
      this.checkBrandCount();
    });
  }

  public checkBrandCounts(data: any) {
    for (const country of data) {
      if (country.brandCount > this.minMarket) {
        return true;
      }
    }
    return false;
  }
  private getAnalogueSelectionCriteria(): void {
    this.criteriaSelectionService
      .getAnalogueSelectionCriteria(this.projectService.createProjectFormData.value.framework.valueFrameworkName)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.defaultCriteria = [];
        let titles = Object.keys(res);
        titles.forEach((title) => {
          if (
            title !== 'initialLaunchDate' &&
            title !== 'indications'
            // title !== 'therapyAreas'
          ) {
            this.setCriteriaList(title, res);
          } else if (title === 'initialLaunchDate') {
            let launchDates = res.initialLaunchDate.sort(
              (a, b) => JSON.parse(a) - JSON.parse(b)
            );
            this.dateInfoData =  res.infoIconData.find(x=> 'Initial launch date' === x.criteria).description
            this.mindate = JSON.parse(launchDates[0]);
            this.maxdate = JSON.parse(launchDates[launchDates.length - 1]);
            this.minlaunchDate = this.mindate;
            this.maxlaunchDate = this.maxdate;
          }
        });
        this.criteriaSelectionService.castCriteriaList
          .pipe(take(1))
          .subscribe((res) => {
            if (res !== null) {
              this.minlaunchDate = res.minlaunchDate;
              this.maxlaunchDate = res.maxlaunchDate;
              this.mustHave = res.mustHave.filter(
                (m: CriteriaInterface) =>
                  this.defaultCriteria.filter((x) => x.title === m.title)
                    .length > 0
              );
              this.niceToHave = res.niceToHave.filter(
                (m: CriteriaInterface) =>
                  this.defaultCriteria.filter((x) => x.title === m.title)
                    .length > 0
              );
              //this.Unselected = res.Unselected;
              let defaultUnselected = JSON.parse(
                JSON.stringify(
                  this.defaultCriteria.filter(
                    (x) =>
                      this.mustHave.filter((m) => m.title === x.title).length ==
                        0 &&
                      this.niceToHave.filter((n) => n.title === x.title)
                        .length == 0
                  )
                )
              );
              this.Unselected = defaultUnselected;
              this.getSavedAnalogue();
              this.criteriaSelectionService.castopenedTabindext
                .pipe(take(1))
                .subscribe((res) => {
                  // this.panelOpenState = res;
                  this.secondPanelOpen = res;
                });
              this.refreshDataOnMarketChange();
            } else {
              this.Unselected = JSON.parse(
                JSON.stringify(this.defaultCriteria)
              );
            }
          });
      });
  }

  private getSavedAnalogue(): void {
    this.criteriaSelectionService.castSavedTableData
      .pipe(take(1))
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  private setCriteriaList(title: string, results: CriteriaApiInterFace): void {
    if(title != "infoIconData"){
      let list = Object.entries(results)
      if(list.filter(x=>x[0]===title)[0][1] != null){
          if (
            this.criteriaList.find((res) => res.apititle === title)?.inputType == '4'|| this.criteriaList.find((res) => res.apititle === title)?.inputType == '7'
          ) {
            this.defaultCriteria.push({
              title: this.criteriaList.find((res) => res.apititle === title)
                ?.title as string,
              name:  this.titleCaseWord(title),
              infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
              ?.title === x.criteria).description,
              inputType: this.getInputType(title,results),
              // valueList: ,
              multiInputList: this.setValueList(
                title,
                results,
                this.PATIENT_NUMBER_MARKET_AREA.Europe
              ),
              minValue:'',
              maxValue: '',
              selectedValueList:[],
            });
          } else {
            if (title === 'eligiblePatientNumber') {
              const marketAreasForPatientNumbers =
                this.getMarketAreaForPatientNumber();
      
              if (marketAreasForPatientNumbers.length > 0) {
                marketAreasForPatientNumbers.forEach((ma) => {
                  if (ma === this.PATIENT_NUMBER_MARKET_AREA.Europe) {
                    this.defaultCriteria.push({
                      title: 'Patient number (per European market)',
                      name: this.titleCaseWord('eligiblePatientNumber'),
                      infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
              ?.title === x.criteria).description,
                      inputType: this.getInputType(title,results),
                      valueList: this.setValueList(
                        title,
                        results,
                        this.PATIENT_NUMBER_MARKET_AREA.Europe
                      ),
                      selectedValueList: [],
                      minValue:'',
                      maxValue: '',
                      multiValueList: this.setMultiValueList(
                        title,
                        results,
                        this.PATIENT_NUMBER_MARKET_AREA.Europe
                      ),
                    });
                  }
      
                  if (ma === this.PATIENT_NUMBER_MARKET_AREA.UnitedStates) {
                    this.defaultCriteria.push({
                      title: 'Patient number (USA)',
                      name: this.titleCaseWord('eligiblePatientNumberUSA'),
                      infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
              ?.title === x.criteria).description,
                      inputType: this.getInputType(title,results),
                      valueList: this.setValueList(
                        title,
                        results,
                        this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
                      ),
                      selectedValueList: [],
                      minValue:'',
                      maxValue: '',
                      multiValueList: this.setMultiValueList(
                        title,
                        results,
                        this.PATIENT_NUMBER_MARKET_AREA.Europe
                      ),
                    });
                  }
                });
              }
            } else {
              this.defaultCriteria.push({
                title: this.criteriaList.find((res) => res.apititle === title)
                  ?.title as string,
                name: this.titleCaseWord(title),
                infoData: results.infoIconData.find(x=>this.criteriaList.find((res) => res.apititle === title)
              ?.title === x.criteria).description,
                inputType: this.getInputType(title,results),
                valueList: this.setValueList(
                  title,
                  results,
                  this.PATIENT_NUMBER_MARKET_AREA.Europe
                ),
                selectedValueList: [],
                minValue:'',
                maxValue: '',
                multiValueList: this.setMultiValueList(
                  title,
                  results,
                  this.PATIENT_NUMBER_MARKET_AREA.Europe
                ),

              });
            }
          }
      }

  }
  }

  private getMarketAreaForPatientNumber() {
    const marketAreas = [];

    if (this.selectedMarket.length == 1) {
      if (this.selectedMarket.includes('United States')) {
        marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.UnitedStates);
      } else {
        marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.Europe);
      }
    }

    if (this.selectedMarket.length > 1) {
      marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.Europe);
      if (this.selectedMarket.includes('United States')) {
        marketAreas.push(this.PATIENT_NUMBER_MARKET_AREA.UnitedStates);
      }
    }

    return marketAreas;
  }

  public titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1);
  }

  private setValueList(
    title: string,
    results: CriteriaApiInterFace,
    marketArea: string
  ): any {
    let values: any[] = [];
    if (
      this.criteriaList.find((res) => res.apititle === title)?.inputType == '4' || this.criteriaList.find((res) => res.apititle === title)?.inputType == '7'
    ) {
      let list = Object.entries(results);
      list.forEach((element) => {
        if (element[0] === title) {
          this.criteriaSelectionService.therapyAreaList.next(
            this.setmultiInputList(element[1])
          );
          values = [
            {
              therapyValueList: this.setmultiInputList(element[1]),
              indicationValueList: [],
              selectedIndicationValue: [],
              selectedTherapyValue:'',
              selectAll: false,
            },
          ];
        }
      });
    } else {
      if (title === 'orphan') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = ['Include', 'Exclude', 'Only'];
          }
        });
      } else if (title === 'oneOffTherapy') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = ['Short-term', 'Long-term', 'One-off'];
          }
        });
      }else if (title === 'durationoftreatment') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = ['Short-term', 'Long-term', 'One-off'];
          }
        });
      }else if (title === 'population') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = ['Adult only', 'Paediatric only', 'Adult & Paediatric'];
          }
        });
      } else if (title === 'eligiblePatientNumber') {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            const patientNumberData = element[1].filter(
              (f: patientNumberCriteriaApiInterFace) =>
                f.marketArea === marketArea
            );
            values = patientNumberData[0]?.patientNumber;
          }
        });
      }else if (title === OncologyCriteriaConstant.priorreimbursedindication && this.ValueFrameworkName == ValueFrameworkNameConstant.oncology) {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            var data:number[] = [];
            element[1].forEach((ele:any)=>{
                if(ele === null){
                  data.push(0);
                }
                else{
                  data.push(Number(ele));
                }
              });
              values = data.sort((a,b)=> a - b);
          }
        });
      } else {
        let list = Object.entries(results);
        list.forEach((element) => {
          if (element[0] === title) {
            values = element[1];
          }
        });
      }
    }
    return values;
  }

  private setMultiValueList(
    title: string,
    results: CriteriaApiInterFace,
    marketArea: string
  ): multiValueList[] {
    let values: multiValueList[] = [];

    let list = Object.entries(results);
    list.forEach((element) => {
      if (element[0] === title) {
        values = element[1].map((x:string)=>{
          return{
            name:x,
            selected:false
          }
        })
      }
    });

    return values;
  }

  private setmultiInputList(valueList: any[]): any {
    return valueList.map((res: any) => {
      return {
        name: res.therapyArea,
        valueList: res.indications.map((res: any) => {
          return {
            name: res,
          };
        }),
      };
    });
  }

  public drop(event: CdkDragDrop<CriteriaInterface[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public reset(): void {
    this.mustHave = [];
    this.niceToHave = [];
    this.minlaunchDate = this.mindate;
    this.maxlaunchDate = this.maxdate;
    this.Unselected = JSON.parse(JSON.stringify(this.defaultCriteria));
    this.valueChange();
  }

  public valueChange(): void {
    let saveCriteria = {
      minlaunchDate: this.minlaunchDate,
      maxlaunchDate: this.maxlaunchDate,
      mustHave: this.mustHave,
      niceToHave: this.niceToHave,
      Unselected: this.Unselected,
    };
    this.criteriaSelectionService.criteriaList.next(saveCriteria);
  }

  public Findanalogues(): void {
    if (this.checkFormValid()) {
      this.secondPanelOpen = true;
      this.firstPanelOpen = false;
      this.analogueHeatMapComponent.searchTable.patchValue('')
      this.getAnalogue();
    }
  }

  public checkFormValid(): boolean {
    let state = true;
    if (this.selectedMarket.length === 0) {
      state = false;
      this.toastNotificationService.errorMessage('Please select at least one market in Project Details to continue');
    } else if (!this.launchDateForm.valid) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Initial launch date is invalid'
      );
    } else if (this.mustHave.length === 0) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Select at least One more Must Have Criteria'
      );
    } else if (!this.checkMustHaveValues()) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Selected Must Have Criteria value cannot be empty'
      );
    } else if (!this.checNicetoHaveValues()) {
      state = false;
      this.toastNotificationService.errorMessage(
        'Selected Nice to Have Criteria value cannot be empty'
      );
    }
    return state;
  }

  public checNicetoHaveValues(): boolean {
    let state = true;
    this.niceToHave.forEach((element) => {
      switch (element.inputType) {
        case '1':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '2':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '3':
          if (element?.selectedValueList?.length === 0) {
            state = false;
          }
          break;
        case '4':
          element?.multiInputList?.forEach((therapyarea) => {
            if (
              therapyarea.selectedTherapyValue === undefined ||
              therapyarea.selectedIndicationValue.length === 0
            ) {
              state = false;
            }
          });
          break;
      }
    });
    return state;
  }

  public checkMustHaveValues(): boolean {
    let state = true;
    this.mustHave.forEach((element) => {
      switch (element.inputType) {
        case '1':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '2':
          if (element?.selectedValue === undefined) {
            state = false;
          }
          break;
        case '3':
          if (element?.selectedValueList?.length === 0) {
            state = false;
          }
          break;
        case '4':
          element?.multiInputList?.forEach((therapyarea) => {
            if (
              therapyarea.selectedTherapyValue === undefined ||
              therapyarea.selectedIndicationValue.length === 0
            ) {
              state = false;
            }
          });
          break;
      }
    });
    return state;
  }

  public getAnalogue(): void {
    this.loading = true;
    this.dataSource = [];
    let body = this.setPayLoad();
    this.criteriaSelectionService
      .getAnalogue(body)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res) => {
          this.dataSource = res;
          this.headerService.AnalogueDataSource.next(this.dataSource);
          this.loading = false;
          this.criteriaSelectionService.savedTableData.next(this.dataSource);
          this.checkDataSource();
          this.checkAnalogues();
          if (
            !this.projectService.isDraftAnalogueFetched.value &&
            this.isDraftProject
          ) {
            this.firstPanelOpen = false;
            this.secondPanelOpen = false;
            this.thirdPanelOpen = true;
            this.projectService.isDraftAnalogueFetched.next(true);
          }
          this.analogueHeatMapComponent.getComparatorColumn(this.dataSource);
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  // assign values to the api input as per the criteria selected
  public setPayLoad(): any {
    let body: criteriaPayloadInterface = {
      InitialLaunchDate: {
        Type: 'Must have',
        Data: this.setDates(),
      },
      Countries: this.capitalizeArrayValues(this.selectedMarket),
      ValueFrameworkName: this.projectService.createProjectFormData.value.framework.valueFrameworkName      
    };
    if (this.mustHave.length !== 0) {
      this.mustHave.forEach((criteria) => {
        this.setPayLoadValues('Must have', criteria, body);
      });
    }
    if (this.niceToHave.length !== 0) {
      this.niceToHave.forEach((criteria) => {
        this.setPayLoadValues('Nice to have', criteria, body);
      });
    }
    this.netPriceService.heatMapPayload = body;
    return body;
  }

  public setPayLoadValues(
    criteriaType: string,
    criteria: CriteriaInterface,
    body: criteriaPayloadInterface
  ): any {
    switch (criteria.inputType) {
      case '1':
        body[criteria.name] = {
          Type: criteriaType,
          Data: [criteria.selectedValue],
        };
        break;
      case '2': {
        if (
          criteria.name === 'EligiblePatientNumber' ||
          criteria.name === 'EligiblePatientNumberUSA'
        ) {
          const marketArea =
            criteria.name === 'EligiblePatientNumberUSA'
              ? this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
              : this.PATIENT_NUMBER_MARKET_AREA.Europe;

          if (!body['EligiblePatientNumber']) {
            body['EligiblePatientNumber'] = [];
          }

          body['EligiblePatientNumber'].push({
            Type: criteriaType,
            Data: [criteria.selectedValue],
            MarketArea: marketArea,
          });
        } else {
          body[criteria.name] = {
            Type: criteriaType,
            Data: [criteria.selectedValue],
          };
        }

        break;
      }
      case '3':
        body[criteria.name] = {
          Type: criteriaType,
          Data: criteria.selectedValueList,
        };
        break;
      case '4':
        let therapyLIst: any[] = [];
        let indicationLIst: any[] = [];
        criteria.multiInputList?.forEach((res) => {
          therapyLIst.push(res.selectedTherapyValue);
          res.selectedIndicationValue.forEach((indication) => {
            indicationLIst.push(indication);
          });
        });
        body['TherapyArea'] = {
          Type: criteriaType,
          Data: therapyLIst,
        };
        body['IndicationCategory'] = {
          Type: criteriaType,
          Data: indicationLIst,
        };
        break;
      case '5':
        body[criteria.name] = {
          Type: criteriaType,
          Data: criteria.valueList,
          maxvalue: criteria.maxValue,
          minvalue: criteria.minValue,
        };
        break;
      case '6':
        body[criteria.name] = {
          Type: criteriaType,
          Data: criteria.selectedValueList,
        };
        break;
      case '7':{
        if (
          criteria.name === 'EligiblePatientNumber' ||
          criteria.name === 'EligiblePatientNumberUSA'
        ) {
          const marketArea =
            criteria.name === 'EligiblePatientNumberUSA'
              ? this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
              : this.PATIENT_NUMBER_MARKET_AREA.Europe;

          if (!body['EligiblePatientNumber']) {
            body['EligiblePatientNumber'] = [];
          }

          body['EligiblePatientNumber'].push({
            Type: criteriaType,
            Data: criteria.selectedValueList,
            MarketArea: marketArea,
          });
        } else {
          body[criteria.name] = {
            Type: criteriaType,
            Data: criteria.selectedValueList,
          };
        }
        break;      
      }
      case '8':{
        if (
          criteria.name === 'EligiblePatientNumber' ||
          criteria.name === 'EligiblePatientNumberUSA'
        ) {
          const marketArea =
            criteria.name === 'EligiblePatientNumberUSA'
              ? this.PATIENT_NUMBER_MARKET_AREA.UnitedStates
              : this.PATIENT_NUMBER_MARKET_AREA.Europe;

          if (!body['EligiblePatientNumber']) {
            body['EligiblePatientNumber'] = [];
          }

          body['EligiblePatientNumber'].push({
            Type: criteriaType,
            Data: criteria.selectedValueList,
            MarketArea: marketArea,
          });
        } else {
          body[criteria.name] = {
            Type: criteriaType,
            Data: criteria.selectedValueList,
          };
        }
        break;
      }
    }
    return body;
  }

  public checkDataSource(): void {
    this.dataSource.forEach((analogue) => {
      analogue.toggle.toggle  = this.analogues.some(
        (res) =>
          res.name === analogue.Brand &&
          res.indication === analogue.Indication.name &&
          res?.HTAIndication === analogue?.HTAIndication?.name &&
          res.inn === analogue.INN && this.checkselectedDatstoreMarket(analogue,res)
      );
      analogue.toggle.disable  = this.analogues.some(
        (res) =>
          res.name === analogue.Brand &&
          res?.HTAIndication !== analogue?.HTAIndication?.name  && this.checkselectedDatstoreMarket(analogue,res)
      );
      this.analogues.forEach((x) => {
        if (
          x.name === analogue.Brand &&
          x.indication === analogue.Indication.name &&
          x?.HTAIndication === analogue?.HTAIndication?.name &&
          x.inn === analogue.INN && this.checkselectedDatstoreMarket(analogue,x)
        ) {
          // x.market = [...x.market,...analogue.Market];
          analogue.Country.forEach((y:any)=>{
            if(!x.market.some(a=> a.name === y.name)){
              x.market.push(y)
            }
          })
        }
      });
    });
    
  }
  
  

  public checkAnalogues(): void {
    this.analogues.forEach((analogue) => {
      analogue.available = this.dataSource.some(
        (res) => res.Brand === analogue.name && 
        res.Indication.name === analogue.indication
        &&  res.INN === analogue.inn && this.checkselectedAnalogueMarket(analogue,res)
      );
    });
    this.getAnalogueList(this.analogues);
  }

  public checkselectedAnalogueMarket(analogue1:any,analogue2:any):boolean{
    let valid = false;
    analogue1.Country.forEach((element:any) => {
      if(analogue2.Market.some((x:any)=> x.name === element.name)){
        valid = true;
      }
    });
    return valid;

  }

  public checkselectedDatstoreMarket(analogue1:any,analogue2:any):boolean{
    let valid = false;
    analogue1.Country.forEach((element:any) => {
      if(analogue2.market.some((x:any)=> x.name === element.name)){
        valid = true;
      }
    });
    return valid;

  }

  public setDates(): string[] {
    let dates: string[] = [];
    for (let index = this.minlaunchDate; index <= this.maxlaunchDate; index++) {
      dates.push(JSON.stringify(index));
    }
    return dates;
  }

  /**
   * this function removes the analogue based on anlogues data and removes all entry at once
   * also untoggle the button in heatmap
   * @param analogue
   */
  public removeAnalogue(analogue: AnalogueListInterface): void {
    const index = this.analogues.findIndex(
      (res) =>
        res.name.toLowerCase().trim() === analogue.name.toLowerCase().trim() &&
        res.indication.toLowerCase().trim() ===
          analogue.indication.toLowerCase().trim() &&
        res.inn.toLowerCase().trim() === analogue.inn.toLowerCase().trim()
    );
    if (index >= 0) {
      this.dataSource.filter(
        (res) =>
          res.Brand.toLowerCase().trim() === analogue.name.toLowerCase().trim() &&
          res.Indication.name.toLowerCase().trim() ===
            analogue.indication.toLowerCase().trim() &&
          res.INN.toLowerCase().trim() === analogue.inn.toLowerCase().trim()
      ).forEach((res) => {
        res.toggle.toggle  = false;
      });
      if (
        this.ValueFrameworkName!==
        'Orphan'
      ) {
        this.dataSource
          .filter(
            (res) =>
              res.Brand.toLowerCase().trim() ===
                analogue.name.toLowerCase().trim() &&
              res.HTAIndication.name.toLowerCase().trim() !==
                analogue.HTAIndication.toLowerCase().trim()
          )
          .forEach((res) => {
            res.toggle.disable = false;
          });
      }
      this.analogues.splice(index, 1);
    }
    this.analogueHeatMapComponent.updateMarketsBrandCount();

      this.sortedAnalogues = this.sortedAnalogues.filter(
        (res) =>
        res.brandName.toLowerCase().trim() !== analogue.name.toLowerCase().trim() &&
          res.indication.trim().toLowerCase() !==
            analogue.indication.trim().toLowerCase() &&
          res.inn.trim().toLowerCase() !== analogue.inn.trim().toLowerCase()
      );
      this.table.renderRows();
      //test sorting
      // this.setAnaloguesForNetPriceAssumptions();
      this.updateAnalogueInService();
    this.newSelectedMarket.forEach((x:any) => {
       let index = x.analogues.findIndex((y:any)=> y.name === analogue.name);
      if(index >= 0){
        x.analogues.splice(index,1)
      }
    });
  }

  public getAnalogueList(analogues: AnalogueListInterface[]): void {
    this.analogues = analogues;
    this.criteriaSelectionService.savedSelectedAnalogues.next(analogues);
    this.table.renderRows();
    this.updateAnalogueInService();
    this.setAnaloguesNewView();
  }

  public setAnaloguesNewView():void{
    this.newSelectedMarket.forEach((x:any)=>{
      this.analogues.forEach(y=>{
          if(!x.analogues.some((z:any)=> z.name===y.name &&
          z.inn === y.inn &&
          z.indication === y.indication)){
            if(y.market.some(a=>a.name.toLowerCase() === x.country.toLowerCase())){
              x.analogues.push(y);
            }
          }
      })
      x.analogues.sort((a:any,b:any)=> a.name.localeCompare(b.name))
    })
  }

  public getCreateProjectDetails(): void {
    this.projectService.castCreateProjectFormData
      .pipe(take(1))
      .subscribe((data) => {
        data?.selectedMarkets?.forEach((market: any) => {
          this.selectedMarket.push(market);
          this.newSelectedMarket.push({country:market,analogues:[]});
        });
        this.newSelectedMarket.sort((a:any,b:any)=> a.country.localeCompare(b.country))
        this.setAnaloguesNewView();
      });
  }
  /**
   * this function adds analogue in the sorting table and updates data in service
   */
  public addAnalogues() {
    // Check if the brand count for all countries is greater than 2
    const isBrandCountValid = this.brandsCountData.every(
      (data: any) => data.brandCount >= this.minMarket
    );
    if (!isBrandCountValid) {
      this.toastNotificationService.errorMessage(
        'Minimum '+ this.minMarket + ' brands are required for all countries.'
      );
      this.projectService.newValuechanged.next(true)
      this.criteriaSelectionService.isAnaloguesValid = false;
      return;
    }
    this.projectService.newValuechanged.next(true)
    this.criteriaSelectionService.isAnaloguesValid = true;
    this.sortedAnalogues = this.projectService.selectedAnalogues
      ? this.projectService.selectedAnalogues
      : [];
      //test sorting
    // this.setAnaloguesForNetPriceAssumptions();
    this.analogues.forEach((analogue) => {
      let sortedRecords = this.sortedAnalogues.filter(
        (ele) =>
          analogue.indication.toLowerCase().trim() ===
            ele.indication.toLowerCase().trim() &&
          analogue.inn.toLowerCase().trim() == ele.inn.toLowerCase().trim()
      );
      if (sortedRecords.length == 0) {
        let allMoaData = this.MasterAnalogueData.filter(
          (res) =>
            res.indication.trim().toLowerCase() ===
              analogue.indication.trim().toLowerCase() &&
            res.inn.trim().toLowerCase() === analogue.inn.trim().toLowerCase()
        );
        allMoaData.map((moaData, index) => {
          let analogueWithMoa:any ={}
          if (
            this.ValueFrameworkName===
            'Orphan'
          ) {
           analogueWithMoa = {
            brandName: analogue.name,
            indication: analogue.indication,
            mechanismOfAction: moaData.mechanismOfAction,
            therapyArea: moaData.therapyArea,
            inn: moaData.inn,
            sequenceNo: analogue.sequenceNo + index + 1,
          };
          if(!this.sortedAnalogues.some(x=>x.brandName === analogueWithMoa.brandName && x.mechanismOfAction === analogueWithMoa.mechanismOfAction )){
            this.sortedAnalogues.push(analogueWithMoa);
          }
        }else{

          analogueWithMoa = {
            brandName: analogue.name,
            indication: analogue.indication,
            HTAIndication: analogue.HTAIndication,
            mechanismOfAction: moaData.mechanismOfAction,
            inn: moaData.inn,
            sequenceNo: analogue.sequenceNo + index + 1,
          };
          if(!this.sortedAnalogues.some(x=>x.brandName === analogueWithMoa.brandName && x.HTAIndication === analogueWithMoa.HTAIndication && x.mechanismOfAction === analogueWithMoa.mechanismOfAction )){
            this.sortedAnalogues.push(analogueWithMoa);
            }
        }
        });
      }
    });
    this.updateAnalogueInService();
    this.secondPanelOpen = false;
    this.thirdPanelOpen = true;
    if (!isBrandCountValid) {
      this.criteriaSelectionService.sortedAnalogues.next(true);
    } else {
      this.criteriaSelectionService.sortedAnalogues.next(false);
    }
  }

  public setAnaloguesForNetPriceAssumptions() {
    let netAnalogues: any[] = [];
    let i = 0;
    //test sorting
    // this.datasoucre
    this.sortedAnalogues
      .forEach((x) => {
        let index = netAnalogues.findIndex(
          (y) =>
            x.brandName === y.Brand &&
            x.inn === y.INN &&
            x.indication === y.TLI
        );
        if (index <= -1) {
          if (
            this.ValueFrameworkName===
            'Orphan'
          ) {
          netAnalogues.push({
            Brand: x.brandName,
            INN: x.inn,
            TLI: x.indication,
            markets:  this.setMarketsForAnalogueNetprice(x),
            sqNo:i,
          });
        }else{
          netAnalogues.push({
            Brand: x.brandName,
            INN: x.inn,
            TLI: x.indication,
        HTAIndication: x.HTAIndication,

            markets:  this.setMarketsForAnalogueNetprice(x),
            sqNo:i,
          });
        }
          i++;
        } else {
          // netAnalogues[index].markets = [...x.Market,...netAnalogues[index].markets]
        }
      });
    this.netPriceService.selectedAnalogueNetprice = netAnalogues;
    this.projectService.newValuechanged.next(true)
  }

  setMarketsForAnalogueNetprice(data:any):any[]{
    let markets:any[]=[]; 
    this.dataSource.filter(x=> x.toggle && x.Brand === data.brandName && x.INN === data.inn && x.Indication.name === data.indication ).forEach(res=>{
      markets = [...markets,...res.Country]
    })
    return markets;
  }

  // setMarketsForAna

  /**
   * this functuion removes the analogue data based on index (i.e. one by one each moa variation)
   * in the table and updates the service
   * @param index
   */
  RemoveAnalogue(index: number) {
    const analogueRows = this.sortedAnalogues.filter(
      (res) =>
        this.checkSortedAnalogues(res,index)
    );
    if (analogueRows.length <= 1) {
    this.dataSource.filter(
        (res) =>this.checkSortedAnalogues2(res,index)
      ).forEach((res) => {
        res.toggle.toggle  = false;
        this.analogueHeatMapComponent.updateMarketsBrandCount();
      });;
      if (
        this.ValueFrameworkName!==
        'Orphan'
      ) {
        this.dataSource
          .filter(
            (res) =>
              res.Brand.toLowerCase().trim() ===
            this.sortedAnalogues[index].brandName.toLowerCase().trim() &&
              res.HTAIndication.name.toLowerCase().trim() !==
              this.sortedAnalogues[index].HTAIndication.toLowerCase().trim()
          )
          .forEach((res) => {
            res.toggle.disable = false;
          });
      }
    }

    if (analogueRows.length <= 1) {
      this.analogues = this.analogues.filter(
        (res) =>{
          let donotmatch = true;
         if( res.indication.toLowerCase() === this.sortedAnalogues[index].indication.toLowerCase() &&
        res.inn.toLowerCase() === this.sortedAnalogues[index].inn.toLowerCase()){
          donotmatch = false;
        }else{
          donotmatch = true;
        }
        return donotmatch;

        }
      );
      this.newSelectedMarket.forEach((x:any)=>{
        x.analogues = x.analogues.filter(
          (res:any) =>{
            let donotmatch = true;
           if( res.indication.toLowerCase() === this.sortedAnalogues[index].indication.toLowerCase() &&
          res.inn.toLowerCase() === this.sortedAnalogues[index].inn.toLowerCase()){
            donotmatch = false;
          }else{
            donotmatch = true;
          }
          return donotmatch;

          }
          
        );
      })
      
    }
    this.sortedAnalogues.splice(index, 1);
    this.table.renderRows();
    this.setAnaloguesForNetPriceAssumptions();
    this.updateAnalogueInService();
    this.setAnaloguesNewView();
  }

private  updateAnalogueInService():void {
    //this.projectService.selectedAnalogues = JSON.parse(JSON.stringify(this.selectedAnalogues));
    this.projectService.selectedAnalogues = [];
    this.sortedAnalogues.map((analogue: any, index: number) => {
      let nAnalogue ={}
      if (
        this.ValueFrameworkName===
        'Orphan'
      ) {
      nAnalogue= {
        brandName: analogue.brandName,
        indication: analogue.indication,
        mechanismOfAction: analogue.mechanismOfAction,
        sequenceNo: index + 1,
        inn: analogue.inn,
        therapyArea: analogue.therapyArea,
      };
    }else{
      nAnalogue= {
        brandName: analogue.brandName,
        indication: analogue.indication,
        HTAIndication: analogue.HTAIndication,
        mechanismOfAction: analogue.mechanismOfAction,
        sequenceNo: index + 1,
        inn: analogue.inn,
        therapyArea: analogue.therapyArea,
      };
      }
      this.projectService.selectedAnalogues.push(nAnalogue);
    });
    this.checkBrandCount();
    this.criteriaSelectionService.savedSelectedAnalogues.next(this.analogues);
    //test sorting
    this.setAnaloguesForNetPriceAssumptions();

  }

  public checkBrandCount():void{
    const isBrandCountValid = this.brandsCountData.every(
      (data: any) => data.brandCount >= this.minMarket
    );
    if (!isBrandCountValid) {
      this.criteriaSelectionService.sortedAnalogues.next(true);
      this.criteriaSelectionService.isAnaloguesValid = false;
      this.projectService.newValuechanged.next(true)
    }else{
      this.criteriaSelectionService.isAnaloguesValid = true;
      this.projectService.newValuechanged.next(true)
    }
  }

  moveUp(index: any) {
    if (index > 0) {
      const tmp = this.sortedAnalogues[index - 1];
      this.sortedAnalogues[index - 1] = this.sortedAnalogues[index];
      this.sortedAnalogues[index] = tmp;
      this.table.renderRows();
      this.updateAnalogueInService();
    }
  }

  moveDown(index: any) {
    if (index < this.sortedAnalogues.length - 1) {
      const tmp = this.sortedAnalogues[index + 1];
      this.sortedAnalogues[index + 1] = this.sortedAnalogues[index];
      this.sortedAnalogues[index] = tmp;
      this.table.renderRows();
      this.updateAnalogueInService();
    }
  }

  dropSorting(event: CdkDragDrop<string[]>) {
    const previousIndex = this.sortedAnalogues.findIndex(
      (row: any) => row === event.item.data
    );
    moveItemInArray(this.sortedAnalogues, previousIndex, event.currentIndex);
    this.table.renderRows();
    this.updateAnalogueInService();
  }

  public sortData(sort: Sort): void {
    this.sort = sort;
    const data = this.sortedAnalogues.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedAnalogues = data;
      return;
    }

    this.sortedAnalogues = data.sort((a, b) => {
      const isAsc = sort.direction !== 'asc';
      switch (sort.active) {
        case 'brandName':
          return compare(a.brandName, b.brandName, isAsc);
        case 'indication':
          return compare(a.indication, b.indication, isAsc);
        case 'mechanismOfAction':
          return compare(a.mechanismOfAction, b.mechanismOfAction, isAsc);
        case 'therapyArea':
          return compare(a.therapyArea, b.therapyArea, isAsc);
          case 'inn':
            return compare(a.inn, b.inn, isAsc);
            case 'HTAIndication':
              return compare(a.HTAIndication, b.HTAIndication, isAsc);
        default:
          return 0;
      }
    });
    this.updateAnalogueInService();
  }

  capitalizeArrayValues(arr: string[]): string[] {
    return arr.map((value) => value.toUpperCase());
  }

  public selectCountry(country:string):void{
    if(this.countryFilter != country){
      this.countryFilter = country;
    }else{
      this.countryFilter = ""
    }
    this.analogueHeatMapComponent.searchTableData(this.countryFilter)
  }

  checkSortedAnalogues(res:any,index:number):boolean{
    let check=false;
    if( this.ValueFrameworkName===
      'Orphan'){
      check = res.indication.toLowerCase().trim() ===
      this.sortedAnalogues[index].indication.toLowerCase().trim() &&
    res.inn.toLowerCase().trim() ===
      this.sortedAnalogues[index].inn.toLowerCase().trim()
    }else{
      check = res.indication.toLowerCase().trim() ===
      this.sortedAnalogues[index].indication.toLowerCase().trim() &&
    res?.HTAIndication.toLowerCase().trim() ===
      this.sortedAnalogues[index]?.HTAIndication.toLowerCase().trim() 
    }
    return check;
  }

  checkSortedAnalogues2(res:any,index:number):boolean{
    let check=false;
    if( this.ValueFrameworkName===
      'Orphan'){
      check = res.Indication.name.toLowerCase().trim() ===
      this.sortedAnalogues[index].indication.toLowerCase().trim() &&
    res.INN.toLowerCase().trim() ===
      this.sortedAnalogues[index].inn.toLowerCase().trim()
    }else{
      check = res.Indication.name.toLowerCase().trim() ===
      this.sortedAnalogues[index].indication.toLowerCase().trim() &&
    res?.HTAIndication?.name.toLowerCase().trim() ===
      this.sortedAnalogues[index]?.HTAIndication.toLowerCase().trim()
    }
    return check;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.criteriaSelectionService.openedTabindex.next(this.secondPanelOpen);
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
