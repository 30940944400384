<div class="analogue-card expand-criteria">
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="firstPanelOpen"
      (opened)="firstPanelOpen = true"
      (closed)="firstPanelOpen = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Comparator Selection Criteria </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <p>
          Based on the criteria below, arranged in two levels of importance, a
          list of suggested comparators will be generated, and their relevancy
          will be shown in a heatmap. You will be able to select the relevant
          comparators based on the information presented in the heatmap.
        </p>
        <p class="sub-title" style="color: #4aa47a; margin-top: 24px">
          Must Have Criteria
        </p>
      </div>
      <div class="example-box">
        <div class="d-flex d-align-c left-box">
          Top level Indication
          <span style="color: red; margin-left: 5px">*</span>
          <div>
            <mat-icon
              #tooltip="matTooltip"
              matTooltip=" This category must match the top level indication of your asset to identify potential comparators."
              matTooltipClass="horizon-tooltip"
              class="horizon-tooltip"
              >info</mat-icon
            >
          </div>
        </div>
        <div class="right-box">
          <he-criteria-input
            [inputdata]="tli"
            (onValueChange)="valueChange()"
          ></he-criteria-input>
        </div>
      </div>
      <!-- <div class="border-btm">
        <div class="example-container">
          <div
            cdkDropList
            #mustHaveList="cdkDropList"
            [cdkDropListData]="mustHave"
            [cdkDropListConnectedTo]="[UnselectedList, niceToHaveList]"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            id="must-have-criteria-div"
          >
            <div *ngIf="mustHave.length === 0" class="empty-box">
              <p>Drag a must have criteria here</p>
            </div>
            <div class="example-box" *ngFor="let item of mustHave" cdkDrag>
              <div class="d-flex d-align-c left-box">
                <mat-icon class="criteria-icon">menu</mat-icon>
                {{ item.title }}
                <div>
                  <mat-icon
                    #tooltip="matTooltip"
                    matTooltip="{{ item.infoData }}"
                    matTooltipClass="horizon-tooltip"
                    class="horizon-tooltip"
                    >info</mat-icon
                  >
                </div>
              </div>
              <div class="right-box">
                <he-criteria-input
                  [inputdata]="item"
                  (onValueChange)="valueChange()"
                ></he-criteria-input>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="border-btm2">
        <p class="sub-title" style="color: #de9b37">Nice To Have Criteria</p>
      </div>
      <div class="border-btm">
        <div class="example-container">
          <div
            cdkDropList
            #niceToHaveList="cdkDropList"
            [cdkDropListData]="niceToHave"
            [cdkDropListConnectedTo]="[ UnselectedList]"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            id="nice-to-have-criteria-div"
          >
            <div *ngIf="niceToHave.length === 0" class="empty-box">
              <p>Drag a nice to have criteria here</p>
            </div>
            <div class="example-box" *ngFor="let item of niceToHave" cdkDrag>
              <div class="d-flex d-align-c left-box">
                <mat-icon class="criteria-icon">menu</mat-icon>
                {{ item.title }}
                <div>
                  <mat-icon
                    #tooltip="matTooltip"
                    matTooltip="{{ item.infoData }}"
                    matTooltipClass="horizon-tooltip"
                    class="horizon-tooltip"
                    >info</mat-icon
                  >
                </div>
              </div>
              <div class="right-box">
                <he-criteria-input
                  [inputdata]="item"
                  (onValueChange)="valueChange()"
                ></he-criteria-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-btm2">
        <p class="sub-title" style="color: #374050; opacity: 50%">
          Unselected Criteria
        </p>
      </div>
      <div class="example-container">
        <div
          cdkDropList
          #UnselectedList="cdkDropList"
          [cdkDropListData]="Unselected"
          [cdkDropListConnectedTo]="[ niceToHaveList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)"
          id="unselected-criteria-div"
        >
          <div *ngIf="Unselected.length === 0" class="empty-box">
            <p>Drag a Unselected criteria here</p>
          </div>
          <div class="example-box" *ngFor="let item of Unselected" cdkDrag>
            <div class="d-flex d-align-c left-box">
              <mat-icon class="criteria-icon">menu</mat-icon>
              {{ item.title }}
              <div>
                <mat-icon
                  #tooltip="matTooltip"
                  matTooltip="{{ item.infoData }}"
                  matTooltipClass="horizon-tooltip"
                  class="horizon-tooltip"
                  >info</mat-icon
                >
              </div>
            </div>
            <div class="right-box">
              <he-criteria-input
                [inputdata]="item"
                (onValueChange)="valueChange()"
              ></he-criteria-input>
            </div>
          </div>
        </div>
      </div>
      <div class="criteria-footer">
        <div class="footer-action">
          <button
            mat-button
            class="btn-02"
            (click)="reset()"
            id="reset-analogues-btn"
          >
            Reset
          </button>
          <button
            mat-button
            class="btn-01"
            [disabled]="
              mustHave.length === 0 && mustHave[0]?.selectedValue !== null
            "
            (click)="FindComparator()"
            id="find-analogues-btn"
          >
            Find comparator
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="secondPanelOpen"
      (opened)="secondPanelOpen = true"
      (closed)="secondPanelOpen = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> COMPARATOR HEAT MAP AND SELECTION </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <ng-container>
          <div class="d-flex d-j-between">
            <div>
              <p *ngIf="!criteriaSelectionService.isComparatorValid">
                At least 1 comparators are required per market
              </p>
            </div>
            <div matTooltipClass="horizon-tooltip" [matTooltip]="!criteriaSelectionService.isComparatorValid ?'Please select at least one brand per market':''">
              <button
              mat-button
              class="btn-01"
              
              [disabled]="!criteriaSelectionService.isComparatorValid"
              (click)="addAnalogues()"
              id="add-analogues-btn"
            >
              Add comparators
            </button>
            </div>
           
          </div>
          <div style="margin-bottom: 20px">
            <div *ngFor="let market of newSelectedMarket" class="country-chip">
              <mat-chip
                style="width: 144px"
                class="not-selected"
                [ngClass]="{ selected: market.country === countryFilter }"
                (click)="selectCountry(market.country)"
              >
                <he-country-cell
                  country="{{ market.country }}"
                ></he-country-cell>
              </mat-chip>
              <div class="analogue-list">
                <ng-container *ngIf="market.analogues.length != 0">
                  <div *ngFor="let analogue of market.analogues">
                    <mat-chip
                      [ngClass]="{ unavailable: !analogue.available }"
                      (removed)="removeAnalogue(analogue)"
                      >{{ analogue.name }}
                      <button matChipRemove>
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-chip>
                  </div>
                </ng-container>
                <ng-container *ngIf="market.analogues.length == 0">
                  <div>
                    <mat-chip> None selected</mat-chip>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <p>
          Selected comparator brands are displayed above and will be used in this
          project for analysis
        </p>
        <p class="sub-title" style="margin-top: 24px">
          Comparators Heat Map
          <span style="font-weight: 100"
            >(Please select the relevant comparators for your project):</span
          >
        </p>
        <p>
          <he-comporator-heat-map
            [loading]="loading"
            [selectedAnalogue]="comparators"
            (emitAnalogueList)="getComparatorsList($event)"
            (emitRemovedAnalogues)="removeAnalogue($event)"
            (removeCountryFilter)="selectCountry($event)"
          >
          </he-comporator-heat-map>
        </p>
      
        <p>
          Only comparators approved/reimbursed in each market will be displayed
          above.
        </p>
        <p>
          Can't find your comparator? <a href="mailto:support@globalpricing.com">click here</a> to send a query directly to our
          support team who will be able to assist you.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="thirdPanelOpen"
      (opened)="thirdPanelOpen = true"
      (closed)="thirdPanelOpen = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Comparator sorting </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sub-header" style="margin-bottom: 14px">
          <p>
            The order of the comparators selected here will be displayed in all
            pages.
          </p>
        </div>
        <div
          class="new-project-card"
          *heFeatureFlag="{
            Name: 'add-analogues-form',
            Page: 'create-new-project'
          }"
        >
          <mat-table
            #table
            matSort
            (matSortChange)="sortData($event)"
            [dataSource]="sortedComparators"
            cdkDropList
            [cdkDropListData]="sortedComparators"
            (cdkDropListDropped)="dropSorting($event)"
            id="analogue-sorting-table"
          >
            <ng-container matColumnDef="brandName">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="brandName"
                class="heading-color"
              >
                Brand Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index">
                <div style="display: grid; margin-right: 5px" class="movebtn">
                  <button
                    mat-icon-button
                    (click)="moveUp(i)"
                    [disabled]="i < 1"
                    [ngClass]="i < 1 ? 'disabled' : ''"
                  >
                    <mat-icon class="arrow-icons">expand_less</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="moveDown(i)"
                    [disabled]="i === sortedComparators.length - 1"
                    [ngClass]="
                      i === sortedComparators.length - 1 && i > 0 ? 'disabled' : ''
                    "
                  >
                    <mat-icon class="arrow-icons">expand_more</mat-icon>
                  </button>
                </div>
                {{ element.brandName }}
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="inn">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="inn"
                class="heading-color"
              >
                INN
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.inn }}
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="HTAIndication">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="HTAIndication"
                class="heading-color"
              >
              HTA Indication</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.HTAIndication }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mechanismOfAction" >
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header="mechanismOfAction"
                class="heading-color"
              >
                Mechanism of Action</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.mechanismOfAction }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="remove">
              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index">
                <button class="removeCell" (click)="removeSorting(i)">
                  Remove
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="comparatorsColumn;sticky:true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: comparatorsColumn; let i = index"
              cdkDrag
              [cdkDragData]="row"
            ></mat-row>
          </mat-table>
        </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
